
// this function will return result
export const getFinalResult = (questions,answers)=>{
  
  let writeAnswers = 0, wrongAnswers = 0;
  // this function will check answer is write or not
  const checker = (firstObj,secondObj)=>{
    let writeAnswer = true;
    for(let key in firstObj){
      if (firstObj[key].split(" ").join("") !== secondObj[key].split(" ").join("")) {
        writeAnswer = false;
      }
    }
    if(writeAnswer) writeAnswers++;
    else wrongAnswers++;
  }
  questions.forEach((_,index)=>{
    checker(questions[index],answers[index])
  })
  return {writeAnswers,wrongAnswers}
}
// this function will return true if answer is write and false if wrong
export const checkAnswer = (obj1,obj2)=>{
  let writeAnswer = true;
    for(let key in obj1){
      if (obj1[key].split(" ").join("") !== obj2[key].split(" ").join("")) {
        writeAnswer = false;
      }
    }
    return writeAnswer;
}
// this function will use to delay
export const delay = (time)=> {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(`Promise resolved after ${time} seconds`);
            }, time);
          });
    }
export const Maroof = (baabName ) => {
    

}