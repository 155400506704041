import reducer from './reducer/reducer'
import {applyMiddleware,compose,createStore} from 'redux'
import thunk from 'redux-thunk'

import { composeWithDevTools } from "redux-devtools-extension";




const enhancer = compose(applyMiddleware(thunk));
const store = createStore(reducer, composeWithDevTools(enhancer));


export default store