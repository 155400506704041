import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Fade from "react-reveal/Fade";
import { TextField, Typography } from "@material-ui/core";
import { registerData } from "../../../Redux/action/action";
import Logo from "../../../Assets/Images/Icon/logo.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  handleCreateUserWithEmailAndPassword,
  updateProfileToInitialValues,
} from "../../../Assets/firebase";
import { updateProfile } from "firebase/auth";

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const erro_detail = useSelector((state) => state.erro_detail);

  const [error, setError] = useState("");
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    conf_password: "",
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const Success = () =>
    toast.success(`Register Successfully`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const ErrorNotification = (errors) =>
    toast.error(`${errors} `, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const onSubmit = async () => {
    if (state.password !== state.conf_password) {
      return setError("password not match");
    }

    handleCreateUserWithEmailAndPassword(
      state.email,
      state.password,
      (response, error) => {
        if (!error) {
          updateProfileToInitialValues(state.name, (response, error) => {
            if (!error) {
              history.push("/");
              Success();
            } else {
              let customError = `Error: ${error.message
                .split("auth/")[1]
                .split(")")[0]
                .replace(/-/g, " ")}`;
              return ErrorNotification(customError);
            }
          });
        } else {
          let customError = `Error: ${error.message
            .split("auth/")[1]
            .split(")")[0]
            .replace(/-/g, " ")}`;
          return ErrorNotification(customError);
        }
      }
    );
    console.log("ok");
  };
  //   const onSubmit = async () => {
  //     if (state.password !== state.conf_password) {
  //       return setError("password not match");
  //     }
  //     const newdata = {
  //       username: state.name,
  //       email: state.email,
  //       password: state.password == state.conf_password && state.password,
  //     };
  //     newdata.password && (await dispatch(registerData(newdata)));
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //       history.push("/");
  //       Success();
  //     } else {
  //       return ErrorNotification();
  //     }
  //     console.log("ok");
  //   };
  const invalidEmail =
      state.email !== "" &&
      (!state.email.includes("@") || !state.email.includes("."))
        ? true
        : false,
    invalidPassword =
      state.password.length < 8 && state.password !== "" ? true : false,
    invalidConfirmPassword =
      state.conf_password !== "" && state.password !== state.conf_password
        ? true
        : false,
    allChecked =
      state.email === "" ||
      state.password === "" ||
      state.conf_password === "" ||
      invalidEmail ||
      invalidPassword ||
      invalidConfirmPassword
        ? false
        : true;
  return (
    <div
      style={{ backgroundColor: "whitesmoke", maxHeight: "100vh" }}
      className="login-page"
    >
      <Fade top>
        <div className="login-card">
          <div className="toolbar_logo">
            <img style={{ width: "160px" }} src={Logo} alt="" />
          </div>
          {/* <Typography variant='h6' align='center'>
            Welcome to Al Tasreef
          </Typography> */}
          <Typography variant="h6" align="center">
            Sign-Up to create account
          </Typography>
          <form action="">
            <TextField
              fullWidth
              id="outlined-basic"
              label="name"
              variant="outlined"
              onChange={handleChange}
              name="name"
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="email"
              variant="outlined"
              onChange={handleChange}
              name="email"
              error={invalidEmail}
              helperText={!invalidEmail ? "" : "Enter valid email"}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="password"
              variant="outlined"
              type="password"
              onChange={handleChange}
              name="password"
              helperText={
                invalidPassword ? "password must be at least 8 characters" : ""
              }
              error={invalidPassword}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Confirm Password"
              variant="outlined"
              type="password"
              onChange={handleChange}
              name="conf_password"
              helperText={invalidConfirmPassword ? "password not match" : ""}
              error={invalidConfirmPassword}
            />
          </form>

          <button
            onClick={() => onSubmit()}
            type="button"
            class="btn btn-info"
            disabled={!allChecked}
          >
            Create Account
          </button>
          <div
            style={{
              margin: "auto",
              width: "80%",
            }}
          >
            {/* <p style={{ color: "red" }}>
              {" "}
              {(erro_detail !== "" && erro_detail) || error}
            </p> */}
            <p
              style={{ fontSize: 20, Color: "black", cursor: "pointer" }}
              className="login-btn"
            >
              Already Have an account?{" "}
              <a
                style={{ textDecoration: "underline", color: "#366883" }}
                href="/login"
              >
                Login
              </a>
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Signup;
