import React from "react";
import Navbar from "../../Components/Navbar/Navbar";

function About() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Navbar />
      <div className="topicpage">
        <h1 style={{ color: "black", margin: "20px 0px" }}>التصريف</h1>
        <div style={{ width: "80%", color: "black", fontSize: "25px" }}>
          بسم اللہ الرحمن الرحیم، کمپیوٹر سائنسز کے جدید موضوعات میں مشین لرنگ،
          انفارمیشن ریٹریول، نیچرل لینگوتن پروسیسنگ اور آرمینیشنل ا نس جیسے
          مضامین شامل ہیں۔ ان مضامین سے مدد لیتے ہوئے لسانیات پر مختلف جہتوں میں
          کام ہو رہا ہے۔ الحمد لله علم الصرف کی یہ ایپ بھی اسی سلسلے کی ایک کڑی
          ہے۔ اس میں علم الصرف کے تمام صیغے خود کار طریقے سے بنائے جا رہے ہیں،
          اسی طرح خود کار طریقے سے امتحانی نظام وضع کیا گیا ہے۔ دس سوالات کے
          جواب کے بعد تفصیلی نتیجہ ظاہر کیا جاتا ہے۔ یہ کام ابھی ابتدائی مراحل
          میں ہے اور یقینا اس میں بہت بہتری کی گنجائش ہے، آپ سے درخواست ہے کہ
          کسی قسم کی بہتری کی تجاویز کے لئے رابطہ پر دی گئی ای میل کے ذریعے سے
          اپنی رائے ہم تک پہنچائیں۔ حق نواز جامعہ اشرفیہ لاہور)
        </div>
      </div>
    </div>
  );
}

export default About;
