import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Grid, Typography, TextField, Button } from "@material-ui/core";
import Frame from "../../Assets/Images/Icon/Frame.svg";
import PageCard from "../../Components/Card/PageCard";
import Navbar from "../../Components/Navbar/Navbar";
import TopicAccording from "./TopicAccording";
import TopicCard from "./TopicCard";
import { useSelector, useDispatch } from "react-redux";
import { loginDisplayShow } from "../../Redux/action/action";
import { userIsSignIn } from "../../Assets/firebase";
// import { history } from "../../configs/history";
const MashaqContentFeeh = () => {
  const [nameForTest, setNameForTest] = useState("");
  const [isSignIn, setIsSignIn] = useState(null);
  useEffect(()=>{
    userIsSignIn((user)=>{
	user ? setIsSignIn(true) : setIsSignIn(false)
    })
  },[])
  const dispatch = useDispatch();
  const history = useHistory();
  const goToQuestionPage = (name) => {
    if (isSignIn) {
      history.push(`/question-feeh/${nameForTest}`);
    } else if(isSignIn === false) {
      const answare = window.confirm("please login first then continue... ");

      if (answare) {
        // history.push('/login')
        dispatch(loginDisplayShow(true));
      } else {
        setTimeout(() => {
          history.push("/");
        }, 2000);
      }
    }
  };
//   const goToQuestionPage = (name) => {
//     const token = localStorage.getItem("token");

//     if (token) {
//       history.push(`/question-feeh/${nameForTest}`);
//     } else {
//       const answare = window.confirm("please login first then continue... ");

//       if (answare) {
//         // history.push('/login')
//         dispatch(loginDisplayShow(true));
//       } else {
//         setTimeout(() => {
//           history.push("/");
//         }, 2000);
//       }
//     }
//   };
  return (
    <>
      <Navbar />
      <div className="mashaq-panel">
        <div className="text-center topicpage">
          <PageCard>
            <h3
              style={{
                color: "black",
              }}
            >
              ہدایات
            </h3>
            <h3
              style={{
                color: "black",
                margin: "10px 20px",
              }}
            >
              اپ کو ہر سیکشن سے ایک اختیار منتخب کرنا ہو گا
            </h3>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="topicpage-grid"
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Button
                  onClick={() => goToQuestionPage()}
                  disabled={nameForTest == "" ? true : false}
                >
                  <Typography variant="h6">امتحان شروع کریں</Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <input
                  placeholder="ٹیسٹ کا نام"
                  type="text"
                  onChange={(e) => setNameForTest(e.target.value)}
                />
              </Grid>
            </Grid>
          </PageCard>
        </div>
      </div>
    </>
  );
};

export default MashaqContentFeeh;
