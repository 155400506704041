import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const TopicAccording = (props) => {
  return (
    <>
      <Accordion style={{ backgroundColor: 'transparent' }}>
        {props.title != 'اسم' && (
          <AccordionSummary
            style={{ color: 'black', height: '100%' }}
            expandIcon={<ExpandMoreIcon color='black' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography>{props.title}</Typography>
          </AccordionSummary>
        )}

        <AccordionDetails style={{ backgroundColor: 'whitesmoke' }}>
          <Typography>{props.children}</Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TopicAccording;
