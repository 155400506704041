import React from "react";
import "../navbarstyles.css";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../../Assets/Images/logo.svg";
import MenuList from "../MenuList";
const SideDrawer = (props) => {
  const english = useSelector((state) => state.english);

  let drawerClasses = [english ? "side-drawer" : "side-drawer-right"];

  if (props.show) {
    drawerClasses = [drawerClasses[0], "open"];
  }

  return (
    <nav
      className={[`${drawerClasses.join(" ")}`]}
      style={{
        right: english ? "" : "0",
        transition: "all ease-in-out",
        display: `${props.show ? "block" : "none"}`,
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#838995",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          borderBottom: "0.1rem solid white",
          height: "18%",
        }}
      >
        <img style={{ width: "120px" }} src={Logo} alt="" />
      </div>

      <MenuList />
    </nav>
  );
};
export default SideDrawer;
