import React, { Component } from "react";
import { useHistory } from "react-router";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Fade from "react-reveal/Fade";
import SweetAlert from 'react-bootstrap-sweetalert';

const useStyles = makeStyles((theme) => ({
    grid: {
      backgroundColor: "grey",
      height: "50vh",
      textAlign: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  }));



  const Alert = ()=>{
      return(
        <SweetAlert
        // style={{ backgroundColor: '#5c6878' }}
    
    
        // show={sweet_alert_updates ? true : false}
        type='success'
        show={false}
        showCancel
        // title='Language'
        customButtons={
          <React.Fragment>
           <div>
               zaman
           </div>
          </React.Fragment>
        }
      
        
         
      ></SweetAlert>
      )
  }

function ConfirmPasswordScreen() {

    
    const classes = useStyles();
    const history = useHistory();
    return (
        <div className="login-page">
            <Alert/>
        <Fade top>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
              <Grid
                container
                style={{ backgroundColor: "white", borderRadius: 20 }}
                className={classes.grid}
                justify="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item container justify="center">
                  <Grid item container alignItems="center" direction="column">
                    <Grid item>
                      <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                      </Avatar>
                    </Grid>
                   
                  </Grid>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Paper elevation={0}>
                    <Typography variant="h6">
                      Please enter new password
                    </Typography>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                >
                  <Grid item spacing={3} justify="center">
                  <TextField
              fullWidth
              name='password'
              id="outlined-basic"
              label="new password"
              variant="outlined"
              type='text'
            //   onChange={handleChange}
            />
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={()=>history.push('/')}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      confirm
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Container>
        </Fade>
      </div>
    )
}

export default ConfirmPasswordScreen
