import React, { useEffect } from "react";

import Login from "./Pages/Authentication/Login/Login";
import Signup from "./Pages/Authentication/Signup/Signup";
import ForgetPassword from "./Pages/Authentication/ForgetPassowrd/forgetPassword";
import Otp_Screen from "./Pages/Authentication/Otp-Screen/otp";
import ConfirmPasswordScreen from "./Pages/Authentication/NewPassword/ConfirmPasswordScreen";

import Home from "./Pages/Home/Index";
import ImageWrap from "./configs/ImageWrap";

import LoginSweetAlert from "./Components/Navbar/alertComponent/LoginSweetAlert";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Hadiyat from "./Pages/Hadiyat/Hadiyat";
import Search from "./Pages/Search/Search";
import Topic from "./Pages/Topic/Topic";
import Question from "./Pages/Questions/Question";
import Result from "./Pages/Questions/Result";
import TopicDetail from "./Pages/Topic/TopicDetail";
import Gardaan from "./Pages/MashaqContent/TopicDetail";
import GardaanNoun from "./Pages/MashaqContent/NounDetail";
import Report from "./Pages/Questions/Report";
import MashaqContent from "./Pages/MashaqContent/MashaqContent";
import TranslationPage from "./Pages/MashaqContent/TranslationPage";
import MashaqContentFail from "./Pages/MashaqContent/MashaqContentFail";
import QuestionCardFail from "./Pages/Questions/QuestionCardFail";
import MashaqContentFeeh from "./Pages/MashaqContent/MashaqContentFeeh";
import QuestionCardFeeh from "./Pages/Questions/QuestionCardFeeh";
import QuestionCardTranslation from "./Pages/Questions/QuestionCardTranslation";
import Others from "./Pages/MashaqContent/Others";
import About from "./Pages/About/About";
import Privacy from "./Pages/PrivacyPolicy/Privacy";
import Contact from "./Pages/Contact";
import Sweet_Alert_For_Imtehanat from "./Components/Navbar/alertComponent/Sweet_Alert_For_Imtehanat";
import Navbar from "./Components/Navbar/Navbar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { userIsSignIn } from "./Assets/firebase";
import { setUserID } from "./Redux/action/action";
const App = () => {
  const dispatch = useDispatch();
 
  return (
    <ImageWrap>
      <LoginSweetAlert />
      <Sweet_Alert_For_Imtehanat />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={Signup} />
        <Switch>
          {/* <Navbar /> */}
          <Route path="/" exact component={Home} />
          <Route path="/Hadiyat" exact component={Hadiyat} />
          {/* <Route path='/otp_screen' exact component={Otp_Screen} /> */}
          <Route path="/forget_password" exact component={ForgetPassword} />
          <Route
            path="/confirm_password"
            exact
            component={ConfirmPasswordScreen}
          />

          <Route path="/others" exact component={Others} />
          <Route path="/search" exact component={Search} />
          <Route path="/topic" exact component={Topic} />
          <Route path="/about" exact component={About} />
          <Route path="/privacy" exact component={Privacy} />
          <Route
            path="/mashaq-salaasi-mujarad"
            exact
            render={() => <MashaqContent type="mujarad" />}
          />
          <Route
            path="/mashaq-salaasi-mujarad-fail"
            exact
            render={() => <MashaqContentFail />}
          />
          <Route
            path="/mashaq-salaasi-mujarad-feeh"
            exact
            render={() => <MashaqContent type="mujaradFeeh" />}
          />
          <Route
            path="/mashaq-salaasi-mujarad-feeh-fail"
            exact
            render={() => <MashaqContentFeeh />}
          />
          <Route
            path="/mashaq-isam"
            exact
            render={() => <MashaqContent type="mashaqIsam" />}
          />
          <Route path="/gardaan-noun" exact component={GardaanNoun} />
          <Route path="/gardaan" exact component={Gardaan} />
          <Route path="/translation-page" exact component={TranslationPage} />
          <Route path="/question" exact component={Question} />
          <Route
            path="/question-fail/:name/:testname"
            exact
            component={QuestionCardFail}
          />
          <Route
            path="/question-fail/translation-page"
            exact
            component={QuestionCardTranslation}
          />
          <Route
            path="/question-feeh/:testname"
            exact
            component={QuestionCardFeeh}
          />
          <Route path="/result" component={Result} />
          <Route path="/topicdetail" component={TopicDetail} />
          <Route path="/report/:id" exact component={Report} />
          <Route path="/report" exact component={Report} />
          <Route path="/contact" exact component={Contact} />
        </Switch>
      </Router>
    </ImageWrap>
  );
};

export default App;
