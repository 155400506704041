import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import Index from "../../Pages/Home/Index";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  addToCart,
  removeToCart,
  sweetAlertShow,
  mushq_afal_slaasi,
  imtihan_afal_slaasi,
} from "../../Redux/action/action";
import {handleLogout, userIsSignIn } from "../../Assets/firebase";

const List = (props) => {
  return (
    <li style={{ textAlign: "center" }}>
      <Link
        onClick={props.onClick}
        style={{ fontSize: "22px", textAlign: "center" }}
        to={props.link}
      >
        {/* <Index sweet_alert_show={sweet_alert_show} /> */}
        {props.title}
      </Link>
    </li>
  );
};

const MenuList = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const if_English = useSelector((state) => state.english);
  const [isSignIn, setIsSignIn] = useState(null);
  useEffect(()=>{
    userIsSignIn((user)=>{
      user ? setIsSignIn(true) : setIsSignIn(false)
    })
  },[])
  const Success_Toast = () =>
    toast.info(`Logout Successfuly`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const history = useHistory();

  const handle_Log_Out = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("All-Tests");
    history.push("/");
    Success_Toast();
  };
  

  return if_English ? (
    <ul>
      <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
        {localStorage.getItem("token") ? (
          <List onClick={() => handle_Log_Out()} title=" لاگ آؤٹ " link="" />
        ) : (
          <List title="لاگ ان " link="login" />
        )}
        <List title="Dashboard" link="/" />
        <List title="مشق افعال ثلاثی" link="" />
        <List
          onClick={() => dispatch(mushq_afal_slaasi(true))}
          title="امتحان فعل"
          link=""
        />
        <List title="مشق مشتقات اسم" link="mashaq-isam" />
        <List title="Exam Record" link="/result" />
        <List title="Contact Us" link="/contact" />
        <List title="About Us" link="/about" />
        <List title="Privacy Policy" link="/privacy" />
        <List
          onClick={() => dispatch(sweetAlertShow(true))}
          title="Settings"
          link={location.pathname}
        />
      </Box>
      <Box display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ul>
            <List title="ڈیش بورڈ" link="/" />
            <List
              title="مشق فعل"
              onClick={() => dispatch(mushq_afal_slaasi(true))}
              link="/"
            />
            <List
              title="امتحان فعل"
              className="uniqueListStyle"
              onClick={() => dispatch(imtihan_afal_slaasi(true))}
              link="/"
            />

            <List title="مشق اسم" link="mashaq-isam" />

            <li>
              <List
                title="امتحان اسم"
                className="uniqueListStyle"
                link="/question"
              />
            </li>
            <li>
              {localStorage.getItem("token") ? (
                <List
                  onClick={() => handle_Log_Out()}
                  title=" لاگ آؤٹ "
                  link=""
                />
              ) : (
                <List title="لاگ ان " link="login" />
              )}
            </li>
            <li>
              <button
                className="lng_btn"
                style={{
                  backgroundColor: "transparent",
                  outline: "none",
                  border: "1px solid white",
                  width: "170px",
                  padding: "5px 10px",
                  color: "white",
                  borderRadius: 5,
                  fontSize: 19,
                  margin: "0px 0px 0px 13px",
                }}
                onClick={() => dispatch(sweetAlertShow(true))}
              >
                زبان کا انتخاب
              </button>
            </li>
          </ul>
        </div>
      </Box>
    </ul>
  ) : (
    <ul>
      <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
        {localStorage.getItem("token") ? (
          <List onClick={() => handle_Log_Out()} title=" لاگ آؤٹ " link="" />
        ) : (
          <List title="لاگ ان " link="login" />
        )}
        <List title="ڈیش بورڈ" link="/" />
        <List
          title="مشق افعال ثلاثی"
          onClick={() => dispatch(mushq_afal_slaasi(true))}
          link=""
        />
        <List
          title="امتحان فعل"
          onClick={() => dispatch(imtihan_afal_slaasi(true))}
          link=""
        />
        <List title="مشق مشتقات اسم" link="mashaq-isam" />
        <List title="امتحان اسم" className="uniqueListStyle" link="/question" />
        <List title="امتحانات کا ریکارڈ" link="/result" />
        <List title="رابطہ" link="/contact" />
        <List title="اس ایپ کے بارے میں" link="/about" />
        <List title="پر آوییسی پالیسی" link="/privacy" />
        <List
          onClick={() => dispatch(sweetAlertShow(true))}
          title="زبان کا انتخاب"
          link={location.pathname}
        />
      </Box>
      <Box display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ul>
            <List title="ڈیش بورڈ" link="/" />
            <List
              title="مشق فعل"
              onClick={() => dispatch(mushq_afal_slaasi(true))}
              link=""
            />
            <List
              title="امتحان فعل"
              className="uniqueListStyle"
              onClick={() => dispatch(imtihan_afal_slaasi(true))}
              link="/"
            />

            <List title="مشق اسم" link="mashaq-isam" />

            <List
              title="امتحان اسم"
              className="uniqueListStyle"
              link="/question"
            />
            <li
            onClick={()=> handleLogout()}
            >
                <List title={isSignIn ? "لاگ آوٹ کریں" : isSignIn === false && "لاگ ان کریں"} link="login"  />
            </li>
            <button
              className="lng_btn"
              style={{
                backgroundColor: "transparent",
                outline: "none",
                border: "1px solid white",
                width: "170px",
                padding: "5px 10px",
                color: "white",
                borderRadius: 5,
                fontSize: 19,
                margin: "0px 0px 0px 13px",
              }}
              onClick={() => dispatch(sweetAlertShow(true))}
            >
              زبان کا انتخاب
            </button>
          </ul>
        </div>
      </Box>
    </ul>
  );
};

export default MenuList;
