import React from "react";

const PageCard = (props) => {
  const mainClass = props.mainClass != null ? props.mainClass : "";

  return (
    <div style={{ backgroundColor: "transparent" }} className={`card`}>
      {props.children}
    </div>
  );
};

export default PageCard;
