import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { questionDataForFeeh } from "../MashaqContent/AllWordsArray";
import { useHistory } from "react-router";
import Layout from "../../Components/Layout/Layout";
import { useSelector, useDispatch } from "react-redux";
import { TestData } from "../../Redux/action/action";
import Navbar from "../../Components/Navbar/Navbar";
import { auth } from "../../Assets/firebase";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "0.1rem",
    "& .MuiTab-root": {
      minWidth: "127px",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "& .MuiTab-root": {
      backgroundColor: "#937854",
      fontSize: "1.2rem",
      color: "#fff",
      padding: "12px 50px",
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiBox-root": {
        padding: "0px",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const QuestionCardFeeh = () => {
  let { testname } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const testDate = new Date().toDateString();
  const newAllTests = localStorage.getItem("All-Tests")
    ? JSON.parse(localStorage.getItem("All-Tests"))
    : [];
  const [value, setValue] = React.useState(0);
  const [arrayQuestions, setArrayQuestions] = React.useState([]);

  const [arraySolutions, setArraySolutions] = React.useState([]);
  const arrayTests = [
    "تفاعل",
    "افعال",
    "افتعال",
    "تفعیل",
    "انفعال",
    "مفاعلہ",
    "استفعال",
    "تفعل",
  ];
  const typesOfGardan = [
    "ماضی مجہول",
    "ماضی معروف",
    "ﻣﹹﻀﺎﺭﻉ مجہول",
    "ﻣﹹﻀﺎﺭﻉ معروف",
    "ﻣﹹﻀﺎﺭﻉ منصوب",
    "امر",
  ];
  const [questionName, setQuestionName] = React.useState("");
  const [adhadh, setAdhadh] = React.useState("");
  const [jins, setJins] = React.useState("");
  const [segha, setSegha] = React.useState("");
  const [fail, setFail] = React.useState("");
  const [feehType, setFeehType] = React.useState("");

  const handleChangeAdhadh = (event, item) => {
    setAdhadh(event.target.value);
    setQuestionName(item.text);
  };
  const handleChangeJins = (event) => {
    setJins(event.target.value);
  };
  const handleChangeSegha = (event) => {
    setSegha(event.target.value);
  };
  const handleChangeFail = (event) => {
    setFail(event.target.value);
  };
  const handleChangeFeehType = (event) => {
    setFeehType(event.target.value);
  };

  const handleChange = (event, newValue) => {
    if (
      adhadh == "" ||
      jins == "" ||
      segha == "" ||
      fail == "" ||
      feehType == ""
    ) {
      alert("please select all options");
    } else {
      if (newValue < 9) {
        arraySolutions.push({
          text: questionName,
          zameerWahidJama: adhadh,
          zameerJins: jins,
          name: segha,
          baabName: fail,
          baab: feehType,
        });
        setValue(newValue + 1);
        setQuestionName("");
        setAdhadh("");
        setJins("");
        setSegha("");
        setFail("");
        setFeehType("");
      } else {
        newAllTests.push({
          testName: `${testname}ثلاثی مزید فیہ `,
          testDate: testDate,
          questionAsked: arrayQuestions,
          answerGiven: arraySolutions,
        });
        arraySolutions.push({
          text: questionName,
          zameerWahidJama: adhadh,
          zameerJins: jins,
          name: segha,
          baabName: fail,
          baab: feehType,
        });
       dispatch(
        TestData(
          auth.currentUser,{
            testName: `${testname} `,
            testDate: testDate,
            questionAsked: arrayQuestions,
            answerGiven: arraySolutions,
          },
          (id)=>{
            history.push(`/report/${id}`);
          }
        )
       )
      }
    }
  };

  useEffect(() => {
    setArrayQuestions(
      questionDataForFeeh.sort(() => Math.random() - Math.random()).slice(0, 10)
    );
    setValue(0);
  }, []);

  return (
    <div>
      <Navbar />

      <div
        style={{
          backgroundColor: "green",
          height: "85vh",
        }}
      >
        <Layout mainClass="topicpage1" title="التصریف">
          <div className="questionCardHeadings">
            <div className={classes.root}>
              <TabPanel value={value} index={value}>
                {arrayQuestions.map((item, index) => {
                  if (index == value) {
                    return (
                      <Fragment key={index}>
                        <FormControl component="fieldset" className="quiz">
                          <h3
                            style={{
                              color: "black",
                              backgroundColor: "transparent",
                              borderRadius: 20,
                              padding: "1rem",
                            }}
                          >
                            سوال نمبر {index + 1}:
                            <span className="arabic" style={{ color: "#a08868" }}>
                              {" "}
                              {item.text}
                            </span>
                          </h3>
                          <RadioGroup
                            aria-label="adhadh"
                            name="adhadh"
                            value={adhadh}
                            onChange={(e) => handleChangeAdhadh(e, item)}
                          >
                            <Grid
                              style={{ backgroundColor: "whitesmoke" }}
                              container
                            >
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <FormControlLabel
                                  value="جمع"
                                  control={<Radio />}
                                  label="جمع"
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <FormControlLabel
                                  value="واحد"
                                  control={<Radio color="default" />}
                                  label="واحد"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              style={{ backgroundColor: "whitesmoke" }}
                              container
                            >
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <FormControlLabel
                                  value="تثنیہ/جمع"
                                  control={<Radio />}
                                  label="تثنیہ/جمع"
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <FormControlLabel
                                  value="تثنیہ"
                                  control={<Radio />}
                                  label="تثنیہ"
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></div>
                        <FormControl component="fieldset" className="quiz">
                          <RadioGroup
                            aria-label="jins"
                            name="jins"
                            value={jins}
                            onChange={handleChangeJins}
                          >
                            <Grid
                              style={{ backgroundColor: "whitesmoke" }}
                              container
                            >
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <FormControlLabel
                                  value=" مؤنث"
                                  control={<Radio />}
                                  label="مؤنث"
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <FormControlLabel
                                  value="مذکر"
                                  control={<Radio color="default" />}
                                  label="مذکر"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                {" "}
                                <FormControlLabel
                                  value="مذکر/مؤنث"
                                  control={<Radio />}
                                  label="مذکر/مؤنث"
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></div>
                        <FormControl component="fieldset" className="quiz">
                          <RadioGroup
                            aria-label="Segha"
                            name="segha"
                            value={segha}
                            onChange={handleChangeSegha}
                          >
                            <Grid
                              style={{ backgroundColor: "whitesmoke" }}
                              container
                            >
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <FormControlLabel
                                  value="حاضر"
                                  control={<Radio />}
                                  label="حاضر"
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <FormControlLabel
                                  value="غائب"
                                  control={<Radio />}
                                  label="غائب"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              style={{ backgroundColor: "whitesmoke" }}
                              container
                            >
                              {" "}
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <FormControlLabel
                                  value="غائب/حاضر"
                                  control={<Radio />}
                                  label="غائب/حاضر"
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                {" "}
                                <FormControlLabel
                                  value="متکلم"
                                  control={<Radio />}
                                  label="متکلم"
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></div>
                        <FormControl component="fieldset" className="quiz">
                          <RadioGroup
                            aria-label="Fail"
                            name="fail"
                            value={fail}
                            onChange={handleChangeFail}
                          >
                            <Grid
                              style={{ backgroundColor: "whitesmoke" }}
                              container
                            >
                              {arrayTests.map((item1) => (
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                  <FormControlLabel
                                    value={item1}
                                    control={<Radio />}
                                    label={item1}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></div>
                        <FormControl component="fieldset" className="quiz">
                          <RadioGroup
                            aria-label="Feeh"
                            name="feeh"
                            value={feehType}
                            onChange={handleChangeFeehType}
                          >
                            <Grid
                              style={{ backgroundColor: "whitesmoke" }}
                              container
                            >
                              {typesOfGardan.map((item1) => (
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                  <FormControlLabel
                                    value={item1}
                                    control={<Radio />}
                                    label={item1}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </RadioGroup>
                        </FormControl>

                        <div
                          style={{
                            display: "flex",
                            width: "100% !important",
                            justifyContent: "center",
                            alignItems: "center",
                            // backgroundColor: "blue",
                            padding: "10px 10px",
                          }}
                        >
                          <FormControl component="fieldset">
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="simple tabs example"
                            >
                              {[...Array(10).keys()].map((countNumber) => (
                                <Tab
                                  label="تصدیق کریں"
                                  {...a11yProps(countNumber)}
                                  style={
                                    value == countNumber
                                      ? { display: "block" }
                                      : { display: "none" }
                                  }
                                  disabled={value == 10 ? true : false}
                                />
                              ))}
                            </Tabs>
                          </FormControl>
                        </div>
                      </Fragment>
                    );
                  }
                })}
              </TabPanel>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default QuestionCardFeeh;
