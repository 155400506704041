import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import { onAuthStateChanged } from "firebase/auth";
import MazeedFeeh from '../../Components/resultDetails/MazeedFeeh';
import {
  makeStyles,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import SectionCard from "../../Components/Card/SectionCard";
import Layout from "../../Components/Layout/Layout";
import { getAllData } from "../../Redux/action/action";
import { auth } from "../../Assets/firebase";
import {
  checkAnswer,
  delay,
  getFinalResult,
} from "../../Assets/FunctionHelpers";
import ListWithCheckBox from "../../Components/reportComponents/List";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Ism from "../../Components/resultDetails/Ism";
import { Translate } from "@material-ui/icons";
import Translation from "../../Components/resultDetails/Translation";
const useStyles = makeStyles((theme) => ({
  topDiv: {
    fontSize: "2rem",
    "& .MuiGrid-container": {
      "&:nth-of-type(1)": {
        color: "black",
        // padding: '0.1rem 0rem',
        "& .MuiGrid-item": {
          "& h5": {
            borderBottom: "0.01rem solid #8C8C8C",
            "&:last-of-type": {
              borderBottom: "none",
            },
          },

          "&.last": {
            // backgroundColor: "#09456",
            backgroundColor: "white",
            minHeight: "60vh",
            maxHeight: "60vh",
            overflow: "hidden",
            overflow: "scroll",
          },
          "&.first": {
            backgroundColor: "#454545",
            minHeight: "60vh",
            maxHeight: "60vh",
            overflow: "hidden",
            overflow: "scroll",
          },
        },
      },
    },
  },
  root: {
    minWidth: "100%",
    margin: "0.5rem 0rem",
    "&:.MuiCardContent-root": {
      padding: "0px",
      "&:last-child": {
        paddingBottom: "0px",
      },
    },
    "& .MuiGrid-container": {
      "&:nth-of-type(1)": {
        backgroundColor: "rgb(16, 16, 16,0.7)",
        color: "#fff",
        fontSize: "2rem",
        [theme.breakpoints.down("md")]: {
          fontSize: "1.5rem",
        },
        // padding: '0.1rem 0rem',
        "& .MuiGrid-item": {
          "&:first-of-type": {
            paddingBottom: "0.5rem",
          },
        },
      },
      "&:nth-of-type(2)": {
        backgroundColor: "rgb(190,190,190,0.7)",
        color: "#fff",
        fontSize: "1.5rem",
        padding: "0.1rem 0rem 0.3rem",
      },
      "&:nth-of-type(3)": {
        backgroundColor: "rgb(16, 16, 16,0.7)",
        color: "#fff",
        fontSize: "1.5rem",
        // padding: '0.1rem 0rem 0.3rem',
        "& .MuiGrid-item": {
          padding: "0.1rem 0rem 0.3rem",
          "&:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3)": {
            borderRight: "0.1rem solid #fff",
            borderBottom: "0.1rem solid #fff",
          },
          "&:nth-of-type(4)": {
            // borderRight: '0.1rem solid #fff',
            borderBottom: "0.1rem solid #fff",
          },
          "&:first-of-type": {
            // borderRight: '0.1rem solid #fff',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
      "&:nth-of-type(4)": {
        backgroundColor: "rgb(16, 16, 16,0.7)",
        color: "#fff",
        fontSize: "1.5rem",
        // padding: '0.1rem 0rem 0.3rem',
        "& .MuiGrid-item": {
          padding: "0.1rem 0rem 0.3rem",
          "&:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3)": {
            borderRight: "0.1rem solid #fff",
            borderBottom: "0.1rem solid #fff",
          },
          "&:nth-of-type(4)": {
            // borderRight: '0.1rem solid #fff',
            borderBottom: "0.1rem solid #fff",
          },
          "&:first-of-type": {
            // borderRight: '0.1rem solid #fff',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
      "&:nth-of-type(5)": {
        backgroundColor: "rgb(16, 16, 16,0.7)",
        color: "#fff",
        fontSize: "1.5rem",
        "& .MuiGrid-item": {
          padding: "0.1rem 0rem 0.3rem",
          "&:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3)": {
            borderRight: "0.1rem solid #fff",
            borderBottom: "0.1rem solid #fff",
          },
          "&:nth-of-type(4)": {
            borderBottom: "0.1rem solid #fff",
          },
          "&:first-of-type": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
      "&:nth-of-type(6)": {
        backgroundColor: "rgb(16, 16, 16,0.7)",
        color: "#fff",
        fontSize: "1.5rem",
        "& .MuiGrid-item": {
          padding: "0.1rem 0rem 0.3rem",
          "&:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3)": {
            borderRight: "0.1rem solid #fff",
            borderBottom: "0.1rem solid #fff",
          },
          "&:nth-of-type(4)": {
            borderBottom: "0.1rem solid #fff",
          },
          "&:first-of-type": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
      "&:nth-of-type(7)": {
        backgroundColor: "rgb(16, 16, 16,0.7)",
        color: "#fff",
        fontSize: "1.5rem",
        "& .MuiGrid-item": {
          padding: "0.1rem 0rem 0.3rem",
          "&:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3)": {
            borderRight: "0.1rem solid #fff",
            borderBottom: "0.1rem solid #fff",
          },
          "&:nth-of-type(4)": {
            borderBottom: "0.1rem solid #fff",
          },
          "&:first-of-type": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  imgSize: {
    width: "25px",
    height: "25px",
  },
}));
const Report = () => {
  const [checked, setChecked] = useState([1]);
  const history = useHistory();
  // this function will use to handle list check and uncheck actions
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();
  const location = useLocation();

  const if_inglish = useSelector((state) => state.english);
  const allExams = useSelector((state) => state.allExams);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {

      } else {
        history.push("/login");
        
      }
    });
    const setter = async () => {
      
      await delay(10000);
      dispatch(getAllData(auth.currentUser.uid));
    };
    setter();
  }, []);
  // getting single result
  if (allExams) {
    if (location.pathname === "/report" || location.pathname === "/report/") {
      history.push(`/report/${allExams[allExams.length - 1].id}`);
      return (
      <div></div>
        
      );
    } else {
      const singleReport = allExams.find((doc) => doc.id === id);
      
      // extracting data
      const {
        exam: { testName, testDate, questionAsked, answerGiven },
      } = singleReport.data();
      
      return (
        <div>
          <Navbar />

          <Layout mainClass="report-card-main-class" title="التصریف">
            
            <SectionCard>
              <div className={classes.topDiv}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid className="last result-area-" item xs={9} sm={6} md={6} lg={9}>
                    <h3>اجمالی نتائج</h3>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <h5>{testName}</h5>
                        <h5>{testDate}</h5>
                        <h5>{answerGiven.length}</h5>
                        <h5>
                          {
                            getFinalResult(questionAsked, answerGiven)
                              .writeAnswers
                          }
                        </h5>
                        <h5>
                          {
                            getFinalResult(questionAsked, answerGiven)
                              .wrongAnswers
                          }
                        </h5>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <h5>امتحان کا نام</h5>
                        <h5>وقت امتحان</h5>
                        <h5>کل جوابات</h5>
                        <h5>درست جوابات</h5>
                        <h5>غلط جوابات</h5>
                      </Grid>
                    </Grid>
                    <h3>تفصیلی نتائج</h3>
                    {questionAsked.map((q, index) => {
                      const {text} = q;
                      return (
                        <>
                          <Grid container direction="row-reverse">
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <h4 className="arabic">
                                ({index + 1}): {text}
                              </h4>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <h4>
                                {checkAnswer(q, answerGiven[index])
                                  ? "صحیح"
                                  : "غلط"}
                              </h4>
                            </Grid>
                          </Grid>
                          {
                          q.hasOwnProperty("baab") ? <MazeedFeeh question={q} answerGiven={answerGiven} index={index} /> : testName === "Translation" ? <Translation question={q} answerGiven={answerGiven} index={index} /> : <Ism question={q} answerGiven={answerGiven} index={index} ism={testName === "اسم امتحان" ? true : false } />
                          
                        }
                        </>
                      );
                    })}
                  </Grid>
                  {/* side panel */}
                  <Grid className="first" item xs={3} sm={6} md={6} lg={3}>
                    <h2 className="urdu" style={{paddingTop: "30px",paddingBottom: "20px" }}>
                      سابقہ نتائج
                    </h2>
                    {/* list in side panel */}
                    <div className="list-container">
                      <ListWithCheckBox array={allExams} />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </SectionCard>
          </Layout>
          {/* {!success ? (
          <div>
            <div
              style={{
                display: "flex",
                height: "100vh",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="questionCardHeadings result-page result-page-content"
            >
              <Typography style={{ color: "black" }} variant="h3">
                <CircularProgress />
              </Typography>
            </div>
          </div>
        ) : (
          <Layout mainClass="report-card-main-class" title="التصریف">
            <>
              <SectionCard>
                <div className={classes.topDiv}>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      {if_inglish ? (
                        <>
                          <h5>Name</h5>
                          <h5>Time</h5>
                          <h5>Marks</h5>
                        </>
                      ) : (
                        <>
                          <h5>نام</h5>
                          <h5>تاریخ</h5>
                          <h5>مارک</h5>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <h5>{resultName}</h5>
                      <h5>{resultTime}</h5>
                      <h5>{totalMarks}</h5>
                    </Grid>
                  </Grid>
                </div>
              </SectionCard>
              <SectionCard>
                {resultAnswerGiven.map((item, index) => (
                  <Card className={classes.root}>
                    <Grid container justify="space-between" alignItems="center">
                      {item.zameerWahidJama ==
                        resultQuestionAsked[index].zameerWahidJama &&
                      item.zameerJins == resultQuestionAsked[index].zameerJins &&
                      item.name == resultQuestionAsked[index].name &&
                      item.baabName == resultQuestionAsked[index].baabName &&
                      item.baab === resultQuestionAsked[index].baab ? (
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          style={{ backgroundColor: "green" }}
                        >
                          {if_inglish ? <h3>Right</h3> : <h3>صحیع </h3>} صحیع
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          style={{ backgroundColor: "red" }}
                        >
                          {if_inglish ? <h3>Wrong</h3> : <h3>غلط </h3>}
                        </Grid>
                      )}
  
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {index + 1}: {item.text}
                      </Grid>
                    </Grid>
                    <Grid container>
                      {" "}
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {if_inglish ? <h3>Result</h3> : <h3> نتیجا </h3>}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {if_inglish ? <h3>Answer</h3> : <h3> درست جواب </h3>}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {if_inglish ? <h3>Selected</h3> : <h3>آپ کا جواب </h3>}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {if_inglish ? <h3>Section</h3> : <h3>سلیکشن </h3>}
                      </Grid>
                    </Grid>{" "}
                    <Grid container>
                      {" "}
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {item.zameerWahidJama ==
                        resultQuestionAsked[index].zameerWahidJama ? (
                          <img className={classes.imgSize} src={TickIcon} />
                        ) : (
                          <img className={classes.imgSize} src={Crossicon} />
                        )}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {resultQuestionAsked[index].zameerWahidJama}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {item.zameerWahidJama}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        Adud
                      </Grid>
                    </Grid>
                    <Grid container>
                      {" "}
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {item.zameerJins ==
                        resultQuestionAsked[index].zameerJins ? (
                          <img className={classes.imgSize} src={TickIcon} />
                        ) : (
                          <img className={classes.imgSize} src={Crossicon} />
                        )}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {resultQuestionAsked[index].zameerJins}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {item.zameerJins}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        Jins
                      </Grid>
                    </Grid>
                    <Grid container>
                      {" "}
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {item.name == resultQuestionAsked[index].name ? (
                          <img className={classes.imgSize} src={TickIcon} />
                        ) : (
                          <img className={classes.imgSize} src={Crossicon} />
                        )}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {resultQuestionAsked[index].name}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {item.name}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        Segha
                      </Grid>
                    </Grid>
                    <Grid container>
                      {" "}
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {item.baabName == resultQuestionAsked[index].baabName ? (
                          <img className={classes.imgSize} src={TickIcon} />
                        ) : (
                          <img className={classes.imgSize} src={Crossicon} />
                        )}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {resultQuestionAsked[index].baabName}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {item.baabName}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        Baab
                      </Grid>
                    </Grid>
                    <Grid container>
                      {" "}
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {item.baab == resultQuestionAsked[index].baab ? (
                          <img className={classes.imgSize} src={TickIcon} />
                        ) : (
                          <img className={classes.imgSize} src={Crossicon} />
                        )}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {resultQuestionAsked[index].baab}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {item.baab}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        Noa-Baab
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </SectionCard>
            </>
            {test && resultName.includes("ثلاثی مزید فیہ") ? (
              <>
                <SectionCard>
                  <div className={classes.topDiv}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        {if_inglish ? (
                          <>
                            <h5>Name</h5>
                            <h5>Time</h5>
                            <h5>Marks</h5>
                          </>
                        ) : (
                          <>
                            <h5>نام</h5>
                            <h5>تاریخ</h5>
                            <h5>مارک</h5>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <h5>{resultName}</h5>
                        <h5>{resultTime}</h5>
                        <h5>{totalMarks}</h5>
                      </Grid>
                    </Grid>
                  </div>
                </SectionCard>
                <SectionCard>
                  {resultAnswerGiven.map((item, index) => (
                    <Card className={classes.root}>
                      <Grid container justify="space-between" alignItems="center">
                        {item.zameerWahidJama ==
                          resultQuestionAsked[index].zameerWahidJama &&
                        item.zameerJins ==
                          resultQuestionAsked[index].zameerJins &&
                        item.name == resultQuestionAsked[index].name &&
                        item.baabName == resultQuestionAsked[index].baabName &&
                        item.baab === resultQuestionAsked[index].baab ? (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "green" }}
                          >
                            {if_inglish ? <h3>Right</h3> : <h3>صحیع </h3>} صحیع
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "red" }}
                          >
                            {if_inglish ? <h3>Wrong</h3> : <h3>غلط </h3>}
                          </Grid>
                        )}
  
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {index + 1}: {item.text}
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Result</h3> : <h3> نتیجا </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Answer</h3> : <h3> درست جواب </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Selected</h3> : <h3>آپ کا جواب </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Section</h3> : <h3>سلیکشن </h3>}
                        </Grid>
                      </Grid>{" "}
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerWahidJama ==
                          resultQuestionAsked[index].zameerWahidJama ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {resultQuestionAsked[index].zameerWahidJama}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerWahidJama}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Adud
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerJins ==
                          resultQuestionAsked[index].zameerJins ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {resultQuestionAsked[index].zameerJins}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerJins}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Jins
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.name == resultQuestionAsked[index].name ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {resultQuestionAsked[index].name}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.name}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Segha
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.baabName ==
                          resultQuestionAsked[index].baabName ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {resultQuestionAsked[index].baabName}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.baabName}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Baab
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.baab == resultQuestionAsked[index].baab ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {resultQuestionAsked[index].baab}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.baab}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Noa-Baab
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </SectionCard>
              </>
            ) : test && resultName.includes("Translation") ? (
              <>
                <SectionCard>
                  <div className={classes.topDiv}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        {if_inglish ? (
                          <>
                            <h5>Name</h5>
                            <h5>Time</h5>
                            <h5>Marks</h5>
                          </>
                        ) : (
                          <>
                            <h5>نام</h5>
                            <h5>تاریخ</h5>
                            <h5>مارک</h5>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <h5>{testName}</h5>
                        <h5>{testDate}</h5>
                        <h5>{totalMarksResult}</h5>
                      </Grid>
                    </Grid>
                  </div>
                </SectionCard>
                <SectionCard>
                  {resultAnswerGiven.map((item, index) => (
                    <Card className={classes.root}>
                      <Grid container justify="space-between" alignItems="center">
                        {item.translation ==
                        resultQuestionAsked[index].translation ? (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "green" }}
                          >
                            {if_inglish ? <h3>Right</h3> : <h3>صحیع </h3>}
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "red" }}
                          >
                            {if_inglish ? <h3>Wrong</h3> : <h3>غلط </h3>}
                          </Grid>
                        )}
  
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {index + 1}: {item.text}
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Result</h3> : <h3> نتیجا </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Answer</h3> : <h3> جواب </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Selected</h3> : <h3>سلیکٹڈ </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Section</h3> : <h3>سلیکشن </h3>}
                        </Grid>
                      </Grid>{" "}
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.translation ==
                          resultQuestionAsked[index].translation ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {resultQuestionAsked[index].translation}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.translation}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Turjuma
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </SectionCard>
              </>
            ) : test ? (
              <>
                <SectionCard>
                  <div className={classes.topDiv}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        {if_inglish ? (
                          <>
                            <h5>Name</h5>
                            <h5>Time</h5>
                            <h5>Marks</h5>
                          </>
                        ) : (
                          <>
                            <h5>نام</h5>
                            <h5>تاریخ</h5>
                            <h5>مارک</h5>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <h5>{resultName}</h5>
                        <h5>{resultTime}</h5>
                        <h5>{totalMarksResult}</h5>
                      </Grid>
                    </Grid>
                  </div>
                </SectionCard>
                <SectionCard>
                  {resultAnswerGiven.map((item, index) => (
                    <Card className={classes.root}>
                      <Grid container justify="space-between" alignItems="center">
                        {item.zameerWahidJama ==
                          resultQuestionAsked[index].zameerWahidJama &&
                        item.zameerJins ==
                          resultQuestionAsked[index].zameerJins &&
                        item.name == resultQuestionAsked[index].name ? (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "green" }}
                          >
                            {if_inglish ? <h3>Right</h3> : <h3>صحیع </h3>}
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "red" }}
                          >
                            {if_inglish ? <h3>Wrong</h3> : <h3>غلط </h3>}
                          </Grid>
                        )}
  
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {index + 1}: {item.text}
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Result</h3> : <h3> نتیجا </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Answer</h3> : <h3> جواب </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Selected</h3> : <h3>سلیکٹڈ </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Section</h3> : <h3>سلیکشن </h3>}
                        </Grid>
                      </Grid>{" "}
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerWahidJama ==
                          resultQuestionAsked[index].zameerWahidJama ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {resultQuestionAsked[index].zameerWahidJama}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerWahidJama}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Adud
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerJins ==
                          resultQuestionAsked[index].zameerJins ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {resultQuestionAsked[index].zameerJins}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerJins}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Jins
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.name == resultQuestionAsked[index].name ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {resultQuestionAsked[index].name}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.name}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Segha
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </SectionCard>
              </>
            ) : testName && testName.includes("ثلاثی مزید فیہ") ? (
              <>
                <SectionCard>
                  <div className={classes.topDiv}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        {if_inglish ? (
                          <>
                            <h5>Name</h5>
                            <h5>Time</h5>
                            <h5>Marks</h5>
                          </>
                        ) : (
                          <>
                            <h5>نام</h5>
                            <h5>تاریخ</h5>
                            <h5>مارک</h5>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <h5>{testName}</h5>
                        <h5>{testDate}</h5>
                        <h5>{totalMarks}</h5>
                      </Grid>
                    </Grid>
                  </div>
                </SectionCard>
                <SectionCard>
                  {answerGiven.map((item, index) => (
                    <Card className={classes.root}>
                      <Grid container justify="space-between" alignItems="center">
                        {item.zameerWahidJama ==
                          questionAsked[index].zameerWahidJama &&
                        item.zameerJins == questionAsked[index].zameerJins &&
                        item.name == questionAsked[index].name &&
                        item.baabName == questionAsked[index].baabName &&
                        item.baab == questionAsked[index].baab ? (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "green" }}
                          >
                            {if_inglish ? <h3>Right</h3> : <h3>صحیع </h3>}
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "red" }}
                          >
                            {if_inglish ? <h3>Wrong</h3> : <h3>غلط </h3>}
                          </Grid>
                        )}
  
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {index + 1}: {item.text}
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Result</h3> : <h3> نتیجا </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Answer</h3> : <h3> جواب </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Selected</h3> : <h3>سلیکٹڈ </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Section</h3> : <h3>سلیکشن </h3>}
                        </Grid>
                      </Grid>{" "}
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerWahidJama ==
                          questionAsked[index].zameerWahidJama ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {questionAsked[index].zameerWahidJama}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerWahidJama}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Adud
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerJins == questionAsked[index].zameerJins ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {questionAsked[index].zameerJins}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerJins}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Jins
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.name == questionAsked[index].name ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {questionAsked[index].name}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.name}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Segha
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.baabName == questionAsked[index].baabName ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {questionAsked[index].baabName}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.baabName}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Baab
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.baab === questionAsked[index].baab ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {questionAsked[index].baab}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.baab}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Noa-Baab
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </SectionCard>
              </>
            ) : testName && testName.includes("Translation") ? (
              <>
                <SectionCard>
                  <div className={classes.topDiv}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        {if_inglish ? (
                          <>
                            <h5>Name</h5>
                            <h5>Time</h5>
                            <h5>Marks</h5>
                          </>
                        ) : (
                          <>
                            <h5>نام</h5>
                            <h5>تاریخ</h5>
                            <h5>مارک</h5>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <h5>{testName}</h5>
                        <h5>{testDate}</h5>
                        <h5>{totalMarksResult}</h5>
                      </Grid>
                    </Grid>
                  </div>
                </SectionCard>
                <SectionCard>
                  {answerGiven.map((item, index) => (
                    <Card className={classes.root}>
                      <Grid container justify="space-between" alignItems="center">
                        {item.translation == questionAsked[index].translation ? (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "green" }}
                          >
                            {if_inglish ? <h3>Right</h3> : <h3>صحیع </h3>}
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "red" }}
                          >
                            {if_inglish ? <h3>Wrong</h3> : <h3>غلط </h3>}
                          </Grid>
                        )}
  
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {index + 1}: {item.text}
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Result</h3> : <h3> نتیجا </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Answer</h3> : <h3> جواب </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Selected</h3> : <h3>سلیکٹڈ </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Section</h3> : <h3>سلیکشن </h3>}
                        </Grid>
                      </Grid>{" "}
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.translation ==
                          questionAsked[index].translation ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {questionAsked[index].translation}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.translation}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Turjuma
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </SectionCard>
              </>
            ) : (
              <>
                <SectionCard>
                  <div className={classes.topDiv}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        {if_inglish ? (
                          <>
                            <h5>Name</h5>
                            <h5>Time</h5>
                            <h5>Marks</h5>
                          </>
                        ) : (
                          <>
                            <h5>نام</h5>
                            <h5>تاریخ</h5>
                            <h5>مارک</h5>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <h5>{testName}</h5>
                        <h5>{testDate}</h5>
                        <h5>{totalMarks}</h5>
                      </Grid>
                    </Grid>
                  </div>
                </SectionCard>
                <SectionCard>
                  {answerGiven.map((item, index) => (
                    <Card className={classes.root}>
                      <Grid container justify="space-between" alignItems="center">
                        {item.zameerWahidJama ==
                          questionAsked[index].zameerWahidJama &&
                        item.zameerJins == questionAsked[index].zameerJins &&
                        item.name == questionAsked[index].name ? (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "green" }}
                          >
                            {if_inglish ? <h3>Right</h3> : <h3>صحیع </h3>}
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            style={{ backgroundColor: "red" }}
                          >
                            {if_inglish ? <h3>Wrong</h3> : <h3>غلط </h3>}
                          </Grid>
                        )}
  
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {index + 1}: {item.text}
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Result</h3> : <h3> نتیجا </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Answer</h3> : <h3> جواب </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Selected</h3> : <h3>سلیکٹڈ </h3>}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {if_inglish ? <h3>Section</h3> : <h3>سلیکشن </h3>}
                        </Grid>
                      </Grid>{" "}
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerWahidJama ==
                          questionAsked[index].zameerWahidJama ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {questionAsked[index].zameerWahidJama}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerWahidJama}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Adud
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerJins == questionAsked[index].zameerJins ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {questionAsked[index].zameerJins}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.zameerJins}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Jins
                        </Grid>
                      </Grid>
                      <Grid container>
                        {" "}
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.name == questionAsked[index].name ? (
                            <img className={classes.imgSize} src={TickIcon} />
                          ) : (
                            <img className={classes.imgSize} src={Crossicon} />
                          )}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {questionAsked[index].name}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          {item.name}
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          Segha
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </SectionCard>
              </>
            )}
          </Layout>
        )} */}
        </div>
      );
    }
  } else return (
    <Layout mainClass="report-card-main-class" title="التصریف">
          <div>
            
            <div
              style={{
                display: "flex",
                height: "100vh",
                width: "100vw",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="questionCardHeadings result-page result-page-content"
            >
              <Typography style={{ color: "black" }} variant="h3">
                <CircularProgress />
              </Typography>
            </div>
          </div>
        </Layout>
  );
};

export default Report;
