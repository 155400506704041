import React, { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import PageCard from '../Card/PageCard';

// import "./navbarstyles.css";
import '../Navbar/navbarstyles.css';
import Frame from '../../Assets/Images/Icon/Frame.svg';
// import Frame from "../../Assets"

const Layout = (props) => {
	const mainClass = props.mainClass != null ? props.mainClass : '';

	return (
		<div>
			<div className={`${mainClass}`}>
				<PageCard>
					<div>
						{/* <h2>
              <span>
                <img src={Frame} alt="" />
              </span>
              {props.title}
              <span>
                <img src={Frame} alt="" />
              </span>
            </h2> */}
						<h3>{props.subtile}</h3>
					</div>

					<div
						style={{
							display: 'flex',
							width: '100%',
							justifyContent: 'center',
						}}
						className='result-page-content'
					>
						{props.children}
					</div>
				</PageCard>
			</div>
		</div>
	);
};

export default Layout;
