import React from 'react'

const TopicCard = (props) => {
    return (
     
            <div className="topicardhead">
<div className="topiccard">
 <a href={props.url} > {props.title}</a>
</div>

</div>
       
    )
}

export default TopicCard
