import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../Components/Layout/Layout';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Typography } from '@material-ui/core';
import { getAllData } from '../../Redux/action/action';
import Navbar from '../../Components/Navbar/Navbar';

const Result = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	// const allResults = JSON.parse(localStorage.getItem("All-Tests"));

	const allExams = useSelector((state) => state.allExams);
	const reducerState = useSelector((state) => state);
	const if_inglish = useSelector((state) => state.english);

	const { success, exam, error } = allExams;
    // this function will run when user click on a result. sot it will navigate to that result
	const getReportPage = (index) => {
		history.push(`/report/${index + 1}`);
	};
	// this useeffect will get all result and set it to the allexams state
	useEffect(() => {
		dispatch(getAllData());
	}, [dispatch]);
	return (
		<div>
			<Navbar />
			{false ? (
				<div style={{ paddingTop: '10%' }}>
					<Layout mainClass='report-card-main-class' title='التصریف'>
						{exam && exam.lenght > 0 ? (
							<List
								className='result'
								style={{
									display: 'flex',
									justifyContent: 'center',
									flexDirection: 'column',
									alignItems: 'center',

									backgroundColor: 'whitesmoke',
								}}
							>
								{exam.map((item, index) => (
									<ListItem
										style={{
											width: '100%',
											borderRadius: 0,
										}}
										key={index}
										onClick={() => getReportPage(index)}
									>
										<ListItemText
											primary={item.exam.testName}
											style={{ color: 'white' }}
											secondary={
												<Typography variant='h6' style={{ color: 'white' }}>
													{item.exam.testDate}
												</Typography>
											}
										/>
										<ListItemAvatar style={{ borderRadius: 0, marginRight: 5 }}>
											<h2>{index + 1}</h2>
										</ListItemAvatar>
									</ListItem>
								))}
							</List>
						) : (
							<div
								style={{
									display: 'flex',
									height: '100vh',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								className='questionCardHeadings result-page result-page-content'
							>
								<Typography style={{ color: 'black' }} variant='h3'>
									Results not Found
								</Typography>
							</div>
						)}
					</Layout>
				</div>
			) : (
				<div>
					<Layout mainClass='report-card-main-class' title='التصریف'>
						{exam && exam.length > 0 ? (
							<List
								className='result result-page-content'
								style={{
									marginTop: '5%',
									margin: '0 auto',
									flexDirection: 'column',
									justifyContent: 'start',

									width: '80%',
								}}
							>
								{exam.map((item, index) => (
									<ListItem
										className='mx-4'
										style={{
											backgroundColor: 'rgb(131, 137, 149)',
											width: '100%',
											borderRadius: 0,
										}}
										key={index}
										onClick={() => getReportPage(index)}
									>
										<ListItemText
											primary={item.exam.testName}
											style={{ color: 'white' }}
											secondary={
												<Typography variant='h6' style={{ color: 'white' }}>
													{item.exam.testDate}
												</Typography>
											}
										/>
										<ListItemAvatar style={{ borderRadius: 0, marginRight: 5 }}>
											<h2>{index + 1}</h2>
										</ListItemAvatar>
									</ListItem>
								))}
							</List>
						) : (
							<div
								style={{
									display: 'flex',
									height: '100vh',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								className='questionCardHeadings result-page result-page-content'
							>
								<Typography style={{ color: 'black' }} variant='h3'>
									Results not Found
								</Typography>
							</div>
						)}
					</Layout>
				</div>
			)}
		</div>
	);
};

export default Result;
