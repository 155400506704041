import {
  AlaamatAmarStart,
  AlaamatAmarMajhoolStart,
  AlaamatAmarEnd,
  AlaamatMaazi,
  AlaamatMuzaraEnd,
  AlaamatMuzaraJazimEnd,
  AlaamatMuzaraMajhoolStart,
  AlaamatMuzaraNafiStart,
  AlaamatMuzaraNawasibEnd,
  AlaamatMuzaraNawasibMajhoolStart,
  AlaamatMuzaraNawasibStart,
  AlaamatMuzaraStart,
  UrduZameerTite,
  AlaamatAmarMaroofBanoonSaqeelaEnd,
  AlaamatAmarBanoonSaqeelaStart,
  AlaamatAmarBanoonSaqeelaMajhoolStart,
  AlaamatAmarMaroofBanoonKhafeefaEnd
} from "./Conjugation";
import {alif,fatha,jazam, taa, kasra,seen,noon,shad,wao,Zamma,maa, laa, lee} from './ConstValues'



export const ConvertBaseFailIntoGardan = (root, baabName, faelText) => {
  

  const FailGardaanFirstSeven = [];
  const FailGardaanSecondSeven = [];

  var gardaanStarter = "",gardaanStarterEndAmar = "",gardaanCenterAmar = "";
  const str2 = root.charAt(0),
    str3 = root.charAt(1),
    str4 = root.charAt(2);

  gardaanStarter = str2 + fatha + str3 + fatha + str4;

  if (faelText === "ماضی معروف" || faelText === "ماضی معروف نفی") {
    if ((baabName === "فتح") | (baabName === "نصر") | (baabName === "ضرب"))
      gardaanStarter = str2 + fatha + str3 + fatha + str4;
    else if ((baabName === "سمع") | (baabName === "حسب"))
      gardaanStarter = str2 + fatha + str3 + kasra + str4;
    else if (baabName === "کرم")
      gardaanStarter = str2 + fatha + str3 + Zamma + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "افعال")
      gardaanStarter = alif + fatha + str2 + jazam + str3 + fatha + str4;
    else if (baabName === "تفاعل")
      gardaanStarter = taa + fatha + str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "افتعال")
      gardaanStarter =
        alif + kasra + str2 + jazam + taa + fatha + str3 + fatha + str4;
    else if (baabName === "انفعال")
      gardaanStarter =
        alif + kasra + noon + jazam + str2 + fatha + str3 + fatha + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        alif +
        kasra +
        seen +
        jazam +
        taa +
        fatha +
        str2 +
        jazam +
        str3 +
        fatha +
        str4;
        if(faelText === "ماضی معروف نفی")
        {
          gardaanStarter = maa + " " + gardaanStarter;
        }
    //seperated two sectipns with reference of design
    for (let index = 0; index < AlaamatMaazi.length; index++) {
      
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text: gardaanStarter + AlaamatMaazi[index],
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardaanStarter + AlaamatMaazi[index],
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (faelText === "مضارع معروف" || faelText === "مضارع معروف نفی") {
    if (baabName === "فتح" || baabName === "سمع")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "ضرب" || baabName === "حسب")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "کرم" || baabName === "نصر")
      gardaanStarter = str2 + jazam + str3 + Zamma + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + fatha + str3 + shad + kasra + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "افعال")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "تفاعل")
      gardaanStarter = taa + fatha + str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + fatha + alif + str3 + kasra + str4;
    else if (baabName === "انفعال")
      gardaanStarter = noon + jazam + str2 + fatha + str3 + kasra + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        seen + jazam + taa + fatha + str2 + jazam + str3 + kasra + str4;
    else if (baabName === "افتعال")
      gardaanStarter = str2 + jazam + taa + fatha + str3 + kasra + str4;

     
      
    for (let index = 0; index < AlaamatMuzaraStart.length; index++) {
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text:
            //AlaamatMuzaraStart[index] +
            faelText === "مضارع معروف نفی" ? laa + AlaamatMuzaraStart[index] + gardaanStarter +
            AlaamatMuzaraEnd[index]  : 
            AlaamatMuzaraStart[index] +
            gardaanStarter +
            AlaamatMuzaraEnd[index],
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text:
          faelText === "مضارع معروف نفی" ? laa + AlaamatMuzaraStart[index] + gardaanStarter +
          AlaamatMuzaraEnd[index]  : 
          AlaamatMuzaraStart[index] +
          gardaanStarter +
          AlaamatMuzaraEnd[index],
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (faelText === "ماضی مجہول" || faelText === "ماضی مجہول نفی") {
    if (
      baabName === "فتح" ||
      baabName === "نصر" ||
      baabName === "ضرب" ||
      baabName === "سمع" ||
      baabName === "حسب" ||
      baabName === "کرم"
    )
      gardaanStarter = str2 + Zamma + str3 + kasra + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + Zamma + str3 + shad + kasra + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + Zamma + str2 + Zamma + str3 + shad + kasra + str4;
    else if (baabName === "افعال")
      gardaanStarter = alif + Zamma + str2 + jazam + str3 + kasra + str4;
    else if (baabName === "تفاعل")
      gardaanStarter =
        taa + Zamma + str2 + Zamma + wao + jazam + str3 + kasra + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + Zamma + wao + jazam + str3 + kasra + str4;
    else if (baabName === "انفعال")
      gardaanStarter =
        alif + Zamma + noon + jazam + str2 + Zamma + str3 + kasra + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        alif +
        Zamma +
        seen +
        jazam +
        taa +
        Zamma +
        str2 +
        jazam +
        str3 +
        kasra +
        str4;
    else if (baabName === "افتعال")
      gardaanStarter =
        alif + Zamma + str2 + jazam + taa + Zamma + str3 + kasra + str4;

        faelText === "ماضی مجہول" ? gardaanStarter = gardaanStarter : gardaanStarter = maa + " " + gardaanStarter;
    for (let index = 0; index < AlaamatMaazi.length; index++) {
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text: gardaanStarter + AlaamatMaazi[index],
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardaanStarter + AlaamatMaazi[index],
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (faelText === "مضارع مجہول" || faelText === "مضارع مجہول نفی") {
    if (
      baabName === "فتح" ||
      baabName === "سمع" ||
      baabName === "ضرب" ||
      baabName === "حسب" ||
      baabName === "کرم" ||
      baabName === "نصر"
    )
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "افعال")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "تفاعل")
      gardaanStarter = taa + fatha + str2 + fatha + alif + str3 + kasra + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "انفعال")
      gardaanStarter = noon + jazam + str2 + fatha + str3 + fatha + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        seen + jazam + taa + fatha + str2 + jazam + str3 + fatha + str4;
    else if (baabName === "افتعال")
      gardaanStarter = str2 + jazam + taa + fatha + str3 + fatha + str4;

    for (let index = 0; index < AlaamatMuzaraMajhoolStart.length; index++) {
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text:
          faelText === "مضارع مجہول نفی" ? laa + AlaamatMuzaraMajhoolStart[index] + gardaanStarter +
          AlaamatMuzaraEnd[index]  : 
          AlaamatMuzaraMajhoolStart[index] +
          gardaanStarter +
          AlaamatMuzaraEnd[index],
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text:
          faelText === "مضارع مجہول نفی" ? laa + AlaamatMuzaraMajhoolStart[index] + gardaanStarter +
          AlaamatMuzaraEnd[index]  : 
          AlaamatMuzaraMajhoolStart[index] +
          gardaanStarter +
          AlaamatMuzaraEnd[index],
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (faelText === "امر" || faelText === "امر مجہول") {
    if (baabName === "فتح" || baabName === "سمع")
    {gardaanStarterEndAmar = str2 + jazam + str3 + fatha + str4;
      gardaanStarter = alif + kasra + str2 + jazam + str3 + fatha + str4;}
    else if (baabName === "ضرب" || baabName === "حسب")
    {
      gardaanStarterEndAmar = str2 + jazam + str3 + kasra + str4;
      gardaanStarter = alif + kasra + str2 + jazam + str3 + kasra + str4;
    }
    else if (baabName === "کرم" || baabName === "نصر")
    {gardaanStarterEndAmar = str2 + jazam + str3 + Zamma + str4;
      gardaanStarter = alif + Zamma + str2 + jazam + str3 + Zamma + str4;}
      
    else if (baabName === "افعال")
      {
        gardaanStarterEndAmar = str2 + jazam + str3 + kasra + str4;
        gardaanStarter = alif + fatha + str2 + jazam + str3 + kasra + str4;}
    else if (baabName === "تفعیل")
    {
      gardaanStarterEndAmar = str2 + fatha + str3 + shad + kasra + str4;
      gardaanStarter = str2 + fatha + str3 + shad + kasra + str4;
    }
      
    else if (baabName === "مفاعلہ")
    {
      gardaanStarterEndAmar = str2 + fatha + str3 + shad + kasra + str4;
      gardaanStarter = str2 + fatha + alif + str3 + kasra + str4;
    }
      
    else if (baabName === "تفعل")
    {
      gardaanStarterEndAmar = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
      gardaanStarter = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
    }
      
    else if (baabName === "تفاعل")
    {
      gardaanStarterEndAmar = taa + fatha + str2 + fatha + alif + str3 + fatha + str4;
      gardaanStarter = taa + fatha + str2 + fatha + alif + str3 + fatha + str4;
    }
      
    else if (baabName === "افتعال")
    {
      gardaanStarterEndAmar = str2 + jazam + taa + fatha + str3 + kasra + str4;
      gardaanStarter = alif + kasra + str2 + jazam + taa + fatha + str3 + kasra + str4;
    }
      
    else if (baabName === "انفعال")
    {
      gardaanStarterEndAmar =  noon + jazam + str2 + fatha + str3 + kasra + str4;
      gardaanStarter = alif + kasra + noon + jazam + str2 + fatha + str3 + kasra + str4;
    }
      
    else if (baabName === "استفعال")
    {
      gardaanStarterEndAmar = seen + jazam + taa +
      fatha + str2 + jazam + str3 + kasra + str4;

      gardaanStarter =
        alif + kasra + seen + jazam + taa +
        fatha + str2 + jazam + str3 + kasra + str4;
    }
      

    for (let index = 0; index < AlaamatAmarStart.length; ++index) {
      if (index < 7) {
        if(index < 6){
          if (faelText === "امر") {
            FailGardaanFirstSeven.push({
              text: AlaamatAmarStart[index] + gardaanStarterEndAmar + AlaamatAmarEnd[index],
              zameer: UrduZameerTite[index],
            });
          }else{
            FailGardaanFirstSeven.push({
              text: AlaamatAmarMajhoolStart[index] + gardaanStarterEndAmar + AlaamatAmarEnd[index],
              zameer: UrduZameerTite[index],
            });
          }
        }
        else{
          if (faelText === "امر") {
            FailGardaanFirstSeven.push({
              text: AlaamatAmarStart[index] + gardaanStarter + AlaamatAmarEnd[index],
              zameer: UrduZameerTite[index],
            });
          }else{
            FailGardaanFirstSeven.push({
              text: AlaamatAmarMajhoolStart[index] + gardaanStarter + AlaamatAmarEnd[index],
              zameer: UrduZameerTite[index],
            });
          }
        } 
      } else {
        if(index < 12){
          if (faelText === "امر") {
            FailGardaanSecondSeven.push({
              text: AlaamatAmarStart[index] + gardaanStarter + AlaamatAmarEnd[index],
              zameer: UrduZameerTite[index],
            });
          }else{
            FailGardaanSecondSeven.push({
              text: AlaamatAmarMajhoolStart[index] + gardaanStarter + AlaamatAmarEnd[index],
              zameer: UrduZameerTite[index],
            });
          }
        }
        else{
          if (faelText === "امر") {
            FailGardaanSecondSeven.push({
              text: AlaamatAmarStart[index] + gardaanStarterEndAmar + AlaamatAmarEnd[index],
              zameer: UrduZameerTite[index],
            });
          }else{
            FailGardaanSecondSeven.push({
              text: AlaamatAmarMajhoolStart[index] + gardaanStarterEndAmar + AlaamatAmarEnd[index],
              zameer: UrduZameerTite[index],
            });
          }
        }
      }
    }
  } else if (faelText === "مضارع منصوب") {
    if (baabName === "فتح" || baabName === "سمع")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "ضرب" || baabName === "حسب")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "کرم" || baabName === "نصر")
      gardaanStarter = str2 + jazam + str3 + Zamma + str4;

    for (let index = 0; index < AlaamatMuzaraStart.length; ++index) {
      var gardanText =
        "لَنْ " +
        AlaamatMuzaraNawasibStart[index] +
        gardaanStarter +
        AlaamatMuzaraNawasibEnd[index];
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (faelText === "مضارع منصوب مجہول") {
    if (baabName === "فتح" || baabName === "سمع")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "ضرب" || baabName === "حسب")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "کرم" || baabName === "نصر")
      gardaanStarter = str2 + jazam + str3 + Zamma + str4;

    for (
      let index = 0;
      index < AlaamatMuzaraNawasibMajhoolStart.length;
      ++index
    ) {
      var gardanText =
        "لَنْ " +
        AlaamatMuzaraNawasibMajhoolStart[index] +
        gardaanStarter +
        AlaamatMuzaraNawasibEnd[index];
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (faelText === "مضارع مجذوم مجہول") {
    // if (baabName === "فتح" || baabName === "سمع")
    //   gardaanStarter = str2 + jazam + str3 + fatha + str4;
    // else if (baabName === "ضرب" || baabName === "حسب")
    //   gardaanStarter = str2 + jazam + str3 + kasra + str4;
    // else if (baabName === "کرم" || baabName === "نصر")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;

    for (let index = 0; index < AlaamatMuzaraStart.length; ++index) {
      var gardanText =
        "لَمْ " +
        AlaamatMuzaraMajhoolStart[index] +
        gardaanStarter +
        AlaamatMuzaraJazimEnd[index];
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (faelText === "مضارع مجذوم") {
      if (baabName === "فتح" || baabName === "سمع")
        gardaanStarter = str2 + jazam + str3 + fatha + str4;
      else if (baabName === "ضرب" || baabName === "حسب")
        gardaanStarter = str2 + jazam + str3 + kasra + str4;
      else if (baabName === "کرم" || baabName === "نصر")
        gardaanStarter = str2 + jazam + str3 + Zamma + str4;
  
      for (let index = 0; index < AlaamatMuzaraStart.length; ++index) {
        var gardanText =
          "لَمْ " +
          AlaamatMuzaraStart[index] +
          gardaanStarter +
          AlaamatMuzaraJazimEnd[index];
        if (index < 7) {
          FailGardaanFirstSeven.push({
            text: gardanText,
            zameer: UrduZameerTite[index],
          });
        } else {
          FailGardaanSecondSeven.push({
            text: gardanText,
            zameer: UrduZameerTite[index],
          });
        }
      }
  } else if (faelText === "مجہول مضارع منصوب") {
    if (baabName === "فتح" || baabName === "سمع")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "ضرب" || baabName === "حسب")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "کرم" || baabName === "نصر")
      gardaanStarter = str2 + jazam + str3 + Zamma + str4;

    for (let index = 0; index < AlaamatMuzaraStart.length; ++index) {
      var gardanText =
        "لَنْ " +
        AlaamatMuzaraNawasibStart[index] +
        gardaanStarter +
        AlaamatMuzaraNawasibEnd[index];
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (faelText === "امر معروف بانون ثقیلہ" || faelText === "امر معروف بانون ثقیلہ مجہول") {
    if (baabName === "فتح" || baabName === "سمع")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "ضرب" || baabName === "حسب")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "کرم" || baabName === "نصر")
      gardaanStarter = str2 + jazam + str3 + Zamma + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + fatha + str3 + shad + kasra + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "افعال")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "تفاعل")
      gardaanStarter = taa + fatha + str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + fatha + alif + str3 + kasra + str4;
    else if (baabName === "انفعال")
      gardaanStarter = noon + jazam + str2 + fatha + str3 + kasra + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        seen + jazam + taa + fatha + str2 + jazam + str3 + kasra + str4;
    else if (baabName === "افتعال")
      gardaanStarter = str2 + jazam + taa + fatha + str3 + kasra + str4;

     
      
    for (let index = 0; index < AlaamatMuzaraStart.length; index++) {
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text:
            faelText === "امر معروف بانون ثقیلہ" ? AlaamatAmarBanoonSaqeelaStart[index] + gardaanStarter +
            AlaamatAmarMaroofBanoonSaqeelaEnd[index]  : 
            AlaamatAmarBanoonSaqeelaMajhoolStart[index] +
            gardaanStarter +
            AlaamatAmarMaroofBanoonSaqeelaEnd[index],
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text:
          faelText === "امر معروف بانون ثقیلہ" ?  AlaamatAmarBanoonSaqeelaStart[index] + gardaanStarter +
          AlaamatAmarMaroofBanoonSaqeelaEnd[index]  : 
          AlaamatAmarBanoonSaqeelaMajhoolStart[index] +
          gardaanStarter +
          AlaamatAmarMaroofBanoonSaqeelaEnd[index],
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (faelText === "امر معروف بانون خفیفہ" || faelText === "امر معروف بانون خفیفہ مجہول") {
    if (baabName === "فتح" || baabName === "سمع")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "ضرب" || baabName === "حسب")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "کرم" || baabName === "نصر")
      gardaanStarter = str2 + jazam + str3 + Zamma + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + fatha + str3 + shad + kasra + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "افعال")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "تفاعل")
      gardaanStarter = taa + fatha + str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + fatha + alif + str3 + kasra + str4;
    else if (baabName === "انفعال")
      gardaanStarter = noon + jazam + str2 + fatha + str3 + kasra + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        seen + jazam + taa + fatha + str2 + jazam + str3 + kasra + str4;
    else if (baabName === "افتعال")
      gardaanStarter = str2 + jazam + taa + fatha + str3 + kasra + str4;

     
      
    for (let index = 0; index < AlaamatMuzaraStart.length; index++) {
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text:
            faelText === "امر معروف بانون خفیفہ" ?
            index == 1 || index == 4 || index == 5 ? "---":
            AlaamatAmarBanoonSaqeelaStart[index] + gardaanStarter +
            AlaamatAmarMaroofBanoonKhafeefaEnd[index]  : 
            index == 1 || index == 4 || index == 5 ? "---":
            AlaamatAmarBanoonSaqeelaMajhoolStart[index] +
            gardaanStarter +
            AlaamatAmarMaroofBanoonKhafeefaEnd[index],
          zameer: UrduZameerTite[index],
        });
        //1 4 5 7 10 11
      } else {
        FailGardaanSecondSeven.push({
          text:
          faelText === "امر معروف بانون خفیفہ" ?  
          index == 7 || index == 10 || index == 11 ? "---":
          AlaamatAmarBanoonSaqeelaStart[index] + gardaanStarter +
          AlaamatAmarMaroofBanoonKhafeefaEnd[index]  : 
          index == 7 || index == 10 || index == 11 ? "---":
          AlaamatAmarBanoonSaqeelaMajhoolStart[index] +
          gardaanStarter +
          AlaamatAmarMaroofBanoonKhafeefaEnd[index],
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (faelText === "نہی معروف" || faelText === "نہی مجہول" ){
    gardaanStarter = str2 + jazam + str3 + fatha + str4;

    for (let index = 0; index < AlaamatMuzaraStart.length; ++index) {
      var gardanText =
        laa + " " +
        AlaamatMuzaraStart[index] +
        gardaanStarter +
        AlaamatMuzaraJazimEnd[index];
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      }
    }
  }
 
  

  return [...FailGardaanFirstSeven,...FailGardaanSecondSeven] 
  // { FailGardaanFirstSeven, FailGardaanSecondSeven };
};




