export const urduTarjamaHelper = [
  { 
    title: 'جلس',
    data: [
      'جلس',
      'ضرب',
      'لازم',
      'بیٹھنا',
      'MaziLazimPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'sit',
      'sat',
      'sat',
      'sits',
    ],
  },
  {
    title: 'حبس',
    data: [
      'حبس',
      'ضرب',
      'لازم',
      'روکنا',
      'MaziMutadiPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'stop',
      'stopped',
      'stopped',
      'stops',
    ],
  },
  {
    title: 'حلق',
    data: [
      'حلق',
      'ضرب',
      'متعدی',
      'سر مونڈھنا',
      'MaziMutadiPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'shave head',
      'shaved head',
      'shaved head',
      'shaves head',
    ],
  },
  {
    title: 'حمل',
    data: [
      'حمل',
      'ضرب',
      'متعدی',
      'اٹھانا',
      'MaziMutadiPatternThree',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'lift',
      'lifted',
      'lifted',
      'lifts',
    ],
  },
  {
    title: 'خرق',
    data: [
      'خرق',
      'ضرب',
      'لازم',
      'پھاڑنا',
      'MaziMutadiPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'tear',
      'tore',
      'tore',
      'tears',
    ],
  },
  {
    title: 'رجع',
    data: [
      'رجع',
      'ضرب',
      'لازم',
      'لوٹنا',
      'MaziLazimPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'come',
      'came',
      'come',
      'comes',
    ],
  },
  {
    title: 'صبر',
    data: [
      'صبر',
      'ضرب',
      'متعدی',
      'صبر کرنا',
      'MaziMutadiPatternTwo',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      ' patience',
      ' patienced',
      ' patienced',
      'patiences',
    ],
  },
  {
    title: 'ضرب',
    data: [
      'ضرب',
      'ضرب',
      'متعدی',
      'مارنا',
      'MaziMutadiPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'beat',
      'beaten',
      'beaten',
      'beats',
    ],
  },
  {
    title: 'غسل',
    data: [
      'غسل',
      'ضرب',
      'متعدی',
      'دھونا',
      'MaziMutadiPatternThree',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'wash',
      'washed',
      'washed',
      'washes',
    ],
  },
  {
    title: 'بلغ',
    data: [
      'بلغ',
      'نصر',
      'لازم',
      'پہنچنا',
      'MaziLazimPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'reach',
      'reached',
      'reached',
      'reaches',
    ],
  },
  {
    title: 'ترک',
    data: [
      'ترک',
      'نصر',
      'متعدی',
      'چھوڑنا',
      'MaziMutadiPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'leave',
      'left',
      'left',
      'leaves',
    ],
  },
  {
    title: 'خرج',
    data: [
      'خرج',
      'نصر',
      'لازم',
      'نکلنا',
      'MaziLazimPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'leave',
      'left',
      'left',
      'leaves',
    ],
  },
  {
    title: 'خلد',
    data: [
      'خلد',
      'نصر',
      'لازم',
      'رہنا',
      'MaziLazimPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'live',
      'lived',
      'lived',
      'lives',
    ],
  },
  {
    title: 'خلط',
    data: [
      'خلط',
      'نصر',
      'لازم',
      'ملنا',
      'MaziLazimPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'meet',
      'met',
      'met',
      'meets',
    ],
  },
  {
    title: 'دخل',
    data: [
      'دخل',
      'نصر',
      'لازم',
      'داخل ہونا',
      'MaziLazimPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'enter',
      'entered',
      'entered',
      'enters',
    ],
  },
  {
    title: 'سقط',
    data: [
      'سقط',
      'نصر',
      'لازم',
      'گرنا',
      'MaziLazimPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'fall',
      'fallen',
      'fallen',
      'falls',
    ],
  },
  {
    title: 'سکت',
    data: [
      'سکت',
      'نصر',
      'لازم',
      'خاموش ہونا',
      'MaziLazimPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'silent',
      'silented',
      'silented',
      'silents',
    ],
  },
  {
    title: 'طلب',
    data: [
      'طلب',
      'نصر',
      'متعدی',
      'طلب کرنا',
      'MaziMutadiPatternTwo',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'deman',
      'demanded',
      'demanded',
      'demands',
    ],
  },
  {
    title: 'قعد',
    data: [
      'قعد',
      'نصر',
      'لازم',
      'بیٹھنا',
      'MaziLazimPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'sit',
      'sat',
      'sat',
      'sits',
    ],
  },
  {
    title: 'نصر',
    data: [
      'نصر',
      'نصر',
      'متعدی',
      'مدد کرنا',
      'MaziMutadiPatternTwo',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'help',
      'helped',
      'helped',
      'helps',
    ],
  },
  {
    title: 'سمع',
    data: [
      'سمع',
      'سمع',
      'متعدی',
      'سننا',
      'MaziMutadiPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'listen',
      'listened',
      'listened',
      'listens',
    ],
  },

  {
    title: 'شرب',
    data: [
      'شرب',
      'سمع',
      'متعدی',
      'پینا',
      'MaziMutadiPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'drink',
      'drank',
      'drunk',
      'drinks',
    ],
  },
  {
    title: 'شہد',
    data: [
      'شہد',
      'سمع',
      'متعدی',
      'دیکھنا، گواہی دینا ',
      'MaziMutadiPatternOne, MaziMutadiPatternOne',
      'MuzaraLazimPatternOne, MuzaraLazimPatternOne',
      'AmarPatternOne, AmarPatternOne',
      'FaiilPatternOne',
      'see, witness',
      'saw, witnessed',
      'seen, witnessed',
      'sees, witnesses',
    ],
  },
  {
    title: 'لبس',
    data: [
      'لبس',
      'سمع',
      'متعدی',
      'پہننا',
      'MaziMutadiPatternOne',
      'MuzaraLazimPatternOne',
      'AmarPatternOne',
      'FaiilPatternOne',
      'wear',
      'weared',
      'weared',
      'wears',
    ],
  },
];

export const alaamatStarterPastEnglishAll = [
  'He ',
  'They (dual male) ',
  'They (plural male) ',
  'She ',
  'They (dual female) ',
  'They (plural female)',
  'You (singular male) ',
  'You (dual male) ',
  'You (plural male) ',
  'You (singular female) ',
  'You (dual female) ',
  'You (plural female)',
  'I (male / female) ',
  'We (male / female , dual / plural) ',
];

export const alaamatStarterMaziLazimPatternOne = [
  'وہ (ایک مرد) ',
  ' وہ (دو مرد)',
  'وہ (سب مرد) ',
  'وہ (ایک عورت) ',
  'وہ (دو عورتیں) ',
  'وہ (سب عورتیں) ',
  'آپ (ایک مرد) ',
  'آپ (دو مرد) ',
  'آپ (سب مرد) ',
  'آپ (ایک عورت) ',
  'آپ (دو عورتیں) ',
  'آپ (سب عورتیں) ',
  'میں (ایک مرد / عورت) ',
  'ہم (سب مرد / عورتیں) ',
];

export const alaamatMaziLazimPatternOne = [
  'ا',
  'ے',
  'ے',
  'ی',
  'یں',
  'یں',
  'ے',
  'ے',
  'ے',
  'ی',
  'یں',
  'یں',
  'ا',
  'ے',
];

export const alaamatMaziLazimPatternTwo = [
  'ا',
  'ئے',
  'ئے',
  'ئی',
  'ئیں',
  'ئیں',
  'ئے',
  'ئے',
  'ئے',
  'ئی',
  'ئیں',
  'ئیں',
  'ا',
  'ئے',
];

export const alaamatStarterMaziMutadiAllPattern = [
  'اس (ایک مرد) نے ',
  ' ان (دو مردوں) نے ',
  'ان (سب مردوں) نے ',
  'اس (ایک عورت) نے ',
  'ان (دو عورتوں) نے ',
  'ان (سب عورتوں) نے ',
  ' آپ ایک مرد نے  ',
  'آپ دو مردوں نے ',
  ' آپ سب مردوں نے ',
  'آپ (ایک عورت) نے ',
  'آپ (دو عورتوں) نے ',
  'آپ (سب عورتوں) نے ',
  'میں (ایک مرد/عورت) نے ',
  'ہم (سب مردوں/عورتوں) نے ',
];

export const alaamatMaziMutadiPatternOne = [
  'ا',
  'ا',
  'ا',
  'ا',
  'ا',
  'ا',
  'ا',
  'ا',
  'ا',
  'ا',
  'ا',
  'ا',
  'ا',
  'ا',
];

export const alaamatMaziMutadiPatternTwo = [
  'کی',
  'کی',
  'کی',
  'کی',
  'کی',
  'کی',
  'کی',
  'کی',
  'کی',
  'کی',
  'کی',
  'کی',
  'کی',
  'کی',
];
export const alaamatMaziMutadiPatternThree = [
  'یا',
  'یا',
  'یا',
  'یا',
  'یا',
  'یا',
  'یا',
  'یا',
  'یا',
  'یا',
  'یا',
  'یا',
  'یا',
  'یا',
];

export const alaamatMuzaraPatternOne = [
  'تا ہے',
  'تے ہیں',
  'تے ہیں',
  'تی ہے',
  'تیں ہیں',
  'تیں ہیں',
  'تے ہو',
  'تے ہو',
  'تے ہو',
  'تی ہو',
  'تی ہیں',
  'تی ہیں',
  'تا ہوں / تی ہوں',
  'تے ہیں / تیں ہیں',
];

export const allLinksAndroid = [
  {
    name: 'تفسیر عثماني (أردو)',
    nameEnglish: 'Tafseer Usmani (Urdu)',
    link: 'https://play.google.com/store/apps/details?id=com.tafseerusmani.jamiaashrafia',
  },
  {
    name: 'تفسیر بیان القران (أردو)',
    nameEnglish: 'Tafseer Bayan ul Quran (Urdu)',
    link: 'https://play.google.com/store/apps/details?id=com.bayanulquran',
  },
  {
    name: 'ترجمة توضیح القرآن (الأردية) للشيخ المفتی تقی عثمانی',
    nameEnglish: 'Tauzehul Quran (Urdu) translation by Mufti Taqi Usmani ',
    link: 'https://play.google.com/store/apps/details?id=com.asantarjuma',
  },
  {
    name: 'الترجمة الإنجليزية القرآن الكريم د. محمد تقي الدين الهلالي و د. محمد محسن',
    nameEnglish:
      'Dr. Muhammad Taqi-ud-Din Al-Hilali, Ph.D. & Dr. Muhammad Muhsin',
    link: 'https://play.google.com/store/apps/details?id=com.cacpak.qurantranslatiobdrtaqiudeendrmuhsin.TaqiHilaliAndMuhsinKhan',
  },
  {
    name: 'الترجمة الإنجليزية القرآن الكريم عبد الماجد دریابادی',
    nameEnglish: 'English Quran Translation by Abdul Majid Daryabadi',
    link: 'https://play.google.com/store/apps/details?id=cacpak.haqnawaz.englishdaryabadi',
  },
  {
    name: 'الفاروق (أردو)',
    nameEnglish: 'Alfarooq (Urdu)',
  },
  {
    name: 'سيرة عائشة (أردو)',
    nameEnglish: 'Seerat Ayesha (Urdu)    ',
  },
  {
    name: 'اشرف الفتاوى (أردو)',
    nameEnglish: 'Ashraf ul Fatawa (Urdu)',
    link: 'https://play.google.com/store/apps/details?id=jamiaashrafia.ashrafulfatawa',
  },
  {
    name: 'احسن السوانح (أردو)',
    nameEnglish: 'Ahsan ul Sawaneh (Urdu)   ',
  },
  {
    name: 'الرد على الشبهات القاديانية (أردو)',
    nameEnglish: 'Qadiani Shubhaat kay Jawabaat (Urdu)  ',
    link: 'https://play.google.com/store/apps/details?id=com.digitallibrary.islamiculoom.thedigitallibrary',
  },
];

export const webData = [
  {
    name: 'موسوعة الحديث النبوي الشريف مع ترجمات الى اللغة الأردية',
    nameEnglish: 'Hadith Encyclopedia with Urdu translations',
    link: 'https://www.mohaddis.com/View/Sahi-Bukhari/T2/1',
  },
  {
    name: 'المحتوى الرقمي للتنظيم الإسلامي',
    nameEnglish: 'Digitization of contents of Tanzeem Islami',
    link: 'https://tanzeemdigitallibrary.com/',
  },
  {
    name: 'تطبيق القران والحديث',
    nameEnglish:
      'Easy Quran wa Hadees (Senior member development of Desktop Application',
    link: 'https://easyquranwahadees.com/',
  },
  {
    name: 'تطوير موقع الجامعة اللأشرفية لاهور',
    nameEnglish: 'Jamia Ashrafia Lahore website development',
    link: 'https://jamiaashrafia.com/',
  },
];
export const takhliqiData = [
  {
    name: 'تقنيات معالجة اللغة الطبيعية المطبقة على نص القرآن الكريم أعمال البحث والتطوير في القدرات الشخصية)',
    nameEnglish:
      'Natural Language Processing techniques applied on Quran Text (R&D work in personal capacity)',
  },
  {
    name: 'نظام آلي لتعليم النحو والصرف في اللغة العربية لمستخدمي اللغة الأوردية (عملية البحث والتطوير في القدرات الشخصية)    ',
    nameEnglish:
      'Algorithm base automated Classical Arabic morphology teaching system (R&D work in personal capacity)',
  },
  {
    name: 'نظام آلي لتعليم النحو والصرف في اللغة العربية لمستخدمي اللغة الانجليزية (عملية البحث والتطوير في القدرات الشخصية)',
    nameEnglish:
      'Algorithm base automated Classical Arabic morphology teaching system (R&D work in personal capacity)',
  },
  {
    name: 'نظام الامتحانات لمعلمي اللغة العربية 1    ',
    nameEnglish:
      'Examination systems for Arabic learners (R&D work in personal capacity)    ',
  },
  {
    name: 'نظام الامتحانات لمعلمي اللغة العربية 2    ',
    nameEnglish:
      'Examination systems for Arabic learners (R&D work in personal capacity)',
  },
  {
    name: 'نظام الامتحانات لمعلمي اللغة العربية 3    ',
    nameEnglish:
      'Examination systems for Arabic learners (R&D work in personal capacity)',
  },
];
