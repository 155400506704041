import React from "react";
import { Grid, Box, makeStyles, TextField, Button } from "@material-ui/core";
import Navbar from "../Components/Navbar/Navbar";
import logo from "../Assets/Images/Icon/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // backgroundColor: '#fff',
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
    flexDirection: "column",
    "& .MuiFormControl-root": {
      backgroundColor: "#fff",
      border: "none",
      "& .MuiInputLabel-outlined": {
        color: "burlywood",
      },
    },
    "& .MuiButton-root": {
      backgroundColor: "burlywood",
      color: "#fff",
      padding: "0.7rem 5rem",
      margin: "1rem 0rem",
      fontWeight: "700",
    },
  },
}));
const Contact = () => {
  const classes = useStyles();
  return (
    <div>
      <Navbar />
      <div className="topicpage" style={{ flex: 1 }}>
        <div>
          <Grid
            container
            className="container"
            md={12}
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Grid
              item
              xs={12}
              className="text-center d-flex flex-column"
              style={{
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {/* <h1 style={{color:'white'}}>{count.count}</h1>
          <button onClick={()=>dispatch(addToCart())} >+</button>
          <button onClick={()=>dispatch(removeToCart())} >-</button> */}

              <Box
                display={{ xs: "block", sm: "block", md: "none", lg: "none" }}
              >
                <img style={{ width: "40%" }} src={logo} alt="" />
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className={classes.root}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={11} sm={11} md={8} lg={8}>
              <TextField
                style={{ borderColor: "black" }}
                id="outlined-multiline-static"
                label="Your Message"
                multiline
                rows={4}
                defaultValue="Default Value"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Button variant="contained">Submit</Button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
