import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Checkbox from "@mui/material/Checkbox";
// import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function ListWithCheckBox({ array }) {
  const history = useHistory();
  //   const [checked, setChecked] = React.useState([1]);
      
  //   const handleToggle = (value) => () => {
  //     const currentIndex = checked.indexOf(value);
  //     const newChecked = [...checked];

  //     if (currentIndex === -1) {
  //       newChecked.push(value);
  //     } else {
  //       newChecked.splice(currentIndex, 1);
  //     }

  //     setChecked(newChecked);
  //   };
  return (
    <List dense sx={{ width: "100%", maxWidth: 360 }}>
      {array && array.map((doc,index) => {
        console.log(doc.data())
        const {exam:{testName,testDate}} = doc.data()
        return (
          <ListItem
          onClick={()=> history.push(`/report/${doc.id}`)}
            key={index}
            //   secondaryAction={
            //     <Checkbox
            //       edge="end"
            //       onChange={handleToggle(value)}
            //       checked={checked.indexOf(value) !== -1}
            //       inputProps={{ "aria-labelledby": labelId }}
            //     />
            //   }
            disablePadding
          >
            <ListItemButton>
              {/* <ListItemAvatar>
                <Avatar
                  alt={`Avatar n°${value + 1}`}
                  src={`/static/images/avatar/${value + 1}.jpg`}
                />
              </ListItemAvatar> */}
              <ListItemText
              className="list-item"
                id={index}
                secondary={testDate}
                primary={testName}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
