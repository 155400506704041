import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import { useSelector, useDispatch } from "react-redux";
import Fade from "react-reveal/Fade";
import Login from "../../../Pages/Authentication/Login/Login";
import { loginDisplayShow } from "../../../Redux/action/action";
import Navbar from "../Navbar";

function LoginSweetAlert() {
  const dispatch = useDispatch();

  const login_show = useSelector((state) => state.login_component_show);

  const [loginDisplay, setLoginDisplay] = useState(false);

  const handleLogin = () => {
    // setLoginDisplay(false);
    dispatch(loginDisplayShow(false));
  };

  return (
    <>
      {login_show ? (
        <div
          style={{
            display: `${loginDisplay}`,
            position: "absolute",
            backgroundColor: "transparent",
            width: "100%",
            // height: "100%",
            zIndex: 100,
          }}
        >
          {/* <Fade top>
            <Login onClick={() => handleLogin()} />
          </Fade> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default LoginSweetAlert;
