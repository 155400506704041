import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Tabs, Tab, Typography, Box, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Navbar from "../../Components/Navbar/Navbar";
import PageCard from "../../Components/Card/PageCard";
import {
  urduTarjamaHelper,
  alaamatStarterPastEnglishAll,
  alaamatStarterMaziLazimPatternOne,
  alaamatStarterMaziMutadiAllPattern,
  alaamatMaziLazimPatternOne,
  alaamatMaziLazimPatternTwo,
  alaamatMaziMutadiPatternThree,
  alaamatMaziMutadiPatternOne,
  alaamatMaziMutadiPatternTwo,
  alaamatMuzaraPatternOne,
} from "../../Assets/DataArray";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const TranslationPage = () => {
  const location = useLocation();
  const { FailFullGardaan, headerText } = location.state;

  console.log(FailFullGardaan, "FailFullGardaan");

  const [display, setDisplay] = useState(false);

  // const search = new useLocation().search;
  // const _fael = new URLSearchParams(search).get('فعل');
  // const baabName = new URLSearchParams(search).get('baabName');

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const newWord = urduTarjamaHelper
    .filter((item) => item.title == headerText.split(" ")[2])
    .pop();
  const MaziEnglishGardaanList = [];
  const MuzaraEnglishGardaanList = [];

  const MaziUrduGardaanList = [];
  const MuzaraUrduGardaanList = [];
  if (newWord != undefined) {
    const MaziGardaanCreatorEnglish = (masdar = newWord.data[9]) => {
      for (let i = 0; i < alaamatStarterPastEnglishAll.length; i++) {
        MaziEnglishGardaanList.push(alaamatStarterPastEnglishAll[i] + masdar);
      }
      return MaziEnglishGardaanList;
    };
    MaziGardaanCreatorEnglish();

    const MaziGardaanCreatorUrdu = (
      masdar = newWord.data[3],
      maziPattern = newWord.data[4]
    ) => {
      if (maziPattern == "MaziLazimPatternOne") {
        let starter = masdar.substring(0, masdar.length - 2);
        for (let i = 0; i < alaamatMaziLazimPatternOne.length; i++) {
          MaziUrduGardaanList.push(
            alaamatStarterMaziLazimPatternOne[i] +
              starter +
              alaamatMaziLazimPatternOne[i]
          );
        }
      } else if (maziPattern == "MaziLazimPatternTwo") {
        let starter = masdar.substring(0, masdar.length - 2);
        for (let i = 0; i < alaamatMaziLazimPatternOne.length; i++) {
          MaziUrduGardaanList.push(
            alaamatStarterMaziLazimPatternOne[i] +
              starter +
              alaamatMaziLazimPatternTwo[i]
          );
        }
      } else if (maziPattern == "MaziMutadiPatternOne") {
        let starter = masdar.substring(0, masdar.length - 2);
        for (let i = 0; i < alaamatMaziMutadiPatternOne.length; i++) {
          MaziUrduGardaanList.push(
            alaamatStarterMaziMutadiAllPattern[i] +
              starter +
              alaamatMaziMutadiPatternOne[i]
          );
        }
        return MaziUrduGardaanList;
      } else if (maziPattern == "MaziMutadiPatternTwo") {
        let starter = masdar.substring(0, masdar.length - 4);
        for (let i = 0; i < alaamatMaziMutadiPatternTwo.length; i++) {
          MaziUrduGardaanList.push(
            alaamatStarterMaziMutadiAllPattern[i] +
              starter +
              alaamatMaziMutadiPatternTwo[i]
          );
        }
        return MaziUrduGardaanList;
      } else if (maziPattern == "MaziMutadiPatternThree") {
        let starter = masdar.substring(0, masdar.length - 2);
        for (let i = 0; i < alaamatMaziMutadiPatternThree.length; i++) {
          MaziUrduGardaanList.push(
            alaamatStarterMaziMutadiAllPattern[i] +
              starter +
              alaamatMaziMutadiPatternThree[i]
          );
        }
        return MaziUrduGardaanList;
      }
      return MaziUrduGardaanList;
    };
    MaziGardaanCreatorUrdu();
  }

  console.log(newWord, "newword data");

  return (
    <div className="mashaq-panel">
      <Navbar />
      <Grid container spacing={1} className="container"></Grid>
      <div className="topicpage text-center">
        <PageCard>
          <h2 style={{ color: "white", marginBottom: "0rem" }}>{headerText}</h2>
          <Box display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
            <div className="topic_detail_tabs" style={{ display: "block" }}>
              <Tabs
                orientation="horizontal"
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
              >
                <Tab label="English" />
                <Tab label="Urdu" />
              </Tabs>

              <div className="tabs-left" style={{ overflowY: "auto" }}>
                {newWord != undefined ? (
                  [FailFullGardaan[0]].map((gardan, index = 1) => {
                    return (
                      <>
                        <TabPanel value={value} index={0} key={index}>
                          <Grid container spacing={3} className="tabsdetail">
                            <Grid item xs={6} className="tabsdetail_left">
                              {gardan.FailGardaanSecondSeven.map(
                                (failType, index) => {
                                  return (
                                    <div>
                                      <span key={index}>
                                        <h5>
                                          {MaziEnglishGardaanList[index + 7]}
                                        </h5>{" "}
                                        {/*Urdu-Side*/}
                                        <p>{failType.text}</p> {/*Arabic-Side*/}
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                            </Grid>

                            <Grid item xs={6} className="tabsdetail_right">
                              {gardan.FailGardaanFirstSeven.map(
                                (failType, index) => {
                                  console.log(
                                    MaziEnglishGardaanList,
                                    "MaziEnglishGardaanList"
                                  );
                                  return (
                                    <span key={index}>
                                      {MaziEnglishGardaanList[index]}{" "}
                                      <p>{failType.text}</p>
                                    </span>
                                  );
                                }
                              )}
                            </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1} key={index + 1}>
                          <Grid container spacing={3} className="tabsdetail">
                            <Grid item xs={6} className="tabsdetail_left">
                              {gardan.FailGardaanSecondSeven.map(
                                (failType, index) => {
                                  return (
                                    <div>
                                      <span key={index}>
                                        <h5>
                                          {MaziUrduGardaanList[index + 7]}
                                        </h5>{" "}
                                        {/*Urdu-Side*/}
                                        <p>{failType.text}</p> {/*Arabic-Side*/}
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                            </Grid>

                            <Grid item xs={6} className="tabsdetail_right">
                              {gardan.FailGardaanFirstSeven.map(
                                (failType, index) => {
                                  return (
                                    <span key={index}>
                                      {MaziUrduGardaanList[index]}{" "}
                                      {/*Urdu-Side*/}
                                      <p>{failType.text}</p> {/*Arabic-Side*/}
                                    </span>
                                  );
                                }
                              )}
                            </Grid>
                          </Grid>
                        </TabPanel>
                      </>
                    );
                  })
                ) : (
                  <h1>Coming Soon</h1>
                )}
              </div>
            </div>
          </Box>
          <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
            <div className="topic_detail_tabs-1">
              <Tabs
                orientation="horizontal"
                variant="fullWidth"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                scrollButtons="auto"
                aria-label="Vertical tabs example"
                className="topic_detail_tabs-tabs"
              >
                <Tab label="English" />
                <Tab label="Urdu" />
              </Tabs>

              <div className="tabs-left">
                {[FailFullGardaan[0]].map((gardan, index) => {
                  return (
                    <>
                      <TabPanel value={value} index={0} key={index}>
                        <Grid container spacing={3} className="tabsdetail">
                          <Grid item xs={12} className="tabsdetail_left">
                            {gardan.FailGardaanFirstSeven.map(
                              (failType, index) => {
                                return (
                                  <span key={index}>
                                    {MaziEnglishGardaanList[index]}{" "}
                                    {/*Urdu-Side*/}
                                    <p>{failType.text}</p> {/*Arabic-Side*/}
                                  </span>
                                );
                              }
                            )}
                            <br />
                            {gardan.FailGardaanSecondSeven.map(
                              (failType, index) => {
                                return (
                                  <div>
                                    <span key={index}>
                                      <h5>
                                        {MaziEnglishGardaanList[index + 7]}
                                      </h5>{" "}
                                      {/*Urdu-Side*/}
                                      <p>{failType.text}</p> {/*Arabic-Side*/}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={1} key={index}>
                        <Grid container spacing={3} className="tabsdetail">
                          <Grid item xs={12} className="tabsdetail_left">
                            {gardan.FailGardaanFirstSeven.map(
                              (failType, index) => {
                                return (
                                  <span key={index}>
                                    {MaziUrduGardaanList[index]} {/*Urdu-Side*/}
                                    <p>{failType.text}</p> {/*Arabic-Side*/}
                                  </span>
                                );
                              }
                            )}
                            <br />
                            {gardan.FailGardaanSecondSeven.map(
                              (failType, index) => {
                                return (
                                  <div>
                                    <span key={index}>
                                      <h5>{MaziUrduGardaanList[index + 7]}</h5>{" "}
                                      {/*Urdu-Side*/}
                                      <p>{failType.text}</p> {/*Arabic-Side*/}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </>
                  );
                })}
              </div>
            </div>
          </Box>
        </PageCard>
      </div>
    </div>
  );
};
export default TranslationPage;

const alif = "ا",
  fatha = "َ",
  jazam = "ْ",
  taa = "ت",
  kasra = "ِ",
  seen = "س",
  noon = "ن",
  shad = "ّ",
  wao = "و",
  Zamma = "ُ",
  KharaZabar = "ٰ";

const ConvertBaseFailIntoGardan = (faelText, baabName, root) => {
  const FailGardaanFirstSeven = [];
  const FailGardaanSecondSeven = [];

  var gardaanStarter = "";
  const str2 = faelText.charAt(0),
    str3 = faelText.charAt(1),
    str4 = faelText.charAt(2);

  gardaanStarter = str2 + fatha + str3 + fatha + str4;
  console.log(
    str2,
    "str2",
    +fatha,
    "fatha",
    +str3,
    "str3" + fatha,
    "fatha",
    +str4,
    "str4"
  );

  if (root === "ماضی معروف") {
    if ((baabName === "فتح") | (baabName === "نصر") | (baabName === "ضرب"))
      gardaanStarter = str2 + fatha + str3 + fatha + str4;
    else if ((baabName === "سمع") | (baabName === "حسب"))
      gardaanStarter = str2 + fatha + str3 + kasra + str4;
    else if (baabName === "کرم")
      gardaanStarter = str2 + fatha + str3 + Zamma + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "افعال")
      gardaanStarter = alif + fatha + str2 + jazam + str3 + fatha + str4;
    else if (baabName === "تفاعل")
      gardaanStarter = taa + fatha + str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "افتعال")
      gardaanStarter =
        alif + kasra + str2 + jazam + taa + fatha + str3 + fatha + str4;
    else if (baabName === "انفعال")
      gardaanStarter =
        alif + kasra + noon + jazam + str2 + fatha + str3 + fatha + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        alif +
        kasra +
        seen +
        jazam +
        taa +
        fatha +
        str2 +
        jazam +
        str3 +
        fatha +
        str4;

    for (let index = 0; index < AlaamatMaazi.length; index++) {
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text: gardaanStarter + AlaamatMaazi[index],
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardaanStarter + AlaamatMaazi[index],
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (root === "ﻣﹹﻀﺎﺭﻉ معروف") {
    if (baabName === "فتح" || baabName === "سمع")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "ضرب" || baabName === "حسب")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "کرم" || baabName === "نصر")
      gardaanStarter = str2 + jazam + str3 + Zamma + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + fatha + str3 + shad + kasra + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "افعال")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "تفاعل")
      gardaanStarter = taa + fatha + str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + fatha + alif + str3 + kasra + str4;
    else if (baabName === "انفعال")
      gardaanStarter = noon + jazam + str2 + fatha + str3 + kasra + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        seen + jazam + taa + fatha + str2 + jazam + str3 + kasra + str4;
    else if (baabName === "افتعال")
      gardaanStarter = str2 + jazam + taa + fatha + str3 + kasra + str4;

    for (let index = 0; index < AlaamatMuzaraStart.length; index++) {
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text:
            AlaamatMuzaraStart[index] +
            gardaanStarter +
            AlaamatMuzaraEnd[index],
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text:
            AlaamatMuzaraStart[index] +
            gardaanStarter +
            AlaamatMuzaraEnd[index],
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (root === "ماضی مجہول") {
    if (
      baabName === "فتح" ||
      baabName === "نصر" ||
      baabName === "ضرب" ||
      baabName === "سمع" ||
      baabName === "حسب" ||
      baabName === "کرم"
    )
      gardaanStarter = str2 + Zamma + str3 + kasra + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + Zamma + str3 + shad + kasra + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + Zamma + str2 + Zamma + str3 + shad + kasra + str4;
    else if (baabName === "افعال")
      gardaanStarter = alif + Zamma + str2 + jazam + str3 + kasra + str4;
    else if (baabName === "تفاعل")
      gardaanStarter =
        taa + Zamma + str2 + Zamma + wao + jazam + str3 + kasra + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + Zamma + wao + jazam + str3 + kasra + str4;
    else if (baabName === "انفعال")
      gardaanStarter =
        alif + Zamma + noon + jazam + str2 + Zamma + str3 + kasra + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        alif +
        Zamma +
        seen +
        jazam +
        taa +
        Zamma +
        str2 +
        jazam +
        str3 +
        kasra +
        str4;
    else if (baabName === "افتعال")
      gardaanStarter =
        alif + Zamma + str2 + jazam + taa + Zamma + str3 + kasra + str4;

    for (let index = 0; index < AlaamatMaazi.length; index++) {
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text: gardaanStarter + AlaamatMaazi[index],
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardaanStarter + AlaamatMaazi[index],
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (root === "ﻣﹹﻀﺎﺭﻉ مجہول") {
    if (
      baabName === "فتح" ||
      baabName === "سمع" ||
      baabName === "ضرب" ||
      baabName === "حسب" ||
      baabName === "کرم" ||
      baabName === "نصر"
    )
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "افعال")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "تفاعل")
      gardaanStarter = taa + fatha + str2 + fatha + alif + str3 + kasra + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "انفعال")
      gardaanStarter = noon + jazam + str2 + fatha + str3 + fatha + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        seen + jazam + taa + fatha + str2 + jazam + str3 + fatha + str4;
    else if (baabName === "افتعال")
      gardaanStarter = str2 + jazam + taa + fatha + str3 + fatha + str4;

    for (let index = 0; index < AlaamatMuzaraMajhoolStart.length; index++) {
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text:
            AlaamatMuzaraMajhoolStart[index] +
            gardaanStarter +
            AlaamatMuzaraEnd[index],
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text:
            AlaamatMuzaraMajhoolStart[index] +
            gardaanStarter +
            AlaamatMuzaraEnd[index],
          zameer: UrduZameerTite[index],
        });
      }
    }
  } else if (root === "امر") {
    if (baabName === "فتح" || baabName === "سمع")
      gardaanStarter = alif + kasra + str2 + jazam + str3 + fatha + str4;
    else if (baabName === "ضرب" || baabName === "حسب")
      gardaanStarter = alif + kasra + str2 + jazam + str3 + kasra + str4;
    else if (baabName === "کرم" || baabName === "نصر")
      gardaanStarter = alif + Zamma + str2 + jazam + str3 + Zamma + str4;
    else if (baabName === "افعال")
      gardaanStarter = alif + fatha + str2 + jazam + str3 + kasra + str4;
    else if (baabName === "تفعیل")
      gardaanStarter = str2 + fatha + str3 + shad + kasra + str4;
    else if (baabName === "مفاعلہ")
      gardaanStarter = str2 + fatha + alif + str3 + kasra + str4;
    else if (baabName === "تفعل")
      gardaanStarter = taa + fatha + str2 + fatha + str3 + shad + fatha + str4;
    else if (baabName === "تفاعل")
      gardaanStarter = taa + fatha + str2 + fatha + alif + str3 + fatha + str4;
    else if (baabName === "افتعال")
      gardaanStarter =
        alif + kasra + str2 + jazam + taa + fatha + str3 + kasra + str4;
    else if (baabName === "انفعال")
      gardaanStarter =
        alif + kasra + noon + jazam + str2 + fatha + str3 + kasra + str4;
    else if (baabName === "استفعال")
      gardaanStarter =
        alif +
        kasra +
        seen +
        jazam +
        taa +
        fatha +
        str2 +
        jazam +
        str3 +
        kasra +
        str4;

    for (let index = 0; index < AlaamatAmar.length; ++index) {
      if (index < 3) {
        FailGardaanFirstSeven.push({
          text: gardaanStarter + AlaamatAmar[index],
          zameer: AmarUrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardaanStarter + AlaamatAmar[index],
          zameer: AmarUrduZameerTite[index],
        });
      }
    }
  } else if (root === "ﻣﹹﻀﺎﺭﻉ منصوب") {
    if (baabName === "فتح" || baabName === "سمع")
      gardaanStarter = str2 + jazam + str3 + fatha + str4;
    else if (baabName === "ضرب" || baabName === "حسب")
      gardaanStarter = str2 + jazam + str3 + kasra + str4;
    else if (baabName === "کرم" || baabName === "نصر")
      gardaanStarter = str2 + jazam + str3 + Zamma + str4;

    for (let index = 0; index < AlaamatMuzaraStart.length; ++index) {
      var gardanText =
        "لَنْ " +
        AlaamatMuzaraNawasibStart[index] +
        gardaanStarter +
        AlaamatMuzaraNawasibEnd[index];
      if (index < 7) {
        FailGardaanFirstSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      } else {
        FailGardaanSecondSeven.push({
          text: gardanText,
          zameer: UrduZameerTite[index],
        });
      }
    }
  }

  return { FailGardaanFirstSeven, FailGardaanSecondSeven };
};

const AlaamatMaazi = [
  "َ",
  "َا",
  "ُوْا",
  "َتْ",
  "َتَا",
  "ْنَ",
  "ْتَ",
  "ْتُمَا",
  "ْتُمْ",
  "ْتِ",
  "ْتُمَا",
  "ْتُنَّ",
  "ْتُ",
  "ْنَا",
];

const AlaamatMuzaraStart = [
  "یَ",
  "یَ",
  "یَ",
  "تَ",
  "تَ",
  "یَ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "اَ",
  "نَ",
];

const AlaamatMuzaraEnd = [
  "ُ",
  "َانِ",
  "ُوْنَ",
  "ُ",
  "َانِ",
  "ْنَ",
  "ُ",
  "َانِ",
  "ُوْنَ",
  "ِیْنَ",
  "َانِ",
  "ْنَ",
  "ُ",
  "ُ",
];

const AlaamatMuzaraMajhoolStart = [
  "یُ",
  "یُ",
  "یُ",
  "تُ",
  "تُ",
  "یُ",
  "تُ",
  "تُ",
  "تُ",
  "تُ",
  "تُ",
  "تُ",
  "اُ",
  "نُ",
];

const UrduZameerTite = [
  "واحد مذکر غائب",
  "تثنیہ مذکر غائب",
  "جمع مذکر غائب",
  "واحد مؤنث غائب",
  "تثنیہ مؤنث غائب",
  "جمع مؤنث غائب",
  "واحد مذکر حاضر",
  "تثنیہ مذکر حاضر",
  "جمع مذکر حاضر",
  "واحد مؤنث حاضر",
  "تثنیہ مؤنث حاضر",
  "جمع مؤنث حاضر",
  "واحد مذکر/مؤنث متکلم",
  "تثنیہ/جمع  مذکر/مؤنث  متکلم",
];

const AlaamatMuzaraNawasibStart = [
  "یَّ",
  "یَّ",
  "یَّ",
  "تَ",
  "تَ",
  "یَّ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "اَ",
  "نَّ",
];
const AlaamatMuzaraNawasibEnd = [
  "َ",
  "َا",
  "ُوْا",
  "َ",
  "َا",
  "ْنَ",
  "َ",
  "َا",
  "ُوْا",
  "ِیْ",
  "َا",
  "ْنَ",
  "َ",
  "َ",
];

const AlaamatAmar = ["ْ", "َا", "ُوْا", "ِیْ", "َا", "ْنَ"];
const AmarUrduZameerTite = [
  "واحد مذکر حاضر",
  "تثنیہ مذکر حاضر",
  "جمع مذکر حاضر",
  "واحد مؤنث حاضر",
  "تثنیہ مؤنث حاضر",
  "جمع مؤنث حاضر",
];
