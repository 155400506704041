import ConstValues, {
  fatha,
  hamzaMaftoh,
  hamzaMazmoom,
  noonMushadidMaftooh,
  noonMushadidMazmoom,
  tamaftoh,
  tamazmoom,
  yamushadadmaftoh,
  yaMushadadMazmoom,
  laa, liaa,lina,litaa,liya, jazam, alif, hamzamaksoor, wao, Zamma, ooo, kasra,yaa, noonmaftoh,
  lioo, liyu,linu,lituu, space, noonMushadidMaksoor, noon, noonsakin,
} from "./ConstValues";

export const AlaamatMaazi = [
  fatha,"َا","ُوْا","َتْ","َتَا","ْنَ","ْتَ","ْتُمَا","ْتُمْ","ْتِ","ْتُمَا","ْتُنَّ","ْتُ","ْنَا",
];

export const AlaamatMuzaraStart = [
  "یَ","یَ","یَ","تَ","تَ","یَ","تَ","تَ","تَ","تَ","تَ","تَ","اَ","نَ",
];

export const AlaamatAmarBanoonSaqeelaStart = [
  liya, liya, liya, litaa, litaa, liya,
  hamzamaksoor, hamzamaksoor, hamzamaksoor,
  hamzamaksoor, hamzamaksoor, hamzamaksoor,
  "اَ","نَ",
];

export const AlaamatAmarBanoonSaqeelaMajhoolStart = [
  liyu, liyu, liyu, lituu, lituu, liyu,
  lituu,lituu,lituu,lituu,lituu,lituu,
  lioo,linu,
];

export const AlaamatMuzaraNafiStart = [
  laa + "یَ",laa + "یَ",laa + "یَ",laa + "تَ",laa + "تَ",laa + "یَ",laa + "تَ",laa + "تَ",laa + "تَ",laa + "تَ",laa + "تَ",laa + "تَ",laa + "اَ",laa + "نَ",
];

export const AlaamatMuzaraEnd = [
  "ُ","َانِ","ُوْنَ","ُ","َانِ","ْنَ","ُ","َانِ","ُوْنَ","ِیْنَ","َانِ","ْنَ","ُ","ُ",
];

export const AlaamatAmarMaroofBanoonSaqeelaEnd = [
  fatha + noonMushadidMaftooh, 
  fatha + alif + space + noonMushadidMaksoor,
  Zamma + noonMushadidMaftooh,
  fatha + noonMushadidMaftooh,
  fatha + alif + space + noonMushadidMaksoor, 
  jazam + noon + fatha + alif + noonMushadidMaksoor,
  fatha + noonMushadidMaftooh,
  fatha + alif + space + noonMushadidMaksoor,
  Zamma + noonMushadidMaftooh,
  kasra + noonMushadidMaftooh,
  jazam + noon + fatha + alif + noonMushadidMaksoor,
  jazam + noon + fatha + alif + noonMushadidMaksoor,
  fatha + noonMushadidMaftooh,
  fatha + noonMushadidMaftooh,
];

export const AlaamatAmarMaroofBanoonKhafeefaEnd = [
  fatha + noonsakin, "-",
  Zamma + noonsakin,
  fatha + noonsakin,"_", "_",
  fatha + noonsakin,"_",
  Zamma + noonsakin,
  kasra + noonsakin,"_","_",
  fatha + noonsakin,
  fatha + noonsakin,
];

export const AlaamatMuzaraMajhoolStart = [
  "یُ","یُ","یُ","تُ","تُ","یُ","تُ","تُ","تُ","تُ","تُ","تُ","اُ","نُ",
];

export const UrduZameerTite = [
  "واحد مذکر غائب","تثنیہ مذکر غائب","جمع مذکر غائب",
  "واحد مؤنث غائب","تثنیہ مؤنث غائب","جمع مؤنث غائب","واحد مذکر حاضر",
  "تثنیہ مذکر حاضر","جمع مذکر حاضر","واحد مؤنث حاضر","تثنیہ مؤنث حاضر",
  "جمع مؤنث حاضر","واحد مذکر/مؤنث متکلم","تثنیہ/جمع  مذکر/مؤنث  متکلم",
];

export const AlaamatMuzaraNawasibStart = [
  "یَّ","یَّ","یَّ","تَ","تَ","یَّ","تَ","تَ","تَ","تَ","تَ","تَ","اَ","نَّ",
];

export const AlaamatLaamTakeedEnd= [
  noonMushadidMaftooh, alif + space + noonMushadidMaksoor, noonMushadidMaftooh,
  noonMushadidMaftooh, alif + space + noonMushadidMaksoor, noon + fatha + alif + noonMushadidMaksoor,
  noonMushadidMaftooh, alif + space + noonMushadidMaksoor, noonMushadidMaftooh,
  noonMushadidMaftooh, alif + space + noonMushadidMaksoor, noon + fatha + alif + noonMushadidMaksoor,
  noonMushadidMaftooh, noonMushadidMaftooh
]

export const AlaamatMuzaraNawasibMajhoolStart = [
  yaMushadadMazmoom,
  yaMushadadMazmoom,
  yaMushadadMazmoom,
  tamazmoom,
  tamazmoom,
  yaMushadadMazmoom,
  tamazmoom,
  tamazmoom,
  tamazmoom,
  tamazmoom,
  tamazmoom,
  tamazmoom,
  hamzaMazmoom,
  noonMushadidMazmoom,
];

export const AlaamatMuzaraNawasibEnd = [
  "َ","َا","ُوْا","َ","َا","ْنَ","َ","َا","ُوْا","ِیْ","َا","ْنَ","َ","َ",
];
export const AlaamatMuzaraJazimEnd = [
  "ْ","َا","ُوْا","ْ","َا","ْنَ","ْ","َا","ُوْا","ِیْ","َا","ْنَ","ْ","ْ",
];

//export const AlaamatAmar = ["ْ", "َا", "ُوْا", "ِیْ", "َا", "ْنَ"];
export const AlaamatAmarStart = [
liya,liya,liya,litaa,litaa,liya,
"","","","","","",
liaa,lina
];

export const AlaamatAmarMajhoolStart = [
  liyu,liyu,liyu,lituu,lituu,liyu,
  "","","","","","",
  lioo,linu
  ];

export const AlaamatAmarEnd = [jazam,fatha + alif, ooo, jazam, fatha + alif, jazam + noonmaftoh,
jazam,fatha+alif,ooo,kasra+yaa+jazam, fatha+alif, jazam + noonmaftoh,
jazam,jazam];

