import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Typography } from "@material-ui/core";
import Navbar from "../../../Components/Navbar/Navbar";
import Logo from "../../../Assets/Images/Icon/logo.svg";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import HeadShake from "react-reveal/HeadShake";
import { getData, loginApi, errorClear } from "../../../Redux/action/action";
import Box from "@material-ui/core/Box";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";

import Fade from "react-reveal/Fade";
import { useHistory } from "react-router";

import { userIsSignIn,handleSigninWithEmailAndPassword } from "../../../Assets/firebase";
const Login = (props) => {
  // const counter = useSelector((state) => state);
  const [errorIs, setErrorIs] = useState(false);
  const [isSignIn, setIsSignIn] = useState(null);
  const [show, setShow] = useState(false);
  useEffect(()=>{
    userIsSignIn((user)=>{
      user ? setIsSignIn(true) : setIsSignIn(false)
    })
  },[])
  const login_api_data = useSelector((state) => state.login_Detail);

  const erro_detail = useSelector((state) => state.erro_detail);

  const { error, message } = login_api_data != undefined && login_api_data;

  const dispatch = useDispatch();
  const history = useHistory();

  // useEffect(() => {
  //   dispatch(getData());
  // }, []);

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const createHandler = () => {
    return history.push("/signup");
  };

  const Success_Toast = () =>
    toast.success(`Login Successfuly`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const ErrorNotification = (error) => {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
   
  };

  const onSubmit = () => {
    handleSigninWithEmailAndPassword(state.email,state.password,(response,error)=>{
      if (response) {
        console.log(response);
        history.push("/");
        Success_Toast();
      } else {
        let customError = `Error: ${error.message.split("auth/")[1].split(')')[0].replace(/-/g, ' ')}`;
        ErrorNotification(customError);
      }
    })
    
  }; // ends handle submit
  // const onSubmit = async () => {
  //   const newdata = {
  //     email: state.email,
  //     password: state.password,
  //   };

  //   await dispatch(loginApi(newdata));

  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     history.push("/");
  //     Success_Toast();
  //   } else {
  //     setErrorIs(true);

  //     ErrorNotification();
  //   }
  // };
  const validEmail = state.email === "" ? true : (state.email.includes("@") && state.email.includes("." )) ? true : false,
  validPassword = state.password === "" ? true : state.password.length < 8 ? false : true,
  allChecked = (validEmail && validPassword && state.email !== "" && state.password !== "");
  console.log(isSignIn)
  if(isSignIn){ 
    history.push("/")
    return ""
} else if(isSignIn == null) { return ""
} else if(isSignIn === false) return (
    <div>
      <div style={{ backgroundColor: "whitesmoke" }} className="login-page">
        <Fade top>
          <div className="login-card">
            {props.onClick && props.onClick ? (
              <div
                style={{
                  display: "flex",
                  padding: 10,
                  justifyContent: "flex-end",
                  width: "100%",
                  fontSize: 30,
                  color: "black",
                  marginTop: "-20px",
                  cursor: "pointer",
                }}
              >
                <p onClick={props.onClick}>x</p>
              </div>
            ) : (
              ""
            )}

            {/* <Box display={{ xs: "none", sm: "none", md: "block", lg: "block" }}> */}
            <div className="toolbar_logo">
              <img style={{ width: "160px" }} src={Logo} alt="" />
            </div>
            {/* </Box> */}

            {/* <Typography variant="h6" align="center">
            Welcome to Al Tasreef
          </Typography> */}
            <Typography variant="h6" align="center">
              Sign-in to your account
            </Typography>

            <form action="">
              <TextField
                fullWidth
                name="email"
                id="outlined-basic"
                label="email"
                variant="outlined"
                type="email"
                helperText={validEmail ? "" : "Enter a valid email"}
                error={!validEmail}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                name="password"
                id="outlined-basic"
                label="password"
                variant="outlined"
                type="password"
                onChange={handleChange}
                helperText={validPassword ? "" : "Invalid password"}
                error={!validPassword}

              />
            </form>

            

            <div className="forgetPassword-div">
              <p
                style={{ fontSize: 20, Color: "black", cursor: "pointer" }}
                onClick={() => history.push("/forget_password")}
              >
                forget Password ?
              </p>
            </div>

            <button
            disabled={!allChecked}
              onClick={() => onSubmit()}
              type="button"
              class="btn btn-info"
            >
              Sign In
            </button>
            <div
              style={{
                margin: "auto",
                width: "80%",
              }}
            >
              <p
                style={{ fontSize: 20, Color: "black", cursor: "pointer" }}
                className="login-btn"
              >
                Not registered?{" "}
                <a 
                style={{ textDecoration: "underline", color: "#366883" }}
                onClick={() => createHandler()}
                >
                  Create an account
                </a>
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Login;
