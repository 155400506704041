import { Grid, Card,Typography } from "@material-ui/core";
import React from "react";
import Icon1 from "../../../Assets/Images/Icon/imtehan-1.svg";
import { Link } from "react-router-dom";

const CardNew = (props) => {
  return (
    <Card className="test-box">
      <Link
        onClick={props.onClick}
        to={props.url}
        className="d-flex align-items-center justify-content-center flex-column"
      >
        {/* <a
        href=
       
      > */}
      <img
          style={{
            // backgroundColor: "white",
            width: "65px",
            height: "65px",
            // borderRadius: "100px",
            padding: "5px",
            boxSizing: "border-box",
          }}
          src={props.img}
          alt={props.title}
        />
 
        <span className='dashboard_title'>{props.title}</span>
        
      </Link>
    </Card>
  );
};

export default CardNew;
