import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  addToCart,
  removeToCart,
  sweetAlertHide,
  sweetAlertShow,
  ifLnaguageEnglish,
  mushq_afal_slaasi,
  imtihan_afal_slaasi,
} from "../../Redux/action/action";
import Kalma from "../../Assets/Images/Icon/kalma.svg";
import Fade from "react-reveal/Fade";
import Login from "../Authentication/Login/Login";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Card from "./HomeCard/Card";
import logo from "../../Assets/Images/Icon/logo.png";
import Icon1 from "../../Assets/Images/Icon/imtehan-1.svg";
import Icon2 from "../../Assets/Images/Icon/imtehan-2.svg";
import Icon3 from "../../Assets/Images/Icon/imtehan-3.svg";
import Icon4 from "../../Assets/Images/Icon/imtehan-4.svg";
import Icon5 from "../../Assets/Images/Icon/imtehan-5.svg";
import Icon6 from "../../Assets/Images/Icon/imtehan-6.svg";
import Navbar from "../../Components/Navbar/Navbar";
import SweetAlert from "react-bootstrap-sweetalert";
import Box from "@material-ui/core/Box";
// import { history } from "../../configs/history";
import {
  addNewComponent,
  TestData,
  getAllData,
} from "../../Redux/action/action";

import Alert from "../../Assets/Images/Icon/alert.svg";
import {
  englishArray,
  newTransWords,
  urduArray,
} from "../MashaqContent/AllWordsArray";
import { userIsSignIn } from "../../Assets/firebase";

const Home = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [update, setUpdate] = useState(false);
  const [faielExercise, setFaielExercise] = useState(false);
  const [english, setEnglish] = useState(false);

  const count = useSelector((state) => state);

  const sweet_alert_updates = useSelector((state) => state.languageAlert);
  const imtihanFall = useSelector((state) => state.imtihanFall);

  const if_inglish = useSelector((state) => state.english);

  const mashq_afal_update = useSelector((state) => state);
  const { mashaqFall } = mashq_afal_update;
  const login_show = useSelector((state) => state.login_component_show);

  const [loginDisplay, setLoginDisplay] = useState(login_show);

  const [result_url_State, setResul_url_State] = useState("/");

  const handleMashaqSalaasiMujarad = () => {
    if (update || mashaqFall) {
      history.push("/mashaq-salaasi-mujarad");
      history.go();
    } else if (imtihanFall) {
      history.push("/mashaq-salaasi-mujarad-fail");
      history.go();
    } else {
      history.push("/mashaq-salaasi-mujarad-fail");
      history.go();
    }
  };

  const handleEnglishSection = (e) => {
    setEnglish(e);
  };

  const handleUrduSection = (e) => {
    setEnglish(e);
  };

  const Record_Handle = () => {
    userIsSignIn((user)=>{
      if (user) {
        history.push("/report");
        history.go();
      } else {
        setLoginDisplay(true);
      }
    });
  };

  const handleLogin = () => {
    setLoginDisplay(false);
  };

  useEffect(() => {
    dispatch(mushq_afal_slaasi(false));
    dispatch(imtihan_afal_slaasi(false));
  }, []);
  return (
    <>
      {loginDisplay ? history.push("/login") : ""}
      {if_inglish ? (
        <div
          className="home"
          style={{
            display: "flex",
            height: "100vh",
            flex: 1,
            // justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Navbar />
          <div style={{ width: "100%", margin: "0px 0px" }}>
            <Box
              className="text-center"
              style={{ flex: 1, alignItems: "center" }}
              display={{
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <img src={Kalma} style={{ width: "50%" }} alt="" />
              </div>
            </Box>
          </div>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Grid
              container
              className="container"
              md={12}
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Grid
                item
                xs={12}
                className="text-center d-flex flex-column"
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <div className="logoDiv">
                  <Box
                    className="text-center"
                    style={{ flex: 1, alignItems: "center" }}
                    display={{
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    }}
                  >
                    <img src={logo} alt="" />
                  </Box>
                </div>
              </Grid>
            </Grid>
          </div>
          <div
            className="dashbord-sec text-center "
            style={{
              display: "flex",

              minWidth: 350,
            }}
          >
            <Grid container>
              <Grid className="test-outer" item lg={4} md={4} sm={4}>
                <Card
                  url=""
                  title="مشق افعال ثلاثی"
                  img={Icon1}
                  onClick={() => dispatch(mushq_afal_slaasi(true))}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4}>
                <Card url="mashaq-isam" title="مشق مشتقات اسم" img={Icon2} />
              </Grid>
              <Grid item lg={4} md={4} sm={4}>
                <Card url="/question" title="امتحانات اسم" img={Icon3} />
              </Grid>

              <Grid item lg={4} md={4} sm={4}>
                <Card
                  url=""
                  title="امتحانات فعل"
                  img={Icon5}
                  onClick={() => dispatch(imtihan_afal_slaasi(true))}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4}>
                <Card
                  onClick={() => Record_Handle()}
                  url={result_url_State}
                  title="ریکارڈ امتحانات "
                  img={Icon4}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4}>
                <Card url="/others" title="دیگر پروجیکٹس" img={Icon6} />
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div
          className="home"
          style={{
            display: "flex",
            height: "100vh",
            flex: 1,
            overflowY: "hidden",
            // justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Navbar />
          <Box
            className="text-center"
            style={{ flex: 1, alignItems: "center" }}
            display={{
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src={Kalma} style={{ width: "50%" }} alt="" />
            </div>
          </Box>
          <div
            style={{
              flex: 1,
              justifyContent: "space-around",
            }}
          >
            <Grid
              container
              className="container"
              md={12}
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Grid
                item
                xs={12}
                className="text-center d-flex flex-column"
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <div className="logoDiv">
                  <Box
                    className="text-center"
                    style={{ flex: 1, alignItems: "center" }}
                    display={{
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    }}
                  >
                    <img src={logo} alt="" />
                  </Box>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="dashbord-sec text-center ">
            <Grid container>
              <Grid item lg={4} md={4} sm={4}>
                <Card url="/question" title="امتحانات اسم" img={Icon3} />
              </Grid>

              <Grid item lg={4} md={4} sm={4}>
                <Card url="mashaq-isam" title="مشق مشتقات اسم" img={Icon2} />
              </Grid>

              <Grid className="test-outer" item lg={4} md={4} sm={4}>
                <Card
                  url=""
                  title="مشق افعال ثلاثی"
                  img={Icon1}
                  onClick={() => dispatch(mushq_afal_slaasi(true))}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4}>
                <Card url="/others" title="دیگر پروجیکٹس" img={Icon6} />
              </Grid>

              <Grid item lg={4} md={4} sm={4}>
                <Card
                  url={result_url_State}
                  onClick={() => Record_Handle()}
                  title="ریکارڈ امتحانات "
                  img={Icon4}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4}>
                <Card
                  url=""
                  title="امتحانات فعل"
                  img={Icon5}
                  onClick={() => dispatch(imtihan_afal_slaasi(true))}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
