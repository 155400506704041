import { Grid } from '@material-ui/core';
import React from 'react';

const SectionCard = (props) => {
  return (
    <>
      <Grid container className='mt-2' direction='row'>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={8}
          className='text-center section_card'
        >
          {props.children}
        </Grid>
      </Grid>
    </>
  );
};

export default SectionCard;
