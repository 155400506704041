import React from "react";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Navbar from "../../Components/Navbar/Navbar";
import PageCard from "../../Components/Card/PageCard";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const TopicDetail = () => {
  const search = new useLocation().search;
  const _noun = new URLSearchParams(search).get("اسم");

  const [value, setValue] = React.useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const typesOfGardan = ["فاعل", "مفعول"];

  if (_noun != null) {
    localStorage.setItem(
      "noun",
      JSON.stringify({
        _noun,
      })
    );
  }

  const NounFullGardaan = [];
  var headerText = "";
  if (_noun !== null) {
    typesOfGardan.map((type) => {
      NounFullGardaan.push(ConvertBaseNounIntoGardan(_noun, type));
    });
    headerText = `${_noun}`;
  } else {
    const { _noun } = JSON.parse(localStorage.getItem("noun"));
    typesOfGardan.map((type) => {
      NounFullGardaan.push(ConvertBaseNounIntoGardan(_noun, type));
    });
  }

  return (
    <>
      <Navbar />
      <div className="mashaq-panel">
        <Box display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
          <div className="topicpage text-center">
            <PageCard>
              <h2 style={{ color: "black", marginBottom: "20px" }}>
                {headerText}
              </h2>
              <div className="topic_detail_tabs">
                <Tabs
                  orientation="vertical"
                  variant="fullWidth"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <Tab label="فاعل" />
                  <Tab label="مفعول" />
                </Tabs>

                <div className="tabs-left">
                  {NounFullGardaan.map((gardan, index) => {
                    return (
                      <TabPanel value={value} index={index} key={index}>
                        <Grid container spacing={3} className="tabsdetail">
                          <Grid item xs={6} className="tabsdetail_left"></Grid>
                          <Grid item xs={6} className="tabsdetail_right">
                            {gardan.map((nounType, index) => {
                              return (
                                <span key={index}>
                                  <p>{nounType.text}</p>
                                  <h5>{nounType.zameer}</h5>{" "}
                                </span>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </TabPanel>
                    );
                  })}
                </div>
              </div>
            </PageCard>
          </div>
        </Box>
        <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
          <Grid container spacing={3} className="container"></Grid>
          <div className="topicpage text-center">
            <PageCard>
              <h2 style={{ color: "black", margin: "20px" }}>{headerText}</h2>
              <div className="topic_detail_tabs-1">
                <Tabs
                  orientation="horizontal"
                  variant="fullWidth"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  scrollButtons="auto"
                  aria-label="Vertical tabs example"
                  // indicatorColor='green'
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <Tab label="فاعل" />
                  <Tab label="مفعول" />
                </Tabs>

                <div className="tabs-left">
                  {NounFullGardaan.map((gardan, index) => {
                    return (
                      <TabPanel value={value} index={index} key={index}>
                        <Grid container spacing={2} className="tabsdetail">
                          <Grid
                            item
                            xs={12}
                            className="tabsdetail_right noun-gardan-div"
                          >
                            {gardan.map((nounType, index) => {
                              return (
                                <span key={index}>
                                  <p>{nounType.text}</p>
                                  <h5>{nounType.zameer}</h5>{" "}
                                </span>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </TabPanel>
                    );
                  })}
                </div>
              </div>
            </PageCard>
          </div>
        </Box>
      </div>
    </>
  );
};

export default TopicDetail;

const alif = "ا",
  fatha = "َ",
  jazam = "ْ",
  kasra = "ِ",
  Zamma = "ُ";

const ConvertBaseNounIntoGardan = (faelText, root) => {
  const completeGardan = [];

  var gardaanStarter = "";
  const str2 = faelText.charAt(0),
    str3 = faelText.charAt(1),
    str4 = faelText.charAt(2);

  gardaanStarter = "";
  if (root === "فاعل") {
    gardaanStarter = str2 + fatha + alif + str3 + kasra + str4;
  } else if (root === "مفعول") {
    gardaanStarter =
      "م" + fatha + str2 + jazam + str3 + Zamma + "و" + jazam + str4;
  }
  for (let index = 0; index < alaamatIsm.length; ++index) {
    completeGardan.push({
      text: gardaanStarter + alaamatIsm[index],
      zameer: UrduZameerTite[index],
    });
  }
  return completeGardan;
};

const alaamatIsm = ["ٌ", "َانِ", "ُوْنَ", fatha + "ةٌ", "تَانِ", fatha + "اتٌ"];

const UrduZameerTite = [
  "واحد مذکر",
  "تثنیہ مذکر",
  "جمع مذکر",
  "واحد مؤنث",
  "تثنیہ مؤنث",
  "جمع مؤنث",
];
