import React from 'react';

const TopicCard = (props) => {
  return (
    <div className='topicardhead'>
      <div
        style={{
          borderWidth: 1,
          borderRadius: '10px',
        }}
        className='topiccard'
      >
        <a style={{ textAlign: 'justify' }} href={props.url}>
          {' '}
          {props.title}
        </a>
      </div>
    </div>
  );
};

export default TopicCard;
