import {
  isUserID,
  SWEET_ALERT_SHOW,
  SWEET_ALERT_Hide,
  IF_SELECT_ENGLISH,
  MUSHQ_AFAL_SALASI,
  IMTIHAN_AFAL_SALASI,
  MUSHQ_ESUM,
  IMTIHAN_ESUM,
  ALL_EXAMS,
} from "../constants";

const insitialState = {
  name: "zaman",
  count: 0,
  cardData: [],
  languageAlert: false,
  english: false,
  mashaqFall: false,
  imtihanFall: false,
  mashaqEsum: false,
  imtihanEsum: false,
  allExams: null,
  login_Detail: {},
  login_component_show: false,
  url_feal_baabname: {},
  erro_detail: "",
  isUserID: null,
};

const reducer = (state = insitialState, action) => {
  switch (action.type) {
    case SWEET_ALERT_SHOW:
      return { ...state, languageAlert: true };
    case SWEET_ALERT_Hide:
      return { ...state, languageAlert: false };
    case IF_SELECT_ENGLISH:
      return {
        ...state,
        english: action.payload,
        languageAlert: false,
      };
    case MUSHQ_AFAL_SALASI:
      return { ...state, mashaqFall: action.payload };
    case IMTIHAN_AFAL_SALASI:
      return { ...state, imtihanFall: action.payload };
    case MUSHQ_ESUM:
      return { ...state, mashaqEsum: action.payload };
    case IMTIHAN_ESUM:
      return { ...state, imtihanEsum: action.payload };
    case "Login_Display_Show":
      return { ...state, login_component_show: action.payload };
    case "LOGIN":
      return {
        ...state,
        login_Detail: action.payload,
      };
    case "ERROR":
      return {
        ...state,
        erro_detail: action.payload,
      };
    case ALL_EXAMS:
      return {
        ...state,
        allExams: action.payload,
      };
    case isUserID:
      return {
        ...state,
        isUserId: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
