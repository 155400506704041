import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Layout from "../../Components/Layout/Layout";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const TopicDetail = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout mainClass="topicpage" title="التصریف">
      <Grid container spacing={3} className="container">
        <div className="topic_detail_tabs">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            <Tab label="معروف ماضی" />
            <Tab label="معروف ماضی" />
            <Tab label="معروف ماضی" />
            <Tab label="معروف ماضی" />
            <Tab label="معروف ماضی" />
            <Tab label="معروف ماضی" />
            <Tab label="معروف ماضی" />
          </Tabs>

          <div class="tabs-left">
            <TabPanel value={value} index={0}>
              <Grid container spacing={3} className="tabsdetail">
                <Grid item xs={6} className="tabsdetail_left">
                  <h5>امتحان فعل</h5>
                  <h5>امتحان فعل</h5>
                  <h5>امتحان فعل</h5>
                  <h5>امتحان فعل</h5>
                  <h5>امتحان فعل</h5>
                  <h5>امتحان فعل</h5>
                </Grid>
                <Grid item xs={6} className="tabsdetail_right">
                  <h5>امتحان فعل</h5>
                  <h5>امتحان فعل</h5>
                  <h5>امتحان فعل</h5>
                  <h5>امتحان فعل</h5>
                  <h5>امتحان فعل</h5>
                  <h5>امتحان فعل</h5>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
              Item Three
            </TabPanel>
            <TabPanel value={value} index={3}>
              Item Four
            </TabPanel>
            <TabPanel value={value} index={4}>
              Item Five
            </TabPanel>
            <TabPanel value={value} index={5}>
              Item Six
            </TabPanel>
            <TabPanel value={value} index={6}>
              Item Seven
            </TabPanel>
          </div>
        </div>
      </Grid>
    </Layout>
  );
};

export default TopicDetail;
