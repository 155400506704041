import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "../../../Assets/Images/Icon/alert.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ifLnaguageEnglish } from "../../../Redux/action/action";

export default function Sweet_Alert_Component(props) {
  const dispatch = useDispatch();

  const sweet_alert_updates = useSelector((state) => state.languageAlert);

  // const if_inglish = useSelector((state) => state.english);
  // const [update, setUpdate] = useState(false);

  return (
    <SweetAlert
      style={{ backgroundColor: "white", color: "bblack" }}
      show={sweet_alert_updates ? true : false}
      showCancel
      title="Language"
      customButtons={
        <React.Fragment>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Link
              style={{ backgroundColor: "#5c6878" }}
              to={true}
              className="btn mx-2 btn-primary"
              onClick={() => dispatch(ifLnaguageEnglish(true))}
            >
              English
            </Link>
            <Link
              style={{ backgroundColor: "#5c6878" }}
              to={true}
              className="btn mx-2 px-4 btn-primary"
              onClick={() => dispatch(ifLnaguageEnglish(false))}
            >
              اردو
            </Link>
          </div>
        </React.Fragment>
      }
      customIcon={Alert}
      customClass="sweet_alert"
    ></SweetAlert>
  );
}
