import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Grid, Tabs, Tab, Box, Button } from "@material-ui/core";

import TabPanel from "../../Components/Tab/Tabpanel";
import Navbar from "../../Components/Navbar/Navbar";
import PageCard from "../../Components/Card/PageCard";
import { urduTarjamaHelper } from "../../Assets/DataArray";
import { ConvertBaseFailIntoGardan } from "../../Assets/GardanHelpers";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const TopicDetail = () => {
  const dispatch = useDispatch();

  const search = new useLocation().search;
  const _fael = new URLSearchParams(search).get("فعل");
  const baabName = new URLSearchParams(search).get("baabName");
  const { english } = useSelector((state) => state);
  let baabNameData = {
    _fael,
    baabName,
  };
  if (_fael != null) {
    localStorage.setItem(
      "baabNameData",
      JSON.stringify({
        _fael,
        baabName,
      })
    );
  }
  //tabs controller
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const typesOfGardan = [
    "ماضی معروف",
    "ماضی معروف نفی",
    "مضارع معروف",
    "مضارع معروف نفی",
    "ماضی مجہول",
    "ماضی مجہول نفی",
    "مضارع مجہول",
    "مضارع مجہول نفی",
    "امر",
    "امر مجہول",
    "مضارع منصوب",
    "مضارع منصوب مجہول",
    "مضارع مجذوم",
    "مضارع مجذوم مجہول",
    "امر معروف بانون ثقیلہ",
    "امر معروف بانون ثقیلہ مجہول",
    "امر معروف بانون خفیفہ",
    "امر معروف بانون خفیفہ مجہول",
    "نہی معروف ",
    "نہی مجہول"
  ];

  const FailFullGardaan = [];
  var headerText = "";

  if (_fael !== null) {
    typesOfGardan.map((type) => {
      FailFullGardaan.push(ConvertBaseFailIntoGardan(_fael, baabName, type));
    });
    
    headerText = ` گردان ${_fael}`;
  } else {
    const { baabName, _fael } = JSON.parse(
      localStorage.getItem("baabNameData")
    );
    typesOfGardan.map((type) => {
      FailFullGardaan.push(ConvertBaseFailIntoGardan(_fael, baabName, type));
    });
    headerText = ` گردان ${_fael}`;
  }
  console.log('gardan ',FailFullGardaan)

  const newWord = urduTarjamaHelper
    .filter((item) => item.title == headerText.split(" ")[2])
    .pop();

  return (
    <div className="mashaq-panel">
      <Navbar />
      <div className="topicpage text-center">
        {english ? (
          <PageCard>
            <h2 style={{ color: "#084567", marginBottom: "0rem" }}>
              {headerText}
            </h2>
            <Box display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
              <div className="topic_detail_tabs">
                <Tabs
                  orientation="vertical"
                  variant="fullWidth"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "white",
                    },
                  }}
                >
                  {typesOfGardan.map((type) => (
                    <Tab label={type} />
                  ))}
                </Tabs>
                <div className="tabs-left">
                  {FailFullGardaan.map((gardan, index) => {
                    return (
                      <TabPanel value={value} index={index} key={index}>
                        <Grid container spacing={3} className="tabsdetail">
                          <Grid item xs={6} className="tabsdetail_left">
                            {gardan.map(
                              (failType, index) => {
                                return (
                                  <div>
                                    <span key={index}>
                                      {/*Urdu-Side*/}
                                      <p>{failType.text}</p>
                                      {/*Arabic-Side*/}
                                      <h5>{failType.zameer}</h5>
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </Grid>

                        
                        </Grid>
                      </TabPanel>
                    );
                  })}
                </div>
              </div>
            </Box>
            <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
              <div className="topic_detail_tabs-1">
                <Tabs
                  orientation="horizontal"
                  variant="fullWidth"
                  // variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  scrollButtons="auto"
                  aria-label="Vertical tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <Tab label="ماضی معروف" />
                  <Tab label="مضارع معروف" />
                  <Tab label="ماضی مجہول" />
                  <Tab label="مضارع مجہول" />
                  <Tab label="امر" />
                  <Tab label="مضارع منصوب" />
                  <Tab label="مضارع مجذوم" />
                </Tabs>

                <div className="tabs-left">
                  {FailFullGardaan.map((gardan, index) => {
                    return (
                      <TabPanel value={value} index={index} key={index}>
                        <Grid container spacing={2} className="tabsdetail">
                          <Grid item xs={12} className="tabsdetail_right">
                            {gardan.map(
                              (failType, index) => {
                                return (
                                  <div>
                                    <span key={index}>
                                      <p>{failType.text}</p>
                                      <h5>{failType.zameer}</h5>
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          
                          </Grid>

                         
                        </Grid>
                      </TabPanel>
                    );
                  })}
                </div>
              </div>
            </Box>
          </PageCard>
        ) : (
          <PageCard>
            <h2 style={{ color: "#084567", marginBottom: "0rem" }}>
              {headerText}
            </h2>
            <Box display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
              <div className="topic_detail_tabs">
                <Tabs
                  orientation="vertical"
                  variant="fullWidth"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "white",
                    },
                  }}
                >
                  {typesOfGardan.map((type) => (
                    <Tab label={type} />
                  ))}
                
                </Tabs>

                <div className="tabs-left">
                  {FailFullGardaan.map((gardan, index) => {
                    return (
                      <TabPanel value={value} index={index} key={index}>
                        <Grid container spacing={2} className="tabsdetail">
                          <Grid item xs={12} className="tabsdetail_right">
                            {gardan.map(
                              (failType, index) => {
                                return (
                                  <div>
                                    <span key={index}>
                                      <p>{failType.text}</p> {/*Arabic-Side*/}
                                      <h5>{failType.zameer}</h5> {/*Urdu-Side*/}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </Grid>

                       
                        </Grid>
                      </TabPanel>
                    );
                  })}
                </div>
              </div>
            </Box>
            <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
              <div className="topic_detail_tabs-1">
                <Tabs
                  orientation="horizontal"
                  variant="fullWidth"
                  // variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  scrollButtons="auto"
                  aria-label="Vertical tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <Tab label="ماضی معروف" />
                  <Tab label="مضارع معروف" />
                  <Tab label="ماضی مجہول" />
                  <Tab label="مضارع مجہول" />
                  <Tab label="امر" />
                  <Tab label="مضارع منصوب" />
                </Tabs>

                <div className="tabs-left">
                  {FailFullGardaan.map((gardan, index) => {
                    return (
                      <TabPanel value={value} index={index} key={index}>
                        <Grid container spacing={2} className="tabsdetail">
                          <Grid item xs={12} className="tabsdetail_right">
                            {gardan.map(
                              (failType, index) => {
                                return (
                                  <div>
                                    <span key={index}>
                                      <p>{failType.text}</p>
                                      <h5>{failType.zameer}</h5>
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          
                          </Grid>

                        </Grid>
                      </TabPanel>
                    );
                  })}
                </div>
              </div>
            </Box>
          </PageCard>
        )}

        <Grid container justify="center" alignItems="center">
          {newWord === undefined ? (
            <div></div>
          ) : (
            <Button
              component={Link}
              to={{
                pathname: "/translation-page",
                state: {
                  FailFullGardaan: FailFullGardaan,
                  headerText: headerText,
                },
              }}
            >
              Translate
            </Button>
          )}
        </Grid>
      </div>
    </div>
  );
};
export default TopicDetail;
