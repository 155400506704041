import React from "react";
import Layout from "../../Components/Layout/Layout";

const Hadiyat = () => {
  return (
    <Layout
      mainClass="hadiyat"
      title="ہدایت"
      subtile="آپ کو پر سیکشن میں سے ایک اختیار منتخب کرنا ہے۔"
    >
      <form>
        <input type="text" placeholder="ثلاثی مذید فیہ " />
        <button type="submit">امتحان شروع کریں</button>
      </form>
    </Layout>
  );
};

export default Hadiyat;
