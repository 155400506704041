export const alif = "ا",
  fatha = "َ",
  jazam = "ْ",
  taa = "ت",
  kasra = "ِ",
  seen = "س",
  noon = "ن",
  shad = "ّ",
  wao = "و",
  Zamma = "ُ",
  yaa = "ی",
  meem = "م",
  laam = "ل",
  KharaZabar = "ٰ",
  noonmaftoh = noon + fatha,
  noonmazmoom = noon + Zamma,
  tamaftoh = taa + fatha,
  tamaksor = taa + kasra,
  tamazmoom = taa + Zamma,
  tasakin = taa + jazam,
  yamaftoh = yaa + fatha,
  yamazmom = yaa + Zamma,
  yamushadadmaftoh = yaa + shad + fatha,
  yaMushadadMazmoom = yaa + shad + Zamma,
  hamzaMaftoh = alif + fatha,
  hamzaMazmoom = alif + Zamma,
  hamzamaksoor = alif + kasra,
  noonMushadidMaftooh = noon + shad + fatha,
  noonMushadidMazmoom = noon + shad + Zamma,
  noonMushadidMaksoor = noon + shad + kasra,
  maa = meem + fatha + alif,
  laa = laam + fatha + alif,
  lee = laam + kasra,
  liya = lee + yamaftoh,
  liyu = lee + yamazmom,
  litaa = lee + tamaftoh,
  lituu = lee + tamazmoom,
  liaa = lee + hamzaMaftoh,
  lioo = lee + hamzaMazmoom,
  lina = lee + noonmaftoh,
  linu = lee + noonmazmoom,
  space = " ",
  noonsakin = noon + jazam,
  ooo = Zamma + wao + jazam + alif;

