import React from "react";
// import React, {useState} from 'react'
import Box from "@material-ui/core/Box";

import Toolbar from "./Toolbar";
import "./navbarstyles.css";
import SideDrawer from "./sidemenu/SideDrawer";
import BackDrop from "./backdrop/BackDrop";
import Sweet_Alert_Component from "./alertComponent/Sweet_Alert_Component";

class Navbar extends React.Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    // let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      // sideDrawer = <SideDrawer />;
      backdrop = <BackDrop click={this.backDropClickHandler} />;
    }
    return (
      <div>
        <Box
          style={{ backgroundColor: "transparent", height: "15vh" }}
          display={{ xs: "block", sm: "block", md: "none", lg: "none" }}
        >
          <Toolbar drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          <Sweet_Alert_Component />

          {backdrop}
        </Box>
        <Box
          style={{ backgroundColor: "transparent !important", height: "15vh" }}
          display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
        >
          <Toolbar drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          <Sweet_Alert_Component />

          {backdrop}
        </Box>
      </div>
    );
  }
}

export default Navbar;
