import React, { Component } from "react";
import ReactDOM from "react-dom";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Fade from "react-reveal/Fade";
// import "./styles.css";

const useStyles = makeStyles((theme) => ({
  grid: {
    backgroundColor: "grey",
    height: "50vh",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function App() {

    const history = useHistory()
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className="login-page">
      <Fade top>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <Grid
              container
              style={{ backgroundColor: "white", borderRadius: 20 }}
              className={classes.grid}
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item container justify="center">
                <Grid item container alignItems="center" direction="column">
                  <Grid item>
                    <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      Verification Code
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Paper elevation={0}>
                  <Typography variant="h6">
                    Please enter the verification code sent to your mobile
                  </Typography>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify="center"
                alignItems="center"
                direction="column"
              >
                <Grid item spacing={3} justify="center">
                  <OtpInput
                    separator={
                      <span>
                        <strong>.</strong>
                      </span>
                    }
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "0 1rem",
                      fontSize: "2rem",
                      borderRadius: 4,
                      border: "1px solid rgba(0,0,0,0.3)",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={()=>history.push('/confirm_password')}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Verify
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Fade>
    </div>
  );
}
