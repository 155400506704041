import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Frame from "../../Assets/Images/Icon/Frame.svg";
import { questionTranslation } from "../MashaqContent/AllWordsArray";
import { useHistory, useParams } from "react-router";
import Layout from "../../Components/Layout/Layout";
import { useSelector, useDispatch } from "react-redux";
import { TestData } from "../../Redux/action/action";
import Navbar from "../../Components/Navbar/Navbar";
import { auth } from "../../Assets/firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "0.1rem",
    "& .MuiTab-root": {
      minWidth: "127px",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "& .MuiTab-root": {
      backgroundColor: "#F8DC0F",
      fontSize: "1.2rem",
      color: "#104A6C",
      padding: "12px 50px",
      marginBottom: "1rem",
      fontWeight: "700",
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiBox-root": {
        padding: "0px",
      },
      "& .question-card-main > h2": {
        marginTop: "2.5rem",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const QuestionCardTranslation = () => {
  const dispatch = useDispatch();
  let { name, testname } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const testDate = new Date().toDateString();
  const newAllTests = localStorage.getItem("All-Tests")
    ? JSON.parse(localStorage.getItem("All-Tests"))
    : [];
  const [value, setValue] = React.useState(0);
  const [arrayQuestions, setArrayQuestions] = React.useState([]);

  const [arraySolutions, setArraySolutions] = React.useState([]);
  const [arrayTests, setArrayTests] = React.useState([]);
  const [questionName, setQuestionName] = React.useState("");
  const [translateOption, setTranslateOption] = React.useState("");

  const handleChangeTranslation = (event, item) => {
    setTranslateOption(event.target.value);
    setQuestionName(item.name);
  };

  const handleChange = (event, newValue) => {
    if (translateOption == "") {
      alert("please select all options");
    } else {
      if (newValue < 9) {
        arraySolutions.push({
          text: questionName,
          translation: translateOption,
        });
        setValue(newValue + 1);
        setQuestionName("");
        setTranslateOption("");
      } else {
        arraySolutions.push({
          text: questionName,
          translation: translateOption,
        });
        const obj = {
          testName: "Translation",
          testDate: testDate,
          questionAsked: arrayQuestions.map((q)=>{ return {text: q.name,translation: q.translation}}),
          answerGiven: arraySolutions,
        };
        console.log(obj)
        dispatch(TestData(auth.currentUser,
          obj,
          (id)=>{
            history.push(`/report/${id}`);
          }
        ))
        
      }
    }
  };

  useEffect(() => {
    setArrayQuestions(
      questionTranslation.sort(() => Math.random() - Math.random()).slice(0, 10)
    );

    setValue(0);
  }, []);

  return (
    <div>
      <Navbar/>
      <Layout mainClass="topicpage" title="التصریف">
        <div style={{ width: "100%" }} className="questionCardHeadings">
          <div className={classes.root}>
      
            <h3
              style={{
                color: "#104A6C",
                fontSize: "3rem",
              }}
            >
              <img
                src={Frame}
                alt=""
                style={{ width: "50px", marginRight: "0.5rem" }}
              />
              التصریف
              <img
                src={Frame}
                alt=""
                style={{ width: "50px", marginLeft: "0.5rem" }}
              />
            </h3>
            <TabPanel
              className="question-card-main"
              value={value}
              index={value}
            >
              {arrayQuestions.map((item, index) => {
                if (index == value) {
                  return (
                    <div>
                      <h4
                        style={{
                          color: "#F8DC0F",
                          borderRadius: 20,
                          marginTop: "1rem",
                        }}
                      >
                        سوال نمبر {index + 1}:
                        <span className="arabic" style={{ color: "black" }}>{item.name}</span>
                      </h4>

                      <div className="quiz-div">
                        <FormControl component="fieldset" className="quiz">
                          <RadioGroup
                            aria-label="translation"
                            name="translation"
                            value={translateOption}
                            onChange={(e) => handleChangeTranslation(e, item)}
                          >
                            <Grid container>
                              {item.options.map((item1, index1) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  key={index1}
                                >
                                  <FormControlLabel
                                    value={item1}
                                    control={<Radio />}
                                    label={item1}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  );
                }
              })}
            </TabPanel>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              {[...Array(10).keys()].map((countNumber) => (
                <Tab
                  label="تصدیق کریں"
                  {...a11yProps(countNumber)}
                  style={
                    value == countNumber
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  disabled={value == 10 ? true : false}
                />
              ))}
            </Tabs>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default QuestionCardTranslation;
