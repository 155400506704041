export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_TO_CART = "REMOVE_TO_CART";
export const SWEET_ALERT_SHOW = "SWEET_ALERT_SHOW";
export const SWEET_ALERT_Hide = "SWEET_ALERT_Hide";
export const IF_SELECT_ENGLISH = "IF_SELECT_ENGLISH";
export const MUSHQ_AFAL_SALASI = "MUSHQ_AFAL_SALASI";
export const IMTIHAN_AFAL_SALASI = "IMTIHAN_AFAL_SALASI";
export const MUSHQ_ESUM = "MUSHQ_ESUM";
export const IMTIHAN_ESUM = "IMTIHAN_ESUM";
export const ALL_EXAMS = "ALL_EXAMS";
export const isUserID = null;
