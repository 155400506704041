import React from "react";
import Navbar from "../../Components/Navbar/Navbar";

function Privacy() {
  return (
    <div>
      <Navbar />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="topicpage">
          <h1 style={{ margin: "20px 0px" }}>پر اویسی پالیسی</h1>
          <div style={{ width: "80%", fontSize: "25px" }}>
            اینڈرائڈ ایپ التصريف مولانا حق نواز صاحب نے بنائی ہے، اور یہ ایپ
            بغیر معاوضے کے ہے۔ استعمال کنندہ کو مہیا کی گئی تمام سہولیات بغیر
            معاوضے کے ہیں اور مقصد یہ ہے کہ لوگ علم الصرف کے حوالے سے اس ایپ سے
            استفادہ حاصل کر سکیں. اس اینڈرائڈ ایپ میں ہم فائز ہیں (Firebase کی
            سروسز کو استعمال کر رہے ہیں جس کو استعمال کرنے کا مقصد یہ ہے کہ اس
            چیز کو جانچا جائے کہ استعمال کننده اس سیشن کے ذریعے سے کس انداز سے
            علم الصرف میں مدد حاصل کر رہا ہے۔ یعنی اس نے مشق کے لیے کس قدر اس
            انیمیشن کو استعال کیا ہے اور امتحانات کے لیے کس قدر اس آپریشن کو
            استعمال کیا ہے۔ فائر نہیں کی پرائیویسی پالیسی کے حوالے سے تفصیلات
            درج ذیل لنک پر دیکھی جا سکتی ہیں۔ کمپیوٹر سائنسز کے جدید موضوعات میں
            مشین لرنگ Machine Learning, نیچرل لینگون پروسینگ Natural Language
            Processing، انفارمیشن ریٹریول Information Retrieval اور آرٹیفیشل ا
            یجنس Artificial Intelligence جیسے مضامین شامل ہیں ۔ ان مضامین سے مدد
            لیتے ہوئے لسانیات پر مختلف جہتوں میں کام ہو رہا ہے۔ الحمد لله علم
            الصرف کی یہ ایپ بھی اسی سلسلے کی ایک کڑی ہے۔ یہ ایپ اردو جانے والے
            قارئین کے لئے بنائی گئی ہے۔ اس ایپ سے وہ لوگ استفادہ کر سکتے ہیں جو
            ابتدائی علم الصرف پڑھ رہے ہیں یا پڑھ چکے ہیں۔ اس ایپ کا مقصد ابتدائی
            علم الصرف جانے والے طالبعلموں کو مزید پنتقلی کے لئے مواقع فراہم کرنا
            ہے کہ وہ مشق اور امتحانات کی مدد سے اپنے علم میں مزید پختگی حاصل کر
            سکیں۔ تا کہ وہ قرآن کریم کے صیغوں کو پہچانے میں مہارت حاصل کر سکیں۔
            اس ایپ میں علم الصرف کے تمام صیغے الگور هم Algorithms کی مدد سے خود
            کار طریقے سے بنائے جا رہے ہیں، اسی طرح خود کار طریقے سے امتحانی نظام
            وضع کیا گیا ہے۔ اس ایپ میں ڈیٹا صرف دینی مقاصد کے
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
