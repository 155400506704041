import React from "react";
import SideMenu from "./sidemenu/SideMenu";
import { useSelector } from "react-redux";
import "./navbarstyles.css";
import Logo from "../../Assets/Images/logo.svg";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MenuList from "./MenuList";

const Toolbar = (props) => {
  const english = useSelector((state) => state.english);
  return (
    <div>
      <nav style={{ padding: 30 }}>
        <div
          className="toolbar_navigator d-flex "
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
            <div
              className="toggle-btn"
              style={{
                position: english ? "" : "absolute",
                right: english ? "" : "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!english && (
                <div
                  style={{
                    marginRight: "30px",
                    color: "#fff",
                    fontSize: "24px",
                  }}
                >
                  التصریف
                </div>
              )}
              <SideMenu click={props.drawerToggleClickHandler} />
              {english && (
                <div
                  style={{
                    marginLeft: "30px",
                    color: "#fff",
                    fontSize: "24px",
                  }}
                >
                  التصریف
                </div>
              )}
            </div>
          </Box>

          <Box display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
            <div className="toolbar_logo">
              <img style={{ width: "120px" }} src={Logo} alt="" />
            </div>
          </Box>
          <div className="toolbar_navigation-items">
            <MenuList />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Toolbar;
