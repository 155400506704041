import React from 'react'
import {Grid} from "@material-ui/core";
import Crossicon from "../../Assets/Images/Icon/cross.png";
import TickIcon from "../../Assets/Images/Icon/tick.png";

const Ism = ({answerGiven,question,index,ism}) => {
  const {name, zameerWahidJama, zameerJins,baab,baabName} = question;
  return (
    <Grid container direction="row-reverse">
    <Grid item xs={3} sm={3} md={3} lg={3}>
      <h5>سیکشن</h5>
      <h5>{ism ? "گردان" : "صیغہ"}</h5>
      <h5>عدد</h5>
      <h5>جنس</h5>
    </Grid>
    <Grid item xs={3} sm={3} md={3} lg={3}>
      <h5>آپ کا انتخاب</h5>
      <h5>{answerGiven[index].name}</h5>
      <h5>{answerGiven[index].zameerWahidJama}</h5>
      <h5>{answerGiven[index].zameerJins}</h5>
    </Grid>
    <Grid item xs={3} sm={3} md={3} lg={3}>
      <h5>درست جواب</h5>
      <h5>{name}</h5>
      <h5>{zameerWahidJama}</h5>
      <h5>{zameerJins}</h5>
    </Grid>
    <Grid item xs={3} sm={3} md={3} lg={3}>
      <h5>نتیجہ</h5>
      <h5>{answerGiven[index].name === name ? <img src={TickIcon} alt="icon"/> : <img src={Crossicon} alt="icon"/>}
      </h5>
      <h5>{answerGiven[index].zameerWahidJama === zameerWahidJama ? <img src={TickIcon} alt="icon"/> : <img src={Crossicon} alt="icon"/>}
      </h5>
      <h5>
       <img src={answerGiven[index].zameerJins.split(" ").join("") === zameerJins ? TickIcon : Crossicon} alt="icon" />
      </h5>
    </Grid>
  </Grid>
  )
}

export default Ism
