import React from "react";
import Layout from "../../Components/Layout/Layout";
import TopicAccording from "./TopicAccording";
import TopicCard from "./TopicCard";

const Topic = () => {
  return (
    <Layout mainClass="topicpage" title="التصریف">
      <TopicAccording title="ضرب">
        <TopicCard title="حلق" url="#" />
        <TopicCard title="حلق" url="#" />
        <TopicCard title="حلق" url="#" />
        <TopicCard title="حلق" url="#" />
        <TopicCard title="حلق" url="#" />
        <TopicCard title="حلق" url="#" />
      </TopicAccording>
    </Layout>
  );
};

export default Topic;
