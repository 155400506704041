import { addNewDoc, getDocsInCollection } from "../../Assets/firebase";
import {
  ADD_TO_CART,
  REMOVE_TO_CART,
  SWEET_ALERT_SHOW,
  SWEET_ALERT_Hide,
  IF_SELECT_ENGLISH,
  MUSHQ_AFAL_SALASI,
  IMTIHAN_AFAL_SALASI,
  ALL_EXAMS,
  isUserID,
} from "../constants";
import axios from "axios";

export const loginDisplayShow = (data) => {
  return {
    type: "Login_Display_Show",
    payload: data,
  };
};

export const addToCart = (data) => {
  return {
    type: ADD_TO_CART,
    payload: data,
  };
};
export const removeToCart = (data) => {
  return {
    type: REMOVE_TO_CART,
    payload: data,
  };
};

export const sweetAlertShow = (data) => {
  return {
    type: SWEET_ALERT_SHOW,
    payload: data,
  };
};
export const sweetAlertHide = (data) => {
  return {
    type: SWEET_ALERT_Hide,
    payload: data,
  };
};
export const ifLnaguageEnglish = (data) => {
  return {
    type: IF_SELECT_ENGLISH,
    payload: data,
  };
};

export const mushq_afal_slaasi = (data) => {
  return {
    type: MUSHQ_AFAL_SALASI,
    payload: data,
  };
};
export const imtihan_afal_slaasi = (data) => {
  return {
    type: IMTIHAN_AFAL_SALASI,
    payload: data,
  };
};

export const loginApi = (new_data) => async (dispatch) => {
  let config = { headers: { "Content-Type": "application/json" } };

  try {
    const { data } = await axios.post(
      // "https://altsreefbackend.herokuapp.com/api/auth/login",
      "http://localhost:5000/api/auth/login",
      new_data,
      config
    );

    if (!data.user && data.error) {
      console.log("action console not exist");
    } else {
      localStorage.setItem("token", data.token);
      dispatch({
        type: "LOGIN",
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: error.response.data.message,
    });
  }
};

export const registerData = (new_data) => async (dispatch) => {
  try {
    await axios
      // .post("https://altsreefbackend.herokuapp.com/api/auth/register", new_data)
      .post("http://localhost:5000/api/auth/register", new_data)
      .then((res) => {
        if (!res.data.user && res.data.error) {
          console.log("action console not exist");
        } else {
          localStorage.setItem("token", res.data.token);
          console.log("response ", res.data);
          return res.data;
        }
      });
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: error.response.data.message,
    });
  }
};

export const protectedGetConfig = (url, token) => {
  return {
    method: "POST",
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};
// this function will set exam data with user details into firebase database
export const TestData = (user, exam,callback) => async (dispatch) => {
  addNewDoc(
    "exam",
    {
      userName: user.displayName,
      email: user.email,
      photo: user.photoURL,
      id: user.uid,
      exam: exam,
    },
    (data) => {
      callback(data.id);
    }
  );
};
// this function use to set user id state
export const setUserID = (id) => async (dispatch) => {
  dispatch({
    type: isUserID,
    payload: id,
  });
}
// export const TestData = (token, exam) => async (dispatch) => {
//   // const api = "https://altsreefbackend.herokuapp.com/api/exam";
//   const api = "http://localhost:5000/api/exam";
//   console.log(exam);
//   const header = `Authorization: Bearer ${token}`;

//   await axios
//     .post(api, exam, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((res) => {
//       if (!res.data.user && res.data.error) {
//         console.log("action console not exist");
//       } else {
//         return res.data;
//       }
//     });
// };

export const getAllData = (auth) => (dispatch) => {
  console.log("checking getalldata in action cmp")
   getDocsInCollection("exam", "id", "==", auth, (data) => {
    console.log("checking data in action cmp")
    console.log(data)
    dispatch({
        type: ALL_EXAMS,
        payload: data,
      });
  });
  // dispatch({
  //   type: ALL_EXAMS,
  //   payload: data,
  // });
}; // ends getalldta function
// export const getAllData = () => async (dispatch) => {
//   const token = localStorage.getItem("token");

//   try {
//     const { data } = await axios.get(
//       // "https://altsreefbackend.herokuapp.com/api/exam",
//       "http://localhost:5000/api/exam",
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );

//     console.log("data ", data);

//     dispatch({
//       type: ALL_EXAMS,
//       payload: data,
//     });
//   } catch (error) {
//     console.log(error);
//   }

//   // .then(res =>
//   //   dispatch({
//   //     type: "GET_DATA",
//   //     payload: res.data
//   //   })
//   //   )
//   //   .catch (e =>
//   //     dispatch({
//   //       type: "ERROR",
//   //       payload: e
//   //     })
//   //     );
// };

export const errorClear = (clear) => (dispatch) => {
  console.log(clear, "clear");
  dispatch({
    type: "ERROR",
    payload: "",
  });
};
