import {
  urduTarjamaHelper,
  alaamatStarterPastEnglishAll,
  alaamatStarterMaziLazimPatternOne,
  alaamatStarterMaziMutadiAllPattern,
  alaamatMaziLazimPatternOne,
  alaamatMaziLazimPatternTwo,
  alaamatMaziMutadiPatternThree,
  alaamatMaziMutadiPatternOne,
  alaamatMaziMutadiPatternTwo,
  alaamatMuzaraPatternOne,
} from "../../Assets/DataArray";

const typesOfGardan = [
  "ماضی معروف",
  "ﻣﹹﻀﺎﺭﻉ معروف",
  "ماضی مجہول",
  "ﻣﹹﻀﺎﺭﻉ مجہول",
  "امر",
  "ﻣﹹﻀﺎﺭﻉ منصوب معروف",
  "ﻣﹹﻀﺎﺭﻉ منصوب مجہول",
];

const alif = "ا",
  fatha = "َ",
  jazam = "ْ",
  taa = "ت",
  kasra = "ِ",
  seen = "س",
  noon = "ن",
  shad = "ّ",
  wao = "و",
  Zamma = "ُ",
  KharaZabar = "ٰ";

const FailGardaanFirstSevenMaziMaroof = [];
const FailGardaanSecondSevenMaziMaroof = [];
const FailGardaanFirstSevenMuzariyaMaroof = [];
const FailGardaanSecondSevenMuzariyaMaroof = [];
const FailGardaanFirstSevenMaziMujhool = [];
const FailGardaanSecondSevenMaziMujhool = [];
const FailGardaanFirstSevenMuzariyaMujhool = [];
const FailGardaanSecondSevenMuzariyaMujhool = [];
const FailGardaanFirstSevenAmur = [];
const FailGardaanSecondSevenAmur = [];
const FailGardaanFirstSevenMuzariyaMunsoob = [];
const FailGardaanSecondSevenMuzariyaMunsoob = [];
const FailGardaanFirstSevenMuzariyaMunsoobMajhool = [];
const FailGardaanSecondSevenMuzariyaMunsoobMajhool = [];

const FailGardaanFirstSevenMaziMaroofFeeh = [];
const FailGardaanSecondSevenMaziMaroofFeeh = [];
const FailGardaanFirstSevenMuzariyaMaroofFeeh = [];
const FailGardaanSecondSevenMuzariyaMaroofFeeh = [];
const FailGardaanFirstSevenMaziMujhoolFeeh = [];
const FailGardaanSecondSevenMaziMujhoolFeeh = [];
const FailGardaanFirstSevenMuzariyaMujhoolFeeh = [];
const FailGardaanSecondSevenMuzariyaMujhoolFeeh = [];
const FailGardaanFirstSevenAmurFeeh = [];
const FailGardaanSecondSevenAmurFeeh = [];

const FailGardaanFirstSevenMaziMaroofTranslation = [];
const FailGardaanSecondSevenMaziMaroofTranslation = [];

const AlaamatMaazi = [
  "َ",
  "َا",
  "ُوْا",
  "َتْ",
  "َتَا",
  "ْنَ",
  "ْتَ",
  "ْتُمَا",
  "ْتُمْ",
  "ْتِ",
  "ْتُمَا",
  "ْتُنَّ",
  "ْتُ",
  "ْنَا",
];

const AlaamatMuzaraStart = [
  "یَ",
  "یَ",
  "یَ",
  "تَ",
  "تَ",
  "یَ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "اَ",
  "نَ",
];

const AlaamatMuzaraEnd = [
  "ُ",
  "َانِ",
  "ُوْنَ",
  "ُ",
  "َانِ",
  "ْنَ",
  "ُ",
  "َانِ",
  "ُوْنَ",
  "ِیْنَ",
  "َانِ",
  "ْنَ",
  "ُ",
  "ُ",
];

const AlaamatMuzaraMajhoolStart = [
  "یُ",
  "یُ",
  "یُ",
  "تُ",
  "تُ",
  "یُ",
  "تُ",
  "تُ",
  "تُ",
  "تُ",
  "تُ",
  "تُ",
  "اُ",
  "نُ",
];

const UrduZameerTite = [
  "واحد مذکر غائب",
  "تثنیہ مذکر غائب",
  "جمع مذکر غائب",
  "واحد مؤنث غائب",
  "تثنیہ مؤنث غائب",
  "جمع مؤنث غائب",
  "واحد مذکر حاضر",
  "تثنیہ مذکر حاضر",
  "جمع مذکر حاضر",
  "واحد مؤنث حاضر",
  "تثنیہ مؤنث حاضر",
  "جمع مؤنث حاضر",
  "واحد مذکر/مؤنث متکلم",
  "تثنیہ/جمع مذکر/مؤنث متکلم",
];

const UrduZameerTiteIsm = [
  "واحد مذکر",
  "تثنیہ مذکر",
  "جمع مذکر",
  "واحد مؤنث",
  "تثنیہ مؤنث",
  "جمع مؤنث",
];

const AlaamatMuzaraNawasibStart = [
  "یَُ",
  "یَّ",
  "یَّ",
  "تَ",
  "تَ",
  "یَّ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "تَ",
  "اَ",
  "نَّ",
];
const AlaamatMuzaraNawasibMajhoolStart = [
  "یُّ",
  "یُّ",
  "یُّ",
  "تُ",
  "تُ",
  "یُّ",
  "تُ",
  "تُ",
  "تُ",
  "تُ",
  "تُ",
  "تُ",
  "اُ",
  "نُّ",
];
const AlaamatMuzaraNawasibEnd = [
  "َ",
  "َا",
  "ُوْا",
  "َ",
  "َا",
  "ْنَ",
  "َ",
  "َا",
  "ُوْا",
  "ِیْ",
  "َا",
  "ْنَ",
  "َ",
  "َ",
];

const AlaamatAmar = ["ْ", "َا", "ُوْا", "ِیْ", "َا", "ْنَ"];
const AmarUrduZameerTite = [
  "واحد مذکر حاضر",
  "تثنیہ مذکر حاضر",
  "جمع مذکر حاضر",
  "واحد مؤنث حاضر",
  "تثنیہ مؤنث حاضر",
  "جمع مؤنث حاضر",
];

const baabZarabarootSahihForTranslation = {
  baabName: "ضرب",
  data: ["جلس", "حبس", "حلق", "حمل", "غسل", "رجع", "صبر", "خرق", "ضرب"],
};

const baabZarabarootSahih = {
  baabName: "ضرب",
  data: [
    "جلس",
    "حبس",
    "حلق",
    "حمل",
    "خرق",
    "رجع",
    "سرق",
    "صبر",
    "صرف",
    "ضرب",
    "عدل",
    "عرف",
    "عصر",
    "غزل",
    "غسل",
    "غفر",
    "غلب",
    "قصد",
    "کذب",
    "کسب",
    "کسر",
    "کشف",
    "نزل",
    "ہلک",
  ],
};
const baabNasararootSahihForTranslation = {
  baabName: "ضرب",
  data: [
    "بلغ",
    "ترک",
    "خرج",
    "خلد",
    "خلط",
    "دخل",
    "سقط",
    "سکت",
    "طلب",
    "قعد",
    "نصر",
  ],
};
const baabNasararootSahih = {
  baabName: "نصر",
  data: [
    "بلغ",
    "ترک",
    "حرث",
    "خرج",
    "خلد",
    "خلط",
    "دخل",
    "دلک",
    "رقد",
    "رکض",
    "ستر",
    "سقط",
    "سکت",
    "طلب",
    "غرز",
    "فتل",
    "قعد",
    "لمس",
    "مطر",
    "مکث",
    "نسج",
    "نشر",
    "نصر",
    "نفخ",
    "نقص",
    "نقض",
    "ھرب",
  ],
};
const baabFataharootSahih = {
  baabName: "فتح",
  data: [
    "جحد",
    "جرح",
    "جعل",
    "خلع",
    "خدع",
    "دفع",
    "ذھب",
    "رفع",
    "زرع",
    "سبح",
    "سحق",
    "صرخ",
    "طبخ",
    "طحن",
    "فتح",
    "قطع ",
    "قلع",
    "مدح",
    "مضع",
    "منع",
    "نھض",
  ],
};
const baabKarumarootSahih = {
  baabName: "کرم",
  data: ["بصر", "بعد", "شرف", "صعب", "عظم", "قرب", "کثر", "کرم "],
};
const baabSamiarootSahih = {
  baabName: "سمع",
  data: [
    "جہل",
    "حفظ",
    "حمد",
    "رکب",
    "سعد",
    "سمع",
    "شرب",
    "شہد",
    "ضحک",
    "عطش",
    "علم",
    "فہم",
    "قدم",
    "لبث",
    "لبس",
    "لحق",
    "لعق",
  ],
};
const baabHasibarootSahih = { baabName: "حسب", data: ["حسب", "نعم"] };

// "افعال", "تفعیل", "مفاعلہ", "تفعل", "تفاعل", "افتعال", "انفعال", "استفعال";

const baabIffaalSahih = {
  baabName: "افعال",
  data: [
    "بدل",
    "برز",
    "برم",
    "بسل",
    "بشر",
    "بصر",
    "بطل",
    "بعد",
    "بلس",
    "بلع",
    "بلغ",
    "بهل",
    "بهم",
    "تبع",
    "تحف",
    "ترف",
    "تسق",
    "تعب",
    "تقن",
    "ثبت",
    "ثبط",
    "ثخن",
    "ثقل",
    "ثمر",
    "جرم",
    "جلب",
    "جلس",
    "جمع",
    "جمل",
    "حبط",
    "حدث",
    "حذف",
    "حرق",
    "حرم",
    "حسب",
    "حسن",
    "حصر",
    "حصن",
    "حضر",
    "حكم",
    "حلف",
    "خبت",
    "خبر",
    "خرب",
    "خرج",
    "خرس",
    "خسر",
    "خصب",
    "خلد",
    "خلص",
    "خلف",
    "خمر",
    "دبر",
    "دحض",
    "دخل",
    "درك",
    "دغم",
    "دهق",
    "دهن",
    "ذعن",
    "ذنب",
    "ذهب",
    "رجف",
    "رجه",
    "ردف",
    "رسل",
    "رشد",
    "رصد",
    "رضع",
    "ركس",
    "رهب",
    "رهق",
    "زلف",
    "زلق",
    "سبغ",
    "سحت",
    "سخط",
    "سرج",
    "سرع",
    "سرف",
    "سفر",
    "سقط",
    "سكر",
    "سكن",
    "سلف",
    "سلم",
    "سمع",
    "سمن",
    "سند",
    "سهل",
    "شبه",
    "شرب",
    "شرف",
    "شرق",
    "شرك",
    "شعر",
    "شفق",
    "شكل",
    "شمت",
    "شهد",
    "صبح",
    "صحب",
    "صدر",
    "صرخ",
    "صرف",
    "صعد",
    "صلح",
    "ضحك",
    "ضرب",
    "ضعف",
    "ضمر",
    "طبق",
    "طعم",
    "طفأ",
    "طلع",
    "طلق",
    "ظلم",
    "ظهر",
    "عتب",
    "عتد",
    "عتل",
    "عثر",
    "عجب",
    "عجز",
    "عجل",
    "عجم",
    "عرب",
    "عرض",
    "عرف",
    "عصر",
    "عظم",
    "عقب",
    "علم",
    "علن",
    "عمل",
    "عنت",
    "غرق",
    "غرم",
    "غضب",
    "غطش",
    "غفل",
    "غلق",
    "غمض",
    "فرد",
    "فرط",
    "فرغ",
    "فسد",
    "فطر",
    "فلح",
    "قبر",
    "قبل",
    "قتر",
    "قرب",
    "قرض",
    "قسط",
    "قسم",
    "قصر",
    "قلع",
    "قمح",
    "قنع",
    "كبر",
    "كثر",
    "كرم",
    "كره",
    "كشف",
    "كفل",
    "كمل",
    "لبس",
    "لحد",
    "لحف",
    "لحق",
    "لزم",
    "لهب",
    "لهم",
    "متع",
    "مسك",
    "مطر",
    "مكن",
    "ملق",
    "مهل",
    "نبأ",
    "نبت",
    "نتن",
    "نذر",
    "نزف",
    "نزل",
    "نسب",
    "نشد",
    "نشر",
    "نشز",
    "نصت",
    "نصف",
    "نظر",
    "نعم",
    "نغض",
    "نفق",
    "نقذ",
    "نقض",
    "نكح",
    "نكر",
    "هرع",
    "هطع",
    "هلك",
    "همل",
  ],
};
const baabTafeelSahih = {
  baabName: "تفعیل",
  data: [
    "بتك",
    "بتل",
    "بدل",
    "بذر",
    "برز",
    "بشر",
    "بصر",
    "بعد",
    "بعض",
    "بكر",
    "بلغ",
    "تبر",
    "ثبت",
    "ثبط",
    "ثرب",
    "ثلث",
    "ثلج",
    "جرب",
    "جرد",
    "جلد",
    "جمع",
    "جنب",
    "جند",
    "جهز",
    "حدث",
    "حذر",
    "حرب",
    "حرص",
    "حرض",
    "حرف",
    "حرق",
    "حرك",
    "حرم",
    "حصل",
    "حصن",
    "حضر",
    "حفظ",
    "حقر",
    "حكم",
    "حمد",
    "حمل",
    "خرب",
    "خسر",
    "خلد",
    "خلف",
    "خلق",
    "خمر",
    "دبر",
    "دخن",
    "درب",
    "درس",
    "دمر",
    "ذبح",
    "ذكر",
    "رتب",
    "رتل",
    "رحب",
    "رسل",
    "رشح",
    "رغب",
    "ركب",
    "زمل",
    "سبح",
    "سجر",
    "سجل",
    "سحر",
    "سخر",
    "سرج",
    "سرح",
    "سطر",
    "سعر",
    "سكر",
    "سكن",
    "سلح",
    "سلط",
    "سلم",
    "سمر",
    "سند",
    "سهل",
    "شبه",
    "شرد",
    "شرع",
    "شرف",
    "شغل",
    "صبح",
    "صدر",
    "صدع",
    "صدق",
    "صرف",
    "صعر",
    "صغر",
    "صلب",
    "ضعف",
    "ضمن",
    "طبق",
    "طعم",
    "طلق",
    "طهر",
    "عبد",
    "عبر",
    "عجل",
    "عدل",
    "عذب",
    "عذر",
    "عرض",
    "عرف",
    "عزر",
    "عطل",
    "عظم",
    "عفر",
    "عقب",
    "عقد",
    "علق",
    "علم",
    "عمر",
    "عمق",
    "غلب",
    "غلق",
    "غمض",
    "فتح",
    "فتش",
    "فجر",
    "فخم",
    "فرج",
    "فرح",
    "فرض",
    "فرط",
    "فرغ",
    "فرق",
    "فزع",
    "فسر",
    "فصل",
    "فضل",
    "فقه",
    "فكر",
    "فكه",
    "فند",
    "فهم",
    "قبل",
    "قتل",
    "قدر",
    "قدس",
    "قدم",
    "قرب",
    "قرن",
    "قسط",
    "قشر",
    "قصر",
    "قطع",
    "قلب",
    "قلد",
    "قنع",
    "كبر",
    "كثر",
    "كذب",
    "كرم",
    "كره",
    "كسر",
    "كفر",
    "كفل",
    "كلب",
    "كلف",
    "كلم",
    "لبس",
    "لخص",
    "متع",
    "مثل",
    "محص",
    "مدن",
    "مرد",
    "مرض",
    "مزق",
    "مسك",
    "مكن",
    "مهد",
    "مهل",
    "نبت",
    "نبه",
    "ندم",
    "نزح",
    "نزل",
    "نشر",
    "نظف",
    "نظم",
    "نعم",
    "نغم",
    "نفذ",
    "نفس",
    "نكر",
    "نكس",
    "نكل",
    "هدم",
    "هذب",
  ],
};
const baabtMufaalaSahih = {
  baabName: "مفاعلہ",
  data: [
    "بدر",
    "برز",
    "برك",
    "بشر",
    "بعد",
    "بلغ",
    "بهل",
    "ثبر",
    "جدل",
    "جلس",
    "جنب",
    "جهد",
    "جهر",
    "حرب",
    "حسب",
    "حضر",
    "حفظ",
    "خدع",
    "خصر",
    "خطب",
    "خفت",
    "خلط",
    "خلف",
    "خمر",
    "دفع",
    "ربط",
    "رجع",
    "رسل",
    "رغم",
    "رفق",
    "رقب",
    "رهق",
    "زرع",
    "سبق",
    "سرع",
    "سعد",
    "سفح",
    "سفر",
    "سقط",
    "سلم",
    "سمح",
    "سمر",
    "شبه",
    "شرك",
    "شفه",
    "شهد",
    "صبر",
    "صحب",
    "صدف",
    "صدق",
    "صفح",
    "صلح",
    "ضرع",
    "ضعف",
    "طبق",
    "ظهر",
    "عتب",
    "عجز",
    "عدل",
    "عشر",
    "عقب",
    "علج",
    "عمل",
    "عنق",
    "عهد",
    "غدر",
    "غضب",
    "فخر",
    "فرق",
    "فصل",
    "قبل",
    "قتل",
    "قرب",
    "قرن",
    "قسم",
    "قطع",
    "كتب",
    "كثر",
    "لحظ",
    "لمس",
    "مرس",
    "مزج",
    "مطل",
    "نزع",
    "نسب",
    "نفس",
    "نفق",
    "نقش",
    "هجر",
  ],
};
const baabTafahulSahih = {
  baabName: "تفعل",
  data: [
    "بتل",
    "بخر",
    "بدل",
    "برج",
    "برد",
    "برع",
    "بسم",
    "جرع",
    "جمع",
    "جنب",
    "جند",
    "حجب",
    "حدث",
    "حرف",
    "حرك",
    "حصن",
    "حمس",
    "حنث",
    "خبت",
    "خرج",
    "خطف",
    "خلف",
    "خمر",
    "دبر",
    "دثر",
    "دفق",
    "ذكر",
    "ربص",
    "رقب",
    "زمل",
    "سلط",
    "سلق",
    "سلم",
    "سنه",
    "شجع",
    "شرف",
    "شعب",
    "صدر",
    "صدع",
    "صدق",
    "صرف",
    "صعد",
    "صلح",
    "ضجر",
    "ضرع",
    "ضمن",
    "طرف",
    "طلع",
    "طهر",
    "عجب",
    "عجل",
    "عرض",
    "علق",
    "علم",
    "عمد",
    "فجر",
    "فحص",
    "فرق",
    "فسح",
    "فضل",
    "فطر",
    "فقد",
    "فقه",
    "فكر",
    "فكه",
    "قبل",
    "قدم",
    "قرش",
    "قطع",
    "قعر",
    "قلب",
    "كبر",
    "كسر",
    "كلم",
    "لبث",
    "لطف",
    "متع",
    "مثل",
    "مرد",
    "مكن",
    "مهل",
    "نبه",
    "نزل",
    "نزه",
    "نضد",
    "نفس",
    "نكس",
    "هجد",
  ],
};
const baabTafaulSahih = {
  baabName: "تفعیل",
  data: [
    "برك",
    "تبع",
    "ثقل",
    "جنف",
    "حكم",
    "خصم",
    "خفت",
    "دخل",
    "درس",
    "درك",
    "ذحر",
    "رجع",
    "ردف",
    "رسل",
    "ركب",
    "ركم",
    "سأل",
    "شأم",
    "شبه",
    "شجر",
    "شكس",
    "صفح",
    "ضرب",
    "ظلم",
    "ظهر",
    "عرف",
    "عسر",
    "غبن",
    "غمز",
    "فأل",
    "فخر",
    "قبل",
    "قسم",
    "قعد",
    "كثر",
    "كسل",
    "مرض",
    "نبز",
    "نزع",
    "نفس",
  ],
};
const baabIftiaalSahih = {
  baabName: "افتعال",
  data: [
    "بدع",
    "بسم",
    "بهل",
    "تبع",
    "تجر",
    "تفق",
    "جمع",
    "جنب",
    "جهد",
    "حرق",
    "حرم",
    "حسب",
    "حفل",
    "حقر",
    "حمد",
    "حمل",
    "حنك",
    "خبر",
    "خرع",
    "خصر",
    "خصم",
    "خطب",
    "خلط",
    "خلف",
    "خمر",
    "درك",
    "ذخر",
    "ذكر",
    "رفع",
    "رفق",
    "رقب",
    "رمض",
    "زجر",
    "زحم",
    "زلف",
    "سبق",
    "ستر",
    "سطر",
    "سرق",
    "سطر",
    "سرق",
    "سلم",
    "سمر",
    "سمع",
    "سند",
    "شبه",
    "شرط",
    "شرك",
    "شعل",
    "شغل",
    "شمل",
    "شهر",
    "صبر",
    "صلح",
    "صنع",
    "ضجع",
    "ضرب",
    "طلع",
    "ظلم",
    "عبر",
    "عدل",
    "عذر",
    "عرض",
    "عرف",
    "عزل",
    "عصر",
    "عصم",
    "عقد",
    "عقل",
    "عكر",
    "عكف",
    "عمر",
    "غرف",
    "غسل",
    "غنم",
    "فتح",
    "فخر",
    "فرق",
    "فقر",
    "قبس",
    "قبل",
    "قتل",
    "قحم",
    "قدر",
    "قرب",
    "قرح",
    "قرض",
    "قرف",
    "قرن",
    "قرح",
    "قصد",
    "قلع",
    "كتب",
    "كسب",
    "لبس",
    "لحد",
    "لحق",
    "لزم",
    "لفت",
    "لقط",
    "لقم",
    "لمس",
    "مثل",
    "محن",
    "مزج",
    "منع",
    "مهن",
    "نبذ",
    "نبه",
    "نثر",
    "نحر",
    "نخب",
    "نزم",
    "نسب",
    "نشر",
    "نصر",
    "نصف",
    "نظر",
    "نفد",
    "نفع",
    "نفق",
    "نقل",
    "نقم",
    "نهز",
  ],
};
const baabInfiaalSahih = {
  baabName: "انفعال",
  data: [
    "بجس",
    "بعث",
    "بهم",
    "تبه",
    "خلع",
    "خنق",
    "دمل",
    "سلخ",
    "شرح",
    "شغل",
    "صرف",
    "طلق",
    "عطف",
    "فتح",
    "فجر",
    "فصل",
    "فصم",
    "فطر",
    "فلق",
    "قسم",
    "قطع",
    "قلب",
    "كدر",
    "كسر",
    "كسف",
    "كشف",
    "هدم",
    "هزم",
    "همر",
  ],
};
const baabIstifaalSahih = {
  baabName: "استفعال",
  data: [
    "بدل",
    "بشر",
    "بصر",
    "ثمر",
    "حسر",
    "حسن",
    "حفظ",
    "حقر",
    "خدم",
    "خرج",
    "خلص",
    "خلف",
    "خمر",
    "درج",
    "درك",
    "ذنب",
    "رجع",
    "رضع",
    "رهب",
    "سلم",
    "شهد",
    "صرخ",
    "ضعف",
    "طعم",
    "عبر",
    "عتب",
    "عجل",
    "عرب",
    "عصم",
    "عظم",
    "علم",
    "عمر",
    "عمِل",
    "غرِق",
    "غفر",
    "فتح",
    "فسر",
    "فهم",
    "قبِل",
    "قدم",
    "قرض",
    "قسم",
    "كبر",
    "كثر",
    "متع",
    "مسك",
    "نبط",
    "نثر",
    "نصر",
    "نفر",
    "نقذ",
    "نكح",
    "نكف",
    "هلك",
  ],
};

// isaam noun
const rootNounList = {
  baabName: "اسم",
  data: [
    "تبع",
    "ثقف",
    "ثقل",
    "ثمر",
    "جبر",
    "جحم",
    "جدل",
    "جرم",
    "جسد",
    "جعل",
    "جنب",
    "جنح",
    "جند",
    "حبر",
    "حبل",
    "حجب",
    "حدث",
    "حذر",
    "حرب",
    "حرث",
    "حرج",
    "حرم",
    "حزب",
    "حسب",
    "حسن",
    "حشر",
    "حصر",
    "حصن",
    "حضر",
    "حطب",
    "حفظ",
    "حكم",
    "حمد",
    "حمل",
    "خبت",
    "ختم",
    "خرج",
    "خرر",
    "خزن",
    "خسر",
    "خشع",
    "خطف",
    "خلف",
    "خلق",
    "درك",
    "ذكر",
    "ذکر",
    "ذهب",
    "رجز",
    "رجع",
    "رجم",
    "رحم",
    "رزق",
    "رسل",
    "رعد",
    "رفع",
    "رفق",
    "رقب",
    "ركب",
    "ركع",
    "زبر",
    "سبق",
    "سبل",
    "سجد",
    "سحر",
    "سخر",
    "سرج",
    "سرع",
    "سرف",
    "سفر",
    "سفل",
    "سقط",
    "سكر",
    "سكن",
    "سلف",
    "سلك",
    "سلم",
    "سمع",
    "شرب",
    "شطن",
    "شعر",
    "شفع",
    "شكر",
    "شهد",
    "صبر",
    "صدع",
    "صدق",
    "صرط",
    "صرف",
    "صعق",
    "صغر",
    "صلح",
    "ضحك",
    "ضرب",
    "ضعف",
    "طرف",
    "طرق",
    "طعم",
    "طمن",
    "طهر",
    "ظلم",
    "ظہر",
    "عبد",
    "عجب",
    "عجز",
    "عدل",
    "عذب",
    "عرش",
    "عرض",
    "عرف",
    "عزم",
    "عصر",
    "عضل",
    "عظم",
    "عقب",
    "علم",
    "غضب",
    "غفر",
    "غلب",
    "غلم",
    "فتح",
    "فتن",
    "فجر",
    "فحش",
    "فرح",
    "فرش",
    "فرق",
    "فسد",
    "فسق",
    "فصل",
    "فضل",
    "فعل",
    "فلح",
    "قتل",
    "قدر",
    "قدم",
    "قذف",
    "قرب",
    "قرع",
    "قرن",
    "قسط",
    "قسم",
    "قصر",
    "قطع",
    "قعد",
    "كبر",
    "كتب",
    "كذب",
    "كرم",
    "كسب",
    "كفر",
    "کتب",
    "لبس",
    "لعن",
    "مطر",
    "مكث",
    "نبت",
    "نذر",
    "نزع",
    "نزل",
    "نشر",
    "نصب",
    "نصر",
    "نظر",
    "نعم",
    "نفس",
    "نفق",
    "نفق",
    "نقب",
    "نكح",
    "نهر",
    "هجر",
    "هلك",
  ],
};

const topicCardContent = [
  baabZarabarootSahih,
  baabNasararootSahih,
  baabFataharootSahih,
  baabSamiarootSahih,
  baabHasibarootSahih,
  baabKarumarootSahih,
  baabIffaalSahih,
  baabTafeelSahih,
  baabtMufaalaSahih,
  baabTafahulSahih,
  baabTafaulSahih,
  baabIftiaalSahih,
  baabInfiaalSahih,
  baabIstifaalSahih,
  rootNounList,
];

const allAbwaabs = topicCardContent.map((item) => item);
const newMaziMaroof = [];
const newMuzariyaMaroof = [];
const newMaziMujhool = [];
const newMuzariyaMujhool = [];
const newAmur = [];
const newMuzariyaMunsoob = [];
const newMuzariyaMunsoobMajhool = [];

const newMaziMaroofFeeh = [];
const newMuzariyaMaroofFeeh = [];
const newMaziMujhoolFeeh = [];
const newMuzariyaMujhoolFeeh = [];
const newAmurFeeh = [];

const newMaziMaroofTranslation = [];

////////////////////////////////// Salus-mujarid-Questions //////////////////
const ConvertBaseFailIntoGardan = () => {
  var gardaanStarter = "";
  typesOfGardan.map((root) => {
    if (root === "ماضی معروف") {
      allAbwaabs.map((baabIs) => {
        if (
          baabIs.baabName === "فتح" ||
          baabIs.baabName === "نصر" ||
          baabIs.baabName === "ضرب"
        )
          newMaziMaroof.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            )
          );
        else if ((baabIs.baabName === "سمع") | (baabIs.baabName === "حسب"))
          newMaziMaroof.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            )
          );
        else if (baabIs.baabName === "کرم")
          newMaziMaroof.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  Zamma +
                  hurf.charAt(2))
            )
          );
        // else if (baabIs.baabName === "تفعیل")
        //   newMaziMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           shad +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "تفعل")
        //   newMaziMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           shad +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "افعال")
        //   newMaziMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           fatha +
        //           hurf.charAt(0) +
        //           jazam +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "تفاعل")
        //   newMaziMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           fatha +
        //           alif +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "مفاعلہ")
        //   newMaziMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           fatha +
        //           alif +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "افتعال")
        //   newMaziMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           kasra +
        //           hurf.charAt(0) +
        //           jazam +
        //           taa +
        //           fatha +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "انفعال")
        //   newMaziMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           kasra +
        //           noon +
        //           jazam +
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "استفعال")
        //   newMaziMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           kasra +
        //           seen +
        //           jazam +
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           jazam +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
      });
      let gardaanMaziMaroof = [].concat.apply([], newMaziMaroof);
      for (let i = 0; i < gardaanMaziMaroof.length; i++) {
        for (let index = 0; index < AlaamatMaazi.length; index++) {
          if (index < 7) {
            FailGardaanFirstSevenMaziMaroof.push({
              text: gardaanMaziMaroof[i] + AlaamatMaazi[index],
              zameer: UrduZameerTite[index],
            });
            // });
          } else {
            FailGardaanSecondSevenMaziMaroof.push({
              text: gardaanMaziMaroof[i] + AlaamatMaazi[index],
              zameer: UrduZameerTite[index],
            });
          }
        }
      }
    } else if (root === "ﻣﹹﻀﺎﺭﻉ معروف") {
      allAbwaabs.map((baabIs) => {
        if (baabIs.baabName === "فتح" || baabIs.baabName === "سمع")
          newMuzariyaMaroof.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            )
          );
        else if (baabIs.baabName === "ضرب" || baabIs.baabName === "حسب")
          newMuzariyaMaroof.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            )
          );
        else if (baabIs.baabName === "کرم" || baabIs.baabName === "نصر")
          newMuzariyaMaroof.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  Zamma +
                  hurf.charAt(2))
            )
          );
        // else if (baabIs.baabName === "تفعیل")
        //   newMuzariyaMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           shad +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "تفعل")
        //   newMuzariyaMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           shad +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "افعال")
        //   newMuzariyaMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           jazam +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "تفاعل")
        //   newMuzariyaMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           fatha +
        //           alif +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "مفاعلہ")
        //   newMuzariyaMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           fatha +
        //           alif +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "انفعال")
        //   newMuzariyaMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           noon +
        //           jazam +
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "استفعال")
        //   newMuzariyaMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           seen +
        //           jazam +
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           jazam +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "افتعال")
        //   newMuzariyaMaroof.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           jazam +
        //           taa +
        //           fatha +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        let gardaanMuzariyaMaroof = [].concat.apply([], newMuzariyaMaroof);
        for (let i = 0; i < gardaanMuzariyaMaroof.length; i++) {
          for (let index = 0; index < AlaamatMaazi.length; index++) {
            if (index < 7) {
              FailGardaanFirstSevenMuzariyaMaroof.push({
                text:
                  AlaamatMuzaraStart[index] +
                  gardaanMuzariyaMaroof[i] +
                  AlaamatMuzaraEnd[index],
                zameer: UrduZameerTite[index],
              });
            } else {
              FailGardaanSecondSevenMuzariyaMaroof.push({
                text:
                  AlaamatMuzaraStart[index] +
                  gardaanMuzariyaMaroof[i] +
                  AlaamatMuzaraEnd[index],
                zameer: UrduZameerTite[index],
              });
            }
          }
        }
      });
    } else if (root === "ماضی مجہول") {
      allAbwaabs.map((baabIs) => {
        if (
          baabIs.baabName === "فتح" ||
          baabIs.baabName === "نصر" ||
          baabIs.baabName === "ضرب" ||
          baabIs.baabName === "سمع" ||
          baabIs.baabName === "حسب" ||
          baabIs.baabName === "کرم"
        )
          newMaziMujhool.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  Zamma +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            )
          );
        // else if (baabIs.baabName === "تفعیل")
        //   newMaziMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           Zamma +
        //           hurf.charAt(1) +
        //           shad +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "تفعل")
        //   newMaziMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           taa +
        //           Zamma +
        //           hurf.charAt(0) +
        //           Zamma +
        //           hurf.charAt(1) +
        //           shad +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "افعال")
        //   newMaziMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           Zamma +
        //           hurf.charAt(0) +
        //           jazam +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "تفاعل")
        //   newMaziMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           taa +
        //           Zamma +
        //           hurf.charAt(0) +
        //           Zamma +
        //           wao +
        //           jazam +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "مفاعلہ")
        //   newMaziMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           Zamma +
        //           wao +
        //           jazam +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "انفعال")
        //   newMaziMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           Zamma +
        //           noon +
        //           jazam +
        //           hurf.charAt(0) +
        //           Zamma +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "استفعال")
        //   newMaziMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           Zamma +
        //           seen +
        //           jazam +
        //           taa +
        //           Zamma +
        //           hurf.charAt(0) +
        //           jazam +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "افتعال")
        //   newMaziMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           Zamma +
        //           hurf.charAt(0) +
        //           jazam +
        //           taa +
        //           Zamma +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );

        let gardaanMaziMujhool = [].concat.apply([], newMaziMujhool);
        for (let i = 0; i < gardaanMaziMujhool.length; i++) {
          for (let index = 0; index < AlaamatMaazi.length; index++) {
            if (index < 7) {
              FailGardaanFirstSevenMaziMujhool.push({
                text: gardaanMaziMujhool[i] + AlaamatMaazi[index],
                zameer: UrduZameerTite[index],
              });
              // });
            } else {
              FailGardaanSecondSevenMaziMujhool.push({
                text: gardaanMaziMujhool[i] + AlaamatMaazi[index],
                zameer: UrduZameerTite[index],
              });
            }
          }
        }
      });
    } else if (root === "ﻣﹹﻀﺎﺭﻉ مجہول") {
      allAbwaabs.map((baabIs) => {
        if (
          baabIs.baabName === "فتح" ||
          baabIs.baabName === "سمع" ||
          baabIs.baabName === "ضرب" ||
          baabIs.baabName === "حسب" ||
          baabIs.baabName === "کرم" ||
          baabIs.baabName === "نصر"
        )
          newMuzariyaMujhool.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            )
          );
        // else if (baabIs.baabName === "تفعیل")
        //   newMuzariyaMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           shad +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "تفعل")
        //   newMuzariyaMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           shad +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "افعال")
        //   newMuzariyaMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           jazam +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "تفاعل")
        //   newMuzariyaMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           fatha +
        //           alif +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "مفاعلہ")
        //   newMuzariyaMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           fatha +
        //           alif +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "انفعال")
        //   newMuzariyaMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           noon +
        //           jazam +
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "استفعال")
        //   newMuzariyaMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           seen +
        //           jazam +
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           jazam +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "افتعال")
        //   newMuzariyaMujhool.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           jazam +
        //           taa +
        //           fatha +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        let gardaanMuzariyaMujhool = [].concat.apply([], newMuzariyaMujhool);
        for (let i = 0; i < gardaanMuzariyaMujhool.length; i++) {
          for (
            let index = 0;
            index < AlaamatMuzaraMajhoolStart.length;
            index++
          ) {
            if (index < 7) {
              FailGardaanFirstSevenMuzariyaMujhool.push({
                text:
                  AlaamatMuzaraMajhoolStart[index] +
                  gardaanMuzariyaMujhool[i] +
                  AlaamatMuzaraEnd[index],
                zameer: UrduZameerTite[index],
              });
            } else {
              FailGardaanSecondSevenMuzariyaMujhool.push({
                text:
                  AlaamatMuzaraMajhoolStart[index] +
                  gardaanMuzariyaMujhool[i] +
                  AlaamatMuzaraEnd[index],
                zameer: UrduZameerTite[index],
              });
            }
          }
        }
      });
    } else if (root === "امر") {
      allAbwaabs.map((baabIs) => {
        if (baabIs.baabName === "فتح" || baabIs.baabName === "سمع")
          newAmur.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  kasra +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            )
          );
        else if (baabIs.baabName === "ضرب" || baabIs.baabName === "حسب")
          newAmur.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  kasra +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            )
          );
        else if (baabIs.baabName === "کرم" || baabIs.baabName === "نصر")
          newAmur.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  Zamma +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  Zamma +
                  hurf.charAt(2))
            )
          );
        else if (baabIs.baabName === "افعال")
          newAmur.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  fatha +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            )
          );
        // else if (baabIs.baabName === "تفعیل")
        //   newAmur.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           shad +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "مفاعلہ")
        //   newAmur.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           hurf.charAt(0) +
        //           fatha +
        //           alif +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "تفعل")
        //   newAmur.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           shad +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "تفاعل")
        //   newAmur.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           fatha +
        //           alif +
        //           hurf.charAt(1) +
        //           fatha +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "افتعال")
        //   newAmur.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           kasra +
        //           hurf.charAt(0) +
        //           jazam +
        //           taa +
        //           fatha +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "انفعال")
        //   newAmur.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           kasra +
        //           noon +
        //           jazam +
        //           hurf.charAt(0) +
        //           fatha +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        // else if (baabIs.baabName === "استفعال")
        //   newAmur.push(
        //     baabIs.data.map(
        //       (hurf) =>
        //         (gardaanStarter =
        //           alif +
        //           kasra +
        //           seen +
        //           jazam +
        //           taa +
        //           fatha +
        //           hurf.charAt(0) +
        //           jazam +
        //           hurf.charAt(1) +
        //           kasra +
        //           hurf.charAt(2))
        //     )
        //   );
        let gardaanAmur = [].concat.apply([], newAmur);
        for (let i = 0; i < gardaanAmur.length; i++) {
          for (let index = 0; index < AlaamatAmar.length; ++index) {
            if (index < 3) {
              FailGardaanFirstSevenAmur.push({
                text: gardaanAmur[i] + AlaamatAmar[index],
                zameer: AmarUrduZameerTite[index],
              });
            } else {
              FailGardaanSecondSevenAmur.push({
                text: gardaanAmur[i] + AlaamatAmar[index],
                zameer: AmarUrduZameerTite[index],
              });
            }
          }
        }
      });
    } else if (root === "ﻣﹹﻀﺎﺭﻉ منصوب معروف") {
      allAbwaabs.map((baabIs) => {
        if (baabIs.baabName === "فتح" || baabIs.baabName === "سمع")
          newMuzariyaMunsoob.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            )
          );
        else if (baabIs.baabName === "ضرب" || baabIs.baabName === "حسب")
          newMuzariyaMunsoob.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            )
          );
        else if (baabIs.baabName === "کرم" || baabIs.baabName === "نصر")
          newMuzariyaMunsoob.push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  Zamma +
                  hurf.charAt(2))
            )
          );
        let gardaanMuzariyaMunsoob = [].concat.apply([], newMuzariyaMunsoob);
        for (let i = 0; i < gardaanMuzariyaMunsoob.length; i++) {
          for (let index = 0; index < AlaamatMuzaraStart.length; ++index) {
            var gardanText =
              "لَنْ " +
              AlaamatMuzaraNawasibStart[index] +
              gardaanMuzariyaMunsoob[i] +
              AlaamatMuzaraNawasibEnd[index];
            if (index < 7) {
              FailGardaanFirstSevenMuzariyaMunsoob.push({
                text: gardanText,
                zameer: UrduZameerTite[index],
              });
            } else {
              FailGardaanSecondSevenMuzariyaMunsoob.push({
                text: gardanText,
                zameer: UrduZameerTite[index],
              });
            }
          }
        }
      });
    } else if (root === "ﻣﹹﻀﺎﺭﻉ منصوب مجہول") {
      allAbwaabs.map((baabIs) => {
        if (
          baabIs.baabName === "فتح" ||
          baabIs.baabName === "سمع" ||
          baabIs.baabName === "ضرب" ||
          baabIs.baabName === "حسب" ||
          baabIs.baabName === "کرم" ||
          baabIs.baabName === "نصر"
        )
          newMuzariyaMunsoobMajhool .push(
            baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            )
          );
        let gardaanMuzariyaMunsoobMajhool = [].concat.apply([], newMuzariyaMunsoobMajhool);
        for (let i = 0; i < gardaanMuzariyaMunsoobMajhool.length; i++) {
          for (let index = 0; index < AlaamatMuzaraStart.length; ++index) {
            var gardanText =
              "لَنْ " +
              AlaamatMuzaraNawasibMajhoolStart[index] +
              gardaanMuzariyaMunsoobMajhool[i] +
              AlaamatMuzaraNawasibEnd[index];
            if (index < 7) {
              FailGardaanFirstSevenMuzariyaMunsoobMajhool.push({
                text: gardanText,
                zameer: UrduZameerTite[index],
              });
            } else {
              FailGardaanSecondSevenMuzariyaMunsoobMajhool.push({
                text: gardanText,
                zameer: UrduZameerTite[index],
              });
            }
          }
        }
      });
    }
  });
  return [
    ...FailGardaanFirstSevenMaziMaroof,
    ...FailGardaanSecondSevenMaziMaroof,
    ...FailGardaanFirstSevenMuzariyaMaroof,
    ...FailGardaanSecondSevenMuzariyaMaroof,
    ...FailGardaanFirstSevenMaziMujhool,
    ...FailGardaanSecondSevenMaziMujhool,
    ...FailGardaanFirstSevenMuzariyaMujhool,
    ...FailGardaanSecondSevenMuzariyaMujhool,
    ...FailGardaanFirstSevenAmur,
    ...FailGardaanSecondSevenAmur,
    ...FailGardaanFirstSevenMuzariyaMunsoob,
    ...FailGardaanSecondSevenMuzariyaMunsoob,
    ...FailGardaanSecondSevenMuzariyaMunsoobMajhool,
  ];
};

////////////////////////////////// Salus-mujarid-feeh-Questions //////////////////

const ConvertBaseSalusMujaridFeehIntoGardan = () => {
  var gardaanStarter = "";
  typesOfGardan.map((root) => {
    if (root === "ماضی معروف") {
      allAbwaabs.map((baabIs) => {
        if (baabIs.baabName === "تفعیل")
          newMaziMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  shad +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفعل")
          newMaziMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  shad +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "افعال")
          newMaziMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  fatha +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفاعل")
          newMaziMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  fatha +
                  alif +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "مفاعلہ")
          newMaziMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  alif +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "افتعال")
          newMaziMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  kasra +
                  hurf.charAt(0) +
                  jazam +
                  taa +
                  fatha +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "انفعال")
          newMaziMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  kasra +
                  noon +
                  jazam +
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "استفعال")
          newMaziMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  kasra +
                  seen +
                  jazam +
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
      });
      let gardaanMaziMaroofFeeh = [].concat.apply([], newMaziMaroofFeeh);

      gardaanMaziMaroofFeeh.map((item, index) => {
        for (let i = 0; i < item.alfaaz.length; i++) {
          for (let index = 0; index < AlaamatMaazi.length; index++) {
            if (index < 7) {
              FailGardaanFirstSevenMaziMaroofFeeh.push({
                text: item.alfaaz[i] + AlaamatMaazi[index],
                zameer: UrduZameerTite[index],
                baabName: item.baabName,
                baab: root,
              });
              // });
            } else {
              FailGardaanSecondSevenMaziMaroofFeeh.push({
                text: item.alfaaz[i] + AlaamatMaazi[index],
                zameer: UrduZameerTite[index],
                baabName: item.baabName,
                baab: root,
              });
            }
          }
        }
      });
    } else if (root === "ﻣﹹﻀﺎﺭﻉ معروف") {
      allAbwaabs.map((baabIs) => {
        if (baabIs.baabName === "تفعیل")
          newMuzariyaMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  shad +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفعل")
          newMuzariyaMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  shad +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "افعال")
          newMuzariyaMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفاعل")
          newMuzariyaMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  fatha +
                  alif +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "مفاعلہ")
          newMuzariyaMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  alif +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "انفعال")
          newMuzariyaMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  noon +
                  jazam +
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "استفعال")
          newMuzariyaMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  seen +
                  jazam +
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "افتعال")
          newMuzariyaMaroofFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  taa +
                  fatha +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        let gardaanMuzariyaMaroofFeeh = [].concat.apply(
          [],
          newMuzariyaMaroofFeeh
        );

        gardaanMuzariyaMaroofFeeh.map((item) => {
          for (let i = 0; i < item.alfaaz.length; i++) {
            for (let index = 0; index < AlaamatMaazi.length; index++) {
              if (index < 7) {
                FailGardaanFirstSevenMuzariyaMaroofFeeh.push({
                  text:
                    AlaamatMuzaraStart[index] +
                    item.alfaaz[i] +
                    AlaamatMuzaraEnd[index],
                  zameer: UrduZameerTite[index],
                  baabName: item.baabName,
                  baab: root,
                });
              } else {
                FailGardaanSecondSevenMuzariyaMaroofFeeh.push({
                  text:
                    AlaamatMuzaraStart[index] +
                    item.alfaaz[i] +
                    AlaamatMuzaraEnd[index],
                  zameer: UrduZameerTite[index],
                  baabName: item.baabName,
                  baab: root,
                });
              }
            }
          }
        });
      });
    } else if (root === "ماضی مجہول") {
      allAbwaabs.map((baabIs) => {
        if (baabIs.baabName === "تفعیل")
          newMaziMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  Zamma +
                  hurf.charAt(1) +
                  shad +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفعل")
          newMaziMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  taa +
                  Zamma +
                  hurf.charAt(0) +
                  Zamma +
                  hurf.charAt(1) +
                  shad +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "افعال")
          newMaziMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  Zamma +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفاعل")
          newMaziMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  taa +
                  Zamma +
                  hurf.charAt(0) +
                  Zamma +
                  wao +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "مفاعلہ")
          newMaziMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  Zamma +
                  wao +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "انفعال")
          newMaziMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  Zamma +
                  noon +
                  jazam +
                  hurf.charAt(0) +
                  Zamma +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "استفعال")
          newMaziMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  Zamma +
                  seen +
                  jazam +
                  taa +
                  Zamma +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "افتعال")
          newMaziMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  Zamma +
                  hurf.charAt(0) +
                  jazam +
                  taa +
                  Zamma +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });

        let gardaanMaziMujhoolFeeh = [].concat.apply([], newMaziMujhoolFeeh);
        gardaanMaziMujhoolFeeh.map((item) => {
          for (let i = 0; i < item.alfaaz.length; i++) {
            for (let index = 0; index < AlaamatMaazi.length; index++) {
              if (index < 7) {
                FailGardaanFirstSevenMaziMujhoolFeeh.push({
                  text: item.alfaaz[i] + AlaamatMaazi[index],
                  zameer: UrduZameerTite[index],
                  baabName: item.baabName,
                  baab: root,
                });
                // });
              } else {
                FailGardaanSecondSevenMaziMujhoolFeeh.push({
                  text: item.alfaaz[i] + AlaamatMaazi[index],
                  zameer: UrduZameerTite[index],
                  baabName: item.baabName,
                  baab: root,
                });
              }
            }
          }
        });
      });
    } else if (root === "ﻣﹹﻀﺎﺭﻉ مجہول") {
      allAbwaabs.map((baabIs) => {
        if (baabIs.baabName === "تفعیل")
          newMuzariyaMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  shad +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفعل")
          newMuzariyaMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  shad +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "افعال")
          newMuzariyaMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفاعل")
          newMuzariyaMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  fatha +
                  alif +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "مفاعلہ")
          newMuzariyaMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  alif +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "انفعال")
          newMuzariyaMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  noon +
                  jazam +
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "استفعال")
          newMuzariyaMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  seen +
                  jazam +
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "افتعال")
          newMuzariyaMujhoolFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  jazam +
                  taa +
                  fatha +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        let gardaanMuzariyaMujhoolFeeh = [].concat.apply(
          [],
          newMuzariyaMujhoolFeeh
        );
        gardaanMuzariyaMujhoolFeeh.map((item) => {
          for (let i = 0; i < item.alfaaz.length; i++) {
            for (
              let index = 0;
              index < AlaamatMuzaraMajhoolStart.length;
              index++
            ) {
              if (index < 7) {
                FailGardaanFirstSevenMuzariyaMujhoolFeeh.push({
                  text:
                    AlaamatMuzaraMajhoolStart[index] +
                    item.alfaaz[i] +
                    AlaamatMuzaraEnd[index],
                  zameer: UrduZameerTite[index],
                  baabName: item.baabName,
                  baab: root,
                });
              } else {
                FailGardaanSecondSevenMuzariyaMujhoolFeeh.push({
                  text:
                    AlaamatMuzaraMajhoolStart[index] +
                    item.alfaaz[i] +
                    AlaamatMuzaraEnd[index],
                  zameer: UrduZameerTite[index],
                  baabName: item.baabName,
                  baab: root,
                });
              }
            }
          }
        });
      });
    } else if (root === "امر") {
      allAbwaabs.map((baabIs) => {
        if (baabIs.baabName === "افعال")
          newAmurFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  fatha +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفعیل")
          newAmurFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  shad +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "مفاعلہ")
          newAmurFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  alif +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفعل")
          newAmurFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  shad +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "تفاعل")
          newAmurFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  fatha +
                  alif +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "افتعال")
          newAmurFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  kasra +
                  hurf.charAt(0) +
                  jazam +
                  taa +
                  fatha +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "انفعال")
          newAmurFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  kasra +
                  noon +
                  jazam +
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        else if (baabIs.baabName === "استفعال")
          newAmurFeeh.push({
            alfaaz: baabIs.data.map(
              (hurf) =>
                (gardaanStarter =
                  alif +
                  kasra +
                  seen +
                  jazam +
                  taa +
                  fatha +
                  hurf.charAt(0) +
                  jazam +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2))
            ),
            baabName: baabIs.baabName,
          });
        let gardaanAmurFeeh = [].concat.apply([], newAmurFeeh);
        gardaanAmurFeeh.map((item) => {
          for (let i = 0; i < item.alfaaz.length; i++) {
            for (let index = 0; index < AlaamatAmar.length; ++index) {
              if (index < 3) {
                FailGardaanFirstSevenAmurFeeh.push({
                  text: item.alfaaz[i] + AlaamatAmar[index],
                  zameer: AmarUrduZameerTite[index],
                  baabName: item.baabName,
                  baab: root,
                });
              } else {
                FailGardaanSecondSevenAmurFeeh.push({
                  text: item.alfaaz[i] + AlaamatAmar[index],
                  zameer: AmarUrduZameerTite[index],
                  baabName: item.baabName,
                  baab: root,
                });
              }
            }
          }
        });
      });
    }
  });

  return [
    ...FailGardaanFirstSevenMaziMaroofFeeh,
    ...FailGardaanSecondSevenMaziMaroofFeeh,
    ...FailGardaanFirstSevenMuzariyaMaroofFeeh,
    ...FailGardaanSecondSevenMuzariyaMaroofFeeh,
    ...FailGardaanFirstSevenMaziMujhoolFeeh,
    ...FailGardaanSecondSevenMaziMujhoolFeeh,
    ...FailGardaanFirstSevenMuzariyaMujhoolFeeh,
    ...FailGardaanSecondSevenMuzariyaMujhoolFeeh,
    ...FailGardaanFirstSevenAmurFeeh,
    ...FailGardaanSecondSevenAmurFeeh,
  ];
};
const typesOfGardan1 = ["فاعل", "مفعول"];
const newFaiel = [];
const newMufool = [];
const completeGardanFaiel = [];
const completeGardanMufool = [];
const alaamatIsm = ["ٌ", "َانِ", "ُوْنَ", fatha + "ةٌ", "تَانِ", fatha + "اتٌ"];
const ConvertBaseNounIntoGardan = () => {
  var gardaanStarter = "";
  typesOfGardan1.map((root) => {
    if (root === "فاعل") {
      rootNounList.data.map((faelText) =>
        newFaiel.push(
          (gardaanStarter =
            faelText.charAt(0) +
            fatha +
            alif +
            faelText.charAt(1) +
            kasra +
            faelText.charAt(2))
        )
      );
    } else if (root === "مفعول") {
      rootNounList.data.map((faelText) =>
        newMufool.push(
          (gardaanStarter =
            "م" +
            fatha +
            faelText.charAt(0) +
            jazam +
            faelText.charAt(1) +
            Zamma +
            "و" +
            jazam +
            faelText.charAt(2))
        )
      );
    }
  });
  for (let i = 0; i < newFaiel.length; i++) {
    for (let index = 0; index < alaamatIsm.length; ++index) {
      completeGardanFaiel.push({
        text: newFaiel[i] + alaamatIsm[index],
        zameer: UrduZameerTiteIsm[index],
        name: "فاعل",
      });
    }
  }
  for (let i = 0; i < newMufool.length; i++) {
    for (let index = 0; index < alaamatIsm.length; ++index) {
      completeGardanMufool.push({
        text: newMufool[i] + alaamatIsm[index],
        zameer: UrduZameerTiteIsm[index],
        name: "مفعول",
      });
    }
  }
  return [...completeGardanFaiel, ...completeGardanMufool];
};
const forTranslations = () => {
  var gardaanStarter = "";
  typesOfGardan.map((root) => {
    if (root === "ماضی معروف") {
      allAbwaabs.map((baabIs) => {
        if (baabIs.baabName === "نصر")
          newMaziMaroofTranslation.push(
            baabIs.data.map((hurf) => {
              return {
                baabName: baabIs.baabName,
                word: hurf,
                gardaanStarter:
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2),
              };
            })
          );
        else if (baabIs.baabName === "ضرب")
          newMaziMaroofTranslation.push(
            baabIs.data.map((hurf) => {
              return {
                baabName: baabIs.baabName,
                word: hurf,
                gardaanStarter: (gardaanStarter =
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  fatha +
                  hurf.charAt(2)),
              };
            })
          );
        else if (baabIs.baabName === "سمع")
          newMaziMaroofTranslation.push(
            baabIs.data.map((hurf) => {
              return {
                baabName: baabIs.baabName,
                word: hurf,
                gardaanStarter:
                  hurf.charAt(0) +
                  fatha +
                  hurf.charAt(1) +
                  kasra +
                  hurf.charAt(2),
              };
            })
          );
      });
      let gardaanMaziMaroofTranslation = [].concat.apply(
        [],
        newMaziMaroofTranslation
      );
      for (let i = 0; i < gardaanMaziMaroofTranslation.length; i++) {
        for (let index = 0; index < AlaamatMaazi.length; index++) {
          if (index < 7) {
            FailGardaanFirstSevenMaziMaroofTranslation.push({
              text:
                gardaanMaziMaroofTranslation[i].gardaanStarter +
                AlaamatMaazi[index],
              zameer: UrduZameerTite[index],
              baab: gardaanMaziMaroofTranslation[i].baabName,
              word: gardaanMaziMaroofTranslation[i].word,
            });
            // });
          } else {
            FailGardaanSecondSevenMaziMaroofTranslation.push({
              text:
                gardaanMaziMaroofTranslation[i].gardaanStarter +
                AlaamatMaazi[index],
              zameer: UrduZameerTite[index],
              baab: gardaanMaziMaroofTranslation[i].baabName,
              word: gardaanMaziMaroofTranslation[i].word,
            });
          }
        }
      }
    }
  });
  return [
    ...FailGardaanFirstSevenMaziMaroofTranslation,
    ...FailGardaanSecondSevenMaziMaroofTranslation,
  ];
};
const allIsma = ConvertBaseNounIntoGardan();
const allSalusMujaridFeehWords = ConvertBaseSalusMujaridFeehIntoGardan();
const allTranslationsData = forTranslations();

const MaziEnglishGardaanList = [];
const MuzaraEnglishGardaanList = [];

const MaziUrduGardaanList = [];
const MuzaraUrduGardaanList = [];

const arrayFilter = [];

urduTarjamaHelper.map((item1) => {
  allTranslationsData.map((item) => {
    if (item.word == item1.title)
      arrayFilter.push({
        text: item.text,
        zameer: item.zameer,
        baab: item.baab,
        title: item1.title,
      });
  });
});

urduTarjamaHelper.map((item) => {
  const MaziGardaanCreatorEnglish = (masdar = item.data[9]) => {
    for (let i = 0; i < alaamatStarterPastEnglishAll.length; i++) {
      MaziEnglishGardaanList.push({
        title: item.title,
        translation: alaamatStarterPastEnglishAll[i] + masdar,
      });
    }
    return MaziEnglishGardaanList;
  };
  MaziGardaanCreatorEnglish();

  const MaziGardaanCreatorUrdu = (
    masdar = item.data[3],
    maziPattern = item.data[4]
  ) => {
    if (maziPattern == "MaziLazimPatternOne") {
      let starter = masdar.substring(0, masdar.length - 2);
      for (let i = 0; i < alaamatMaziLazimPatternOne.length; i++) {
        MaziUrduGardaanList.push({
          title: item.title,
          translation:
            alaamatStarterMaziLazimPatternOne[i] +
            starter +
            alaamatMaziLazimPatternOne[i],
        });
      }
    } else if (maziPattern == "MaziLazimPatternTwo") {
      let starter = masdar.substring(0, masdar.length - 2);
      for (let i = 0; i < alaamatMaziLazimPatternOne.length; i++) {
        MaziUrduGardaanList.push({
          title: item.title,
          translation:
            alaamatStarterMaziLazimPatternOne[i] +
            starter +
            alaamatMaziLazimPatternTwo[i],
        });
      }
    } else if (maziPattern == "MaziMutadiPatternOne") {
      let starter = masdar.substring(0, masdar.length - 2);
      for (let i = 0; i < alaamatMaziMutadiPatternOne.length; i++) {
        MaziUrduGardaanList.push({
          title: item.title,
          translation:
            alaamatStarterMaziMutadiAllPattern[i] +
            starter +
            alaamatMaziMutadiPatternOne[i],
        });
      }
      return MaziUrduGardaanList;
    } else if (maziPattern == "MaziMutadiPatternTwo") {
      let starter = masdar.substring(0, masdar.length - 4);
      for (let i = 0; i < alaamatMaziMutadiPatternTwo.length; i++) {
        MaziUrduGardaanList.push({
          title: item.title,
          translation:
            alaamatStarterMaziMutadiAllPattern[i] +
            starter +
            alaamatMaziMutadiPatternTwo[i],
        });
      }
      return MaziUrduGardaanList;
    } else if (maziPattern == "MaziMutadiPatternThree") {
      let starter = masdar.substring(0, masdar.length - 2);
      for (let i = 0; i < alaamatMaziMutadiPatternThree.length; i++) {
        MaziUrduGardaanList.push({
          title: item.title,
          translation:
            alaamatStarterMaziMutadiAllPattern[i] +
            starter +
            alaamatMaziMutadiPatternThree[i],
        });
      }
      return MaziUrduGardaanList;
    }
    return MaziUrduGardaanList;
  };
  MaziGardaanCreatorUrdu();
});

const arrayIsHave = [];

for (let x = 0; x < arrayFilter.length; x++) {
  let optionsArray = MaziUrduGardaanList.filter((item) => {
    if (item.title == arrayFilter[x].title) {
      return item;
    }
  });
  arrayIsHave.push({
    name: arrayFilter[x].text,
    title: arrayFilter[x].title,
    translation: MaziUrduGardaanList[x] && MaziUrduGardaanList[x].translation,
    options: optionsArray.map((item) => item.translation),
  });
}

export const allwords = ConvertBaseFailIntoGardan();

export const questionDataMaziMaroof = [
  ...FailGardaanFirstSevenMaziMaroof,
  ...FailGardaanSecondSevenMaziMaroof,
].map((item) => {
  let newWord = item.zameer.split(" ");
  return {
    text: item.text,
    zameerWahidJama: newWord[0],
    zameerJins: newWord[1],
    name: newWord[2],
  };
});

// export const newDatais = [
//   ...FailGardaanFirstSevenMaziMaroof,
//   ...FailGardaanSecondSevenMaziMaroof,
// ].map((item) => {
//   if (item.text.search('ضرب') != -1) {
//     return { text: item.text };
//   } else {
//     return;
//   }
// });

export const questionDataMuzariyaMaroof = [
  ...FailGardaanFirstSevenMuzariyaMaroof,
  ...FailGardaanSecondSevenMuzariyaMaroof,
].map((item) => {
  let newWord = item.zameer.split(" ");
  return {
    text: item.text,
    zameerWahidJama: newWord[0],
    zameerJins: newWord[1],
    name: newWord[2],
  };
});
export const questionDataMaziMujhool = [
  ...FailGardaanFirstSevenMaziMujhool,
  ...FailGardaanSecondSevenMaziMujhool,
].map((item) => {
  let newWord = item.zameer.split(" ");
  return {
    text: item.text,
    zameerWahidJama: newWord[0],
    zameerJins: newWord[1],
    name: newWord[2],
  };
});
export const questionDataMuzariyaMujhool = [
  ...FailGardaanFirstSevenMuzariyaMujhool,
  ...FailGardaanSecondSevenMuzariyaMujhool,
].map((item) => {
  let newWord = item.zameer.split(" ");
  return {
    text: item.text,
    zameerWahidJama: newWord[0],
    zameerJins: newWord[1],
    name: newWord[2],
  };
});
export const questionDataAmur = [
  ...FailGardaanFirstSevenAmur,
  ...FailGardaanSecondSevenAmur,
].map((item) => {
  let newWord = item.zameer.split(" ");
  return {
    text: item.text,
    zameerWahidJama: newWord[0],
    zameerJins: newWord[1],
    name: newWord[2],
  };
});
export const questionDataMauzariyaMunsoob = [
  ...FailGardaanFirstSevenMuzariyaMunsoob,
  ...FailGardaanSecondSevenMuzariyaMunsoob,
].map((item) => {
  let newWord = item.zameer.split(" ");
  return {
    text: item.text,
    zameerWahidJama: newWord[0],
    zameerJins: newWord[1],
    name: newWord[2],
  };
});
export const questionDataMauzariyaMunsoobMajhool = [
  ...FailGardaanFirstSevenMuzariyaMunsoobMajhool,
  ...FailGardaanSecondSevenMuzariyaMunsoobMajhool,
].map((item) => {
  let newWord = item.zameer.split(" ");
  return {
    text: item.text,
    zameerWahidJama: newWord[0],
    zameerJins: newWord[1],
    name: newWord[2],
  };
});

export const questionsDataForIsma = allIsma.map((item) => {
  let newIsma = item.zameer.split(" ");
  return {
    text: item.text,
    zameerWahidJama: newIsma[0],
    zameerJins: newIsma[1],
    name: item.name,
  };
});

export const questionDataForFeeh = allSalusMujaridFeehWords.map((item) => {
  let newWord = item.zameer.split(" ");
  return {
    text: item.text,
    zameerWahidJama: newWord[0],
    zameerJins: newWord[1],
    name: newWord[2],
    baabName: item.baabName,
    baab: item.baab,
  };
});
export const newTrans = forTranslations();
export const englishArray = MaziEnglishGardaanList;
export const urduArray = MaziUrduGardaanList;
// export const newTransWords = forTranslations();
export const questionTranslation = arrayIsHave;
export const newArrayData = [
  ...newMaziMaroof,
  ...newMuzariyaMaroof,
  ...newMaziMujhool,
  ...newMuzariyaMujhool,
  ...newAmur,
  ...newMuzariyaMunsoob,
];