import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Grid, Typography, TextField, Button } from "@material-ui/core";
import Frame from "../../Assets/Images/Icon/Frame.svg";
import Search from "../../Assets/Images/Icon/search.svg";
import PageCard from "../../Components/Card/PageCard";
import Navbar from "../../Components/Navbar/Navbar";
import TopicAccording from "./TopicAccording";
import TopicCard from "./TopicCard";
import { loginDisplayShow } from "../../Redux/action/action";
import { userIsSignIn } from "../../Assets/firebase";
// import { history } from "../../configs/history";
const MashaqContentFail = () => {
  const dispatch = useDispatch();

  const [nameForTest, setNameForTest] = useState("");
  const history = useHistory();
  const topicCardContent = [
    { name1: "ماضی معروف", name2: "Maazi-Maroof" },
    { name1: "ﻣﹹﻀﺎﺭﻉ معروف", name2: "Muzariya-Maroof" },
    { name1: "ماضی مجہول", name2: "Mazi-Mujhool" },
    { name1: "ﻣﹹﻀﺎﺭﻉ مجہول", name2: "Muzariya-Mujhool" },
    { name1: "امر", name2: "Amur" },
    { name1: "معروف ﻣﹹﻀﺎﺭﻉ منصوب", name2: "Muzariya-Munsoob-Maroof" },
    { name1: "مجہول ﻣﹹﻀﺎﺭﻉ منصوب", name2: "Muzariya-Munsoob-Majhool" },
  ];
  const goToQuestionPage = (name) => {
    console.log("l//////askdhk");
    const token = localStorage.getItem("token");
    userIsSignIn((user)=>{
      if (user) {
        history.push(`/question-fail/${name}/${nameForTest}`);
      } else {
        const answare = window.confirm("please login first then continue... ");
  
        if (answare) {
          return history.push("/login");
          dispatch(loginDisplayShow(true));
        } else {
          setTimeout(() => {
            history.push("/");
          }, 2000);
        }
      }
    });
  };
  const goToQuestionTranslationPage = (name) => {
    userIsSignIn((user)=>{
      if (user) {
        history.push(`/question-fail/translation-page`);
      } else {
        const answare = window.confirm("please login first then continue... ");
  
        if (answare) {
          history.push("/login");
          // dispatch(loginDisplayShow(true));
        } else {
          setTimeout(() => {
            history.push("/");
          }, 2000);
        }
      }
    })
  };
  return (
    <div className="mashaq-panel">
      <Navbar />
      <div
        style={{
          backgroundColor: "#FFFFFF",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="topicpage text-center"
      >
        <PageCard>
          <h3
            style={{
              color: "#104A6C",
              fontSize: "3rem",
            }}
          >
            <img
              src={Frame}
              alt=""
              style={{ width: "50px", marginRight: "0.5rem" }}
            />
            ہدایات
            <img
              src={Frame}
              alt=""
              style={{ width: "50px", marginLeft: "0.5rem" }}
            />
          </h3>
          <h4
            style={{
              color: "grey",
              margin: "10px 20px",
            }}
          >
            اپ کو ہر سیکشن سے ایک اختیار منتخب کرنا ہو گا
          </h4>
          <Grid container justify="space-evenly" alignItems="center">
            <Grid item xs={10} sm={10} md={5} lg={5}>
              <input
                placeholder="ٹیسٹ کا نام"
                className="selection-input-mashaq-fail"
                type="text"
                onChange={(e) => setNameForTest(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container justify="space-evenly" alignItems="center">
            {topicCardContent.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  style={{ margin: "0px 15px" }}
                  sm={12}
                  md={5}
                  lg={5}
                >
                  <Card
                    component={Button}
                    key={index}
                    className="selection-button-mashaq-fail"
                    onClick={() => goToQuestionPage(item.name2)}
                    fullWidth
                    disabled={nameForTest == "" ? true : false}
                    // style={{ backgroundColor: 'whitesmoke', outline: 'none' }}
                  >
                    <h5 className="urdu" style={{ color: "#104A6C" }}>
                      {item.name1}
                    </h5>
                  </Card>
                </Grid>
              );
            })}
            <Grid item xs={6} sm={6} md={5} lg={5}>
              <Card
                component={Button}
                className="selection-button-mashaq-fail"
                onClick={() => goToQuestionTranslationPage()}
                fullWidth
                disabled={nameForTest == "" ? true : false}
              >
                <Typography
                  variant="h5"
                  style={{ fontWeight: 500, color: "#104A6C" }}
                >
                  Translation
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </PageCard>
      </div>
    </div>
  );
};

export default MashaqContentFail;
