import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { TextField } from '@material-ui/core';

import { useHistory } from 'react-router';

function ForgetPassword() {
  const [state, setState] = useState({
    email: '',
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    // history.push('/confirm_password')

    const newdata = {
      email: state.email,
    };
  };

  const history = useHistory();

  return (
    <div style={{ backgroundColor: 'whitesmoke' }} className='login-page'>
      <Fade top>
        <div className='login-card'>
          <h2>Forget Password</h2>
          <form style={{ marginTop: '2rem' }} action=''>
            <TextField
              fullWidth
              id='outlined-basic'
              label='Email'
              variant='outlined'
              type='email'
              name='email'
              onChange={(e) => handleChange(e)}
            />
          </form>

          <button
            onClick={() => handleSubmit()}
            type='button'
            class='btn btn-info'
          >
            CHECK YOUR EMAIL
          </button>
        </div>
      </Fade>
    </div>
  );
}

export default ForgetPassword;
