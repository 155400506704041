import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Frame from "../../Assets/Images/Icon/Frame.svg";
import PageCard from "../../Components/Card/PageCard";
import Navbar from "../../Components/Navbar/Navbar";
import TopicAccording from "./TopicAccording";
import TopicCard from "./TopicCard";
// import { history } from "../../configs/history";
const MashaqContent = ({ type }) => {
  var topicCardContent = [];
  var headerText = "";
  if (type === "mujarad") {
    headerText = "ثلاثی مجرد";
    topicCardContent = [
      baabZarabarootSahih,
      baabNasararootSahih,
      baabFataharootSahih,
      baabSamiarootSahih,
      baabHasibarootSahih,
      baabKarumarootSahih,
    ];
  } else if (type === "mujaradFeeh") {
    headerText = "ثلاثي مزيد فيه";
    topicCardContent = [
      baabIffaalSahih,
      baabTafeelSahih,
      baabtMufaalaSahih,
      baabTafahulSahih,
      baabTafaulSahih,
      baabIftiaalSahih,
      baabInfiaalSahih,
      baabIstifaalSahih,
    ];
  } else if (type === "mashaqIsam") {
    headerText = "مشتقات اسم";
    topicCardContent = [rootNounList];
  }
  // const handleConfirm = () => {
  //   history.push("/topic");
  //   history.go();
  // }
  return (
    <>
      <Navbar />
      <div className="mashaq-panel">
        {/* <Grid className="container"></Grid> */}
        <div className="topicpage">
          <PageCard>
            <h2
              style={{
                color: "black",
                fontSize: 28,
                marginBottom: "20px",
              }}
            >
              {headerText}
            </h2>

            <div className="topicpage-inn">
              {topicCardContent.map((baabs, index) => {
                return (
                  <TopicAccording key={index} title={baabs.baabName}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      {baabs.data.map((baab, index) => {
                        var gardaanUrl = "#";
                        if (baabs.baabName !== "اسم") {
                          gardaanUrl = `gardaan?فعل=${baab}&baabName=${baabs.baabName}`;
                        } else {
                          var gardaanUrl = `gardaan-noun?اسم=${baab}`;
                        }
                        return (
                          <Grid item xs="4" sm="4" md="3" lg="3">
                            <TopicCard
                              title={baab}
                              url={gardaanUrl}
                              key={index}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    ;
                  </TopicAccording>
                );
              })}
            </div>
          </PageCard>
        </div>
      </div>
    </>
  );
};

export default MashaqContent;

const baabZarabarootSahih = {
  baabName: "ضرب",
  data: [
    "جلس",
    "حبس",
    "حلق",
    "حمل",
    "خرق",
    "رجع",
    "سرق",
    "صبر",
    "صرف",
    "ضرب",
    "عدل",
    "عرف",
    "عصر",
    "غزل",
    "غسل",
    "غفر",
    "غلب",
    "قصد",
    "کذب",
    "کسب",
    "کسر",
    "کشف",
    "نزل",
    "ہلک",
  ],
};
const baabNasararootSahih = {
  baabName: "نصر",
  data: [
    "بلغ",
    "ترک",
    "حرث",
    "خرج",
    "خلد",
    "خلط",
    "دخل",
    "دلک",
    "رقد",
    "رکض",
    "ستر",
    "سقط",
    "سکت",
    "طلب",
    "غرز",
    "فتل",
    "قعد",
    "لمس",
    "مطر",
    "مکث",
    "نسج",
    "نشر",
    "نصر",
    "نفخ",
    "نقص",
    "نقض",
    "ھرب",
  ],
};
const baabFataharootSahih = {
  baabName: "فتح",
  data: [
    "جحد",
    "جرح",
    "جعل",
    "خلع",
    "خدع",
    "دفع",
    "ذھب",
    "رفع",
    "زرع",
    "سبح",
    "سحق",
    "صرخ",
    "طبخ",
    "طحن",
    "فتح",
    "قطع ",
    "قلع",
    "مدح",
    "مضع",
    "منع",
    "نھض",
  ],
};
const baabKarumarootSahih = {
  baabName: "کرم",
  data: ["بصر", "بعد", "شرف", "صعب", "عظم", "قرب", "کثر", "کرم "],
};
const baabSamiarootSahih = {
  baabName: "سمع",
  data: [
    "جہل",
    "حفظ",
    "حمد",
    "رکب",
    "سعد",
    "سمع",
    "شرب",
    "شہد",
    "ضحک",
    "عطش",
    "علم",
    "فہم",
    "قدم",
    "لبث",
    "لبس",
    "لحق",
    "لعق",
  ],
};
const baabHasibarootSahih = { baabName: "حسب", data: ["حسب", "نعم"] };

// "افعال", "تفعیل", "مفاعلہ", "تفعل", "تفاعل", "افتعال", "انفعال", "استفعال";

const baabIffaalSahih = {
  baabName: "افعال",
  data: [
    "بدل",
    "برز",
    "برم",
    "بسل",
    "بشر",
    "بصر",
    "بطل",
    "بعد",
    "بلس",
    "بلع",
    "بلغ",
    "بهل",
    "بهم",
    "تبع",
    "تحف",
    "ترف",
    "تسق",
    "تعب",
    "تقن",
    "ثبت",
    "ثبط",
    "ثخن",
    "ثقل",
    "ثمر",
    "جرم",
    "جلب",
    "جلس",
    "جمع",
    "جمل",
    "حبط",
    "حدث",
    "حذف",
    "حرق",
    "حرم",
    "حسب",
    "حسن",
    "حصر",
    "حصن",
    "حضر",
    "حكم",
    "حلف",
    "خبت",
    "خبر",
    "خرب",
    "خرج",
    "خرس",
    "خسر",
    "خصب",
    "خلد",
    "خلص",
    "خلف",
    "خمر",
    "دبر",
    "دحض",
    "دخل",
    "درك",
    "دغم",
    "دهق",
    "دهن",
    "ذعن",
    "ذنب",
    "ذهب",
    "رجف",
    "رجه",
    "ردف",
    "رسل",
    "رشد",
    "رصد",
    "رضع",
    "ركس",
    "رهب",
    "رهق",
    "زلف",
    "زلق",
    "سبغ",
    "سحت",
    "سخط",
    "سرج",
    "سرع",
    "سرف",
    "سفر",
    "سقط",
    "سكر",
    "سكن",
    "سلف",
    "سلم",
    "سمع",
    "سمن",
    "سند",
    "سهل",
    "شبه",
    "شرب",
    "شرف",
    "شرق",
    "شرك",
    "شعر",
    "شفق",
    "شكل",
    "شمت",
    "شهد",
    "صبح",
    "صحب",
    "صدر",
    "صرخ",
    "صرف",
    "صعد",
    "صلح",
    "ضحك",
    "ضرب",
    "ضعف",
    "ضمر",
    "طبق",
    "طعم",
    "طفأ",
    "طلع",
    "طلق",
    "ظلم",
    "ظهر",
    "عتب",
    "عتد",
    "عتل",
    "عثر",
    "عجب",
    "عجز",
    "عجل",
    "عجم",
    "عرب",
    "عرض",
    "عرف",
    "عصر",
    "عظم",
    "عقب",
    "علم",
    "علن",
    "عمل",
    "عنت",
    "غرق",
    "غرم",
    "غضب",
    "غطش",
    "غفل",
    "غلق",
    "غمض",
    "فرد",
    "فرط",
    "فرغ",
    "فسد",
    "فطر",
    "فلح",
    "قبر",
    "قبل",
    "قتر",
    "قرب",
    "قرض",
    "قسط",
    "قسم",
    "قصر",
    "قلع",
    "قمح",
    "قنع",
    "كبر",
    "كثر",
    "كرم",
    "كره",
    "كشف",
    "كفل",
    "كمل",
    "لبس",
    "لحد",
    "لحف",
    "لحق",
    "لزم",
    "لهب",
    "لهم",
    "متع",
    "مسك",
    "مطر",
    "مكن",
    "ملق",
    "مهل",
    "نبأ",
    "نبت",
    "نتن",
    "نذر",
    "نزف",
    "نزل",
    "نسب",
    "نشد",
    "نشر",
    "نشز",
    "نصت",
    "نصف",
    "نظر",
    "نعم",
    "نغض",
    "نفق",
    "نقذ",
    "نقض",
    "نكح",
    "نكر",
    "هرع",
    "هطع",
    "هلك",
    "همل",
  ],
};
const baabTafeelSahih = {
  baabName: "تفعیل",
  data: [
    "بتك",
    "بتل",
    "بدل",
    "بذر",
    "برز",
    "بشر",
    "بصر",
    "بعد",
    "بعض",
    "بكر",
    "بلغ",
    "تبر",
    "ثبت",
    "ثبط",
    "ثرب",
    "ثلث",
    "ثلج",
    "جرب",
    "جرد",
    "جلد",
    "جمع",
    "جنب",
    "جند",
    "جهز",
    "حدث",
    "حذر",
    "حرب",
    "حرص",
    "حرض",
    "حرف",
    "حرق",
    "حرك",
    "حرم",
    "حصل",
    "حصن",
    "حضر",
    "حفظ",
    "حقر",
    "حكم",
    "حمد",
    "حمل",
    "خرب",
    "خسر",
    "خلد",
    "خلف",
    "خلق",
    "خمر",
    "دبر",
    "دخن",
    "درب",
    "درس",
    "دمر",
    "ذبح",
    "ذكر",
    "رتب",
    "رتل",
    "رحب",
    "رسل",
    "رشح",
    "رغب",
    "ركب",
    "زمل",
    "سبح",
    "سجر",
    "سجل",
    "سحر",
    "سخر",
    "سرج",
    "سرح",
    "سطر",
    "سعر",
    "سكر",
    "سكن",
    "سلح",
    "سلط",
    "سلم",
    "سمر",
    "سند",
    "سهل",
    "شبه",
    "شرد",
    "شرع",
    "شرف",
    "شغل",
    "صبح",
    "صدر",
    "صدع",
    "صدق",
    "صرف",
    "صعر",
    "صغر",
    "صلب",
    "ضعف",
    "ضمن",
    "طبق",
    "طعم",
    "طلق",
    "طهر",
    "عبد",
    "عبر",
    "عجل",
    "عدل",
    "عذب",
    "عذر",
    "عرض",
    "عرف",
    "عزر",
    "عطل",
    "عظم",
    "عفر",
    "عقب",
    "عقد",
    "علق",
    "علم",
    "عمر",
    "عمق",
    "غلب",
    "غلق",
    "غمض",
    "فتح",
    "فتش",
    "فجر",
    "فخم",
    "فرج",
    "فرح",
    "فرض",
    "فرط",
    "فرغ",
    "فرق",
    "فزع",
    "فسر",
    "فصل",
    "فضل",
    "فقه",
    "فكر",
    "فكه",
    "فند",
    "فهم",
    "قبل",
    "قتل",
    "قدر",
    "قدس",
    "قدم",
    "قرب",
    "قرن",
    "قسط",
    "قشر",
    "قصر",
    "قطع",
    "قلب",
    "قلد",
    "قنع",
    "كبر",
    "كثر",
    "كذب",
    "كرم",
    "كره",
    "كسر",
    "كفر",
    "كفل",
    "كلب",
    "كلف",
    "كلم",
    "لبس",
    "لخص",
    "متع",
    "مثل",
    "محص",
    "مدن",
    "مرد",
    "مرض",
    "مزق",
    "مسك",
    "مكن",
    "مهد",
    "مهل",
    "نبت",
    "نبه",
    "ندم",
    "نزح",
    "نزل",
    "نشر",
    "نظف",
    "نظم",
    "نعم",
    "نغم",
    "نفذ",
    "نفس",
    "نكر",
    "نكس",
    "نكل",
    "هدم",
    "هذب",
  ],
};
const baabtMufaalaSahih = {
  baabName: "مفاعلہ",
  data: [
    "بدر",
    "برز",
    "برك",
    "بشر",
    "بعد",
    "بلغ",
    "بهل",
    "ثبر",
    "جدل",
    "جلس",
    "جنب",
    "جهد",
    "جهر",
    "حرب",
    "حسب",
    "حضر",
    "حفظ",
    "خدع",
    "خصر",
    "خطب",
    "خفت",
    "خلط",
    "خلف",
    "خمر",
    "دفع",
    "ربط",
    "رجع",
    "رسل",
    "رغم",
    "رفق",
    "رقب",
    "رهق",
    "زرع",
    "سبق",
    "سرع",
    "سعد",
    "سفح",
    "سفر",
    "سقط",
    "سلم",
    "سمح",
    "سمر",
    "شبه",
    "شرك",
    "شفه",
    "شهد",
    "صبر",
    "صحب",
    "صدف",
    "صدق",
    "صفح",
    "صلح",
    "ضرع",
    "ضعف",
    "طبق",
    "ظهر",
    "عتب",
    "عجز",
    "عدل",
    "عشر",
    "عقب",
    "علج",
    "عمل",
    "عنق",
    "عهد",
    "غدر",
    "غضب",
    "فخر",
    "فرق",
    "فصل",
    "قبل",
    "قتل",
    "قرب",
    "قرن",
    "قسم",
    "قطع",
    "كتب",
    "كثر",
    "لحظ",
    "لمس",
    "مرس",
    "مزج",
    "مطل",
    "نزع",
    "نسب",
    "نفس",
    "نفق",
    "نقش",
    "هجر",
  ],
};
const baabTafahulSahih = {
  baabName: "تفعل",
  data: [
    "بتل",
    "بخر",
    "بدل",
    "برج",
    "برد",
    "برع",
    "بسم",
    "جرع",
    "جمع",
    "جنب",
    "جند",
    "حجب",
    "حدث",
    "حرف",
    "حرك",
    "حصن",
    "حمس",
    "حنث",
    "خبت",
    "خرج",
    "خطف",
    "خلف",
    "خمر",
    "دبر",
    "دثر",
    "دفق",
    "ذكر",
    "ربص",
    "رقب",
    "زمل",
    "سلط",
    "سلق",
    "سلم",
    "سنه",
    "شجع",
    "شرف",
    "شعب",
    "صدر",
    "صدع",
    "صدق",
    "صرف",
    "صعد",
    "صلح",
    "ضجر",
    "ضرع",
    "ضمن",
    "طرف",
    "طلع",
    "طهر",
    "عجب",
    "عجل",
    "عرض",
    "علق",
    "علم",
    "عمد",
    "فجر",
    "فحص",
    "فرق",
    "فسح",
    "فضل",
    "فطر",
    "فقد",
    "فقه",
    "فكر",
    "فكه",
    "قبل",
    "قدم",
    "قرش",
    "قطع",
    "قعر",
    "قلب",
    "كبر",
    "كسر",
    "كلم",
    "لبث",
    "لطف",
    "متع",
    "مثل",
    "مرد",
    "مكن",
    "مهل",
    "نبه",
    "نزل",
    "نزه",
    "نضد",
    "نفس",
    "نكس",
    "هجد",
  ],
};
const baabTafaulSahih = {
  baabName: "تفعیل",
  data: [
    "برك",
    "تبع",
    "ثقل",
    "جنف",
    "حكم",
    "خصم",
    "خفت",
    "دخل",
    "درس",
    "درك",
    "ذحر",
    "رجع",
    "ردف",
    "رسل",
    "ركب",
    "ركم",
    "سأل",
    "شأم",
    "شبه",
    "شجر",
    "شكس",
    "صفح",
    "ضرب",
    "ظلم",
    "ظهر",
    "عرف",
    "عسر",
    "غبن",
    "غمز",
    "فأل",
    "فخر",
    "قبل",
    "قسم",
    "قعد",
    "كثر",
    "كسل",
    "مرض",
    "نبز",
    "نزع",
    "نفس",
  ],
};
const baabIftiaalSahih = {
  baabName: "افتعال",
  data: [
    "بدع",
    "بسم",
    "بهل",
    "تبع",
    "تجر",
    "تفق",
    "جمع",
    "جنب",
    "جهد",
    "حرق",
    "حرم",
    "حسب",
    "حفل",
    "حقر",
    "حمد",
    "حمل",
    "حنك",
    "خبر",
    "خرع",
    "خصر",
    "خصم",
    "خطب",
    "خلط",
    "خلف",
    "خمر",
    "درك",
    "ذخر",
    "ذكر",
    "رفع",
    "رفق",
    "رقب",
    "رمض",
    "زجر",
    "زحم",
    "زلف",
    "سبق",
    "ستر",
    "سطر",
    "سرق",
    "سطر",
    "سرق",
    "سلم",
    "سمر",
    "سمع",
    "سند",
    "شبه",
    "شرط",
    "شرك",
    "شعل",
    "شغل",
    "شمل",
    "شهر",
    "صبر",
    "صلح",
    "صنع",
    "ضجع",
    "ضرب",
    "طلع",
    "ظلم",
    "عبر",
    "عدل",
    "عذر",
    "عرض",
    "عرف",
    "عزل",
    "عصر",
    "عصم",
    "عقد",
    "عقل",
    "عكر",
    "عكف",
    "عمر",
    "غرف",
    "غسل",
    "غنم",
    "فتح",
    "فخر",
    "فرق",
    "فقر",
    "قبس",
    "قبل",
    "قتل",
    "قحم",
    "قدر",
    "قرب",
    "قرح",
    "قرض",
    "قرف",
    "قرن",
    "قرح",
    "قصد",
    "قلع",
    "كتب",
    "كسب",
    "لبس",
    "لحد",
    "لحق",
    "لزم",
    "لفت",
    "لقط",
    "لقم",
    "لمس",
    "مثل",
    "محن",
    "مزج",
    "منع",
    "مهن",
    "نبذ",
    "نبه",
    "نثر",
    "نحر",
    "نخب",
    "نزم",
    "نسب",
    "نشر",
    "نصر",
    "نصف",
    "نظر",
    "نفد",
    "نفع",
    "نفق",
    "نقل",
    "نقم",
    "نهز",
  ],
};
const baabInfiaalSahih = {
  baabName: "انفعال",
  data: [
    "بجس",
    "بعث",
    "بهم",
    "تبه",
    "خلع",
    "خنق",
    "دمل",
    "سلخ",
    "شرح",
    "شغل",
    "صرف",
    "طلق",
    "عطف",
    "فتح",
    "فجر",
    "فصل",
    "فصم",
    "فطر",
    "فلق",
    "قسم",
    "قطع",
    "قلب",
    "كدر",
    "كسر",
    "كسف",
    "كشف",
    "هدم",
    "هزم",
    "همر",
  ],
};
const baabIstifaalSahih = {
  baabName: "استفعال",
  data: [
    "بدل",
    "بشر",
    "بصر",
    "ثمر",
    "حسر",
    "حسن",
    "حفظ",
    "حقر",
    "خدم",
    "خرج",
    "خلص",
    "خلف",
    "خمر",
    "درج",
    "درك",
    "ذنب",
    "رجع",
    "رضع",
    "رهب",
    "سلم",
    "شهد",
    "صرخ",
    "ضعف",
    "طعم",
    "عبر",
    "عتب",
    "عجل",
    "عرب",
    "عصم",
    "عظم",
    "علم",
    "عمر",
    "عمِل",
    "غرِق",
    "غفر",
    "فتح",
    "فسر",
    "فهم",
    "قبِل",
    "قدم",
    "قرض",
    "قسم",
    "كبر",
    "كثر",
    "متع",
    "مسك",
    "نبط",
    "نثر",
    "نصر",
    "نفر",
    "نقذ",
    "نكح",
    "نكف",
    "هلك",
  ],
};

// isaam noun
const rootNounList = {
  baabName: "اسم",
  data: [
    "تبع",
    "ثقف",
    "ثقل",
    "ثمر",
    "جبر",
    "جحم",
    "جدل",
    "جرم",
    "جسد",
    "جعل",
    "جنب",
    "جنح",
    "جند",
    "حبر",
    "حبل",
    "حجب",
    "حدث",
    "حذر",
    "حرب",
    "حرث",
    "حرج",
    "حرم",
    "حزب",
    "حسب",
    "حسن",
    "حشر",
    "حصر",
    "حصن",
    "حضر",
    "حطب",
    "حفظ",
    "حكم",
    "حمد",
    "حمل",
    "خبت",
    "ختم",
    "خرج",
    "خرر",
    "خزن",
    "خسر",
    "خشع",
    "خطف",
    "خلف",
    "خلق",
    "درك",
    "ذكر",
    "ذکر",
    "ذهب",
    "رجز",
    "رجع",
    "رجم",
    "رحم",
    "رزق",
    "رسل",
    "رعد",
    "رفع",
    "رفق",
    "رقب",
    "ركب",
    "ركع",
    "زبر",
    "سبق",
    "سبل",
    "سجد",
    "سحر",
    "سخر",
    "سرج",
    "سرع",
    "سرف",
    "سفر",
    "سفل",
    "سقط",
    "سكر",
    "سكن",
    "سلف",
    "سلك",
    "سلم",
    "سمع",
    "شرب",
    "شطن",
    "شعر",
    "شفع",
    "شكر",
    "شهد",
    "صبر",
    "صدع",
    "صدق",
    "صرط",
    "صرف",
    "صعق",
    "صغر",
    "صلح",
    "ضحك",
    "ضرب",
    "ضعف",
    "طرف",
    "طرق",
    "طعم",
    "طمن",
    "طهر",
    "ظلم",
    "ظہر",
    "عبد",
    "عجب",
    "عجز",
    "عدل",
    "عذب",
    "عرش",
    "عرض",
    "عرف",
    "عزم",
    "عصر",
    "عضل",
    "عظم",
    "عقب",
    "علم",
    "غضب",
    "غفر",
    "غلب",
    "غلم",
    "فتح",
    "فتن",
    "فجر",
    "فحش",
    "فرح",
    "فرش",
    "فرق",
    "فسد",
    "فسق",
    "فصل",
    "فضل",
    "فعل",
    "فلح",
    "قتل",
    "قدر",
    "قدم",
    "قذف",
    "قرب",
    "قرع",
    "قرن",
    "قسط",
    "قسم",
    "قصر",
    "قطع",
    "قعد",
    "كبر",
    "كتب",
    "كذب",
    "كرم",
    "كسب",
    "كفر",
    "کتب",
    "لبس",
    "لعن",
    "مطر",
    "مكث",
    "نبت",
    "نذر",
    "نزع",
    "نزل",
    "نشر",
    "نصب",
    "نصر",
    "نظر",
    "نعم",
    "نفس",
    "نفق",
    "نفق",
    "نقب",
    "نكح",
    "نهر",
    "هجر",
    "هلك",
  ],
};
