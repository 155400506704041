import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "../../../Assets/Images/Icon/alert.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  mushq_afal_slaasi,
  imtihan_afal_slaasi,
} from "../../../Redux/action/action";
import { history } from "../../../configs/history";

function Sweet_Alert_For_Imtehanat() {
  const dispatch = useDispatch();

  const [update, setUpdate] = useState(false);
  const [faielExercise, setFaielExercise] = useState(false);

  const imtihanFall = useSelector((state) => state.imtihanFall);
  const mashq_afal_update = useSelector((state) => state);
  const { mashaqFall } = mashq_afal_update;

  const handleMashaqSalaasiMujarad = () => {
    if (update || mashaqFall) {
      history.push("/mashaq-salaasi-mujarad");
      history.go();
    } else if (imtihanFall) {
      history.push("/mashaq-salaasi-mujarad-fail");
      history.go();
    } else {
      history.push("/mashaq-salaasi-mujarad-fail");
      history.go();
    }
  };

  const handleMashaqSalaasiMujaradFeeh = () => {
    if (update || mashaqFall) {
      history.push("/mashaq-salaasi-mujarad-feeh");
      history.go();
    } else if (imtihanFall) {
      history.push("/mashaq-salaasi-mujarad-feeh-fail");
      history.go();
    } else {
      history.push("/mashaq-salaasi-mujarad-feeh-fail");
      history.go();
    }
  };

  return (
    <SweetAlert
      style={{ backgroundColor: "white", color: "black" }}
      custom
      show={update || faielExercise || mashaqFall || imtihanFall}
      // show={true}
      showCancel
      title="انتخاب کریں!"
      customButtons={
        <React.Fragment>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a
              style={{ backgroundColor: "#5c6878" }}
              href="javascript:void(0);"
              className="btn mx-2  btn-primary"
              onClick={handleMashaqSalaasiMujaradFeeh}
            >
              ثلاثی مزید فیہ
            </a>
            <a
              style={{ backgroundColor: "#5c6878" }}
              href="javascript:void(0);"
              className="btn mx-2 btn-primary"
              onClick={handleMashaqSalaasiMujarad}
            >
              ثلاثی مجرد
            </a>
          </div>
        </React.Fragment>
      }
      onCancel={
        update
          ? () => setUpdate(false)
          : faielExercise
          ? () => setFaielExercise(false)
          : mashaqFall
          ? () => {
              dispatch(mushq_afal_slaasi(false));
              dispatch(imtihan_afal_slaasi(false));
            }
          : () => dispatch(imtihan_afal_slaasi(false))
      }
      customIcon={Alert}
      customClass="sweet_alert"
    ></SweetAlert>
  );
}

export default Sweet_Alert_For_Imtehanat;
