import React from "react";
import Navbar from "../Components/Navbar/Navbar";

function ImageWrap(props) {
  return (
    <div className="Image-background">
      {/* <Navbar /> */}
      {props.children}
    </div>
  );
}

export default ImageWrap;
