import React, { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { Grid } from "@material-ui/core";

const Feild = (props) => {
  return (
    <>
      <Grid item xs={6} className="text-center">
        <h5>{props.title}</h5>
      </Grid>
    </>
  );
};
const Hadiyat = () => {


  return (
    <Layout
      mainClass="hadiyat"
      title="ہدایت"
      subtile="آپ کو پر سیکشن میں سے ایک اختیار منتخب کرنا ہے۔"
    >
      <form>
        <input type="text" placeholder="ثلاثی مذید فیہ " />
        <button  type="submit">امتحان شروع کریں</button>
      </form>

      <Grid container spacing={3} className="container">
        <Feild title="فعل مضارع" />
        <Feild title="فعل مضارع" />
        <Feild title="فعل مضارع" />
        <Feild title="فعل مضارع" />
        <Feild title="فعل مضارع" />
        <Feild title="فعل مضارع" />
        <Feild title="فعل مضارع" />
        <Feild title="فعل مضارع" />
      </Grid>
    </Layout>
  );
};

export default Hadiyat;
