import React from 'react'
import {Grid} from "@material-ui/core";
import Crossicon from "../../Assets/Images/Icon/cross.png";
import TickIcon from "../../Assets/Images/Icon/tick.png";

const Translation = ({answerGiven,question,index}) => {
  const {translation} = question;
  return (
    <Grid container direction="row-reverse">
    <Grid item xs={3} sm={3} md={3} lg={3}>
      <h5>سیکشن</h5>
      <h5>ترجمہ</h5>
    </Grid>
    <Grid item xs={3} sm={3} md={3} lg={3}>
      <h5>آپ کا انتخاب</h5>
      <h5>{answerGiven[index].translation}</h5>
    </Grid>
    <Grid item xs={3} sm={3} md={3} lg={3}>
      <h5>درست جواب</h5>
      <h5>{translation}</h5>
    </Grid>
    <Grid item xs={3} sm={3} md={3} lg={3}>
      <h5>نتیجہ</h5>
      <h5>{answerGiven[index].translation === translation ? <img src={TickIcon} alt="icon"/> : <img src={Crossicon} alt="icon"/>}
      </h5>
    </Grid>
  </Grid>
  )
}

export default Translation
