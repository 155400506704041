import React, { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import QuestionCard from "./QuestionCard";
import Fade from "react-reveal/Fade";
import Login from "../Authentication/Login/Login";
import { useSelector, useDispatch } from "react-redux";
import { loginDisplayShow } from "../../Redux/action/action";
import Navbar from "../../Components/Navbar/Navbar";

const Question = () => {
  const dispatch = useDispatch();

  const login_show = useSelector((state) => state.login_component_show);

  const [loginDisplay, setLoginDisplay] = useState(login_show);

  const handleLogin = () => {
    dispatch(loginDisplayShow(false));
  };
  return (
    <>
      <Navbar />
      {loginDisplay ? (
        <div
          style={{
            display: `${loginDisplay}`,
            // position: "absolute",
            zIndex: 100,
            backgroundColor: "green",
            width: "100%",
            // height: "100%",
          }}
        >
          <Fade top>
            <Login onClick={() => handleLogin()} />
          </Fade>
        </div>
      ) : (
        ""
      )}

      <div>
        <Layout title="التصریف">
          <QuestionCard />
        </Layout>
      </div>
    </>
  );
};

export default Question;
