import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  Card,
  Button,
  CardContent,
} from "@material-ui/core";
import Navbar from "../../Components/Navbar/Navbar";
import { allLinksAndroid, webData, takhliqiData } from "../../Assets/DataArray";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .MuiAppBar-root": {},
    "& .MuiTabs-scroller": {
      "& > span": {
        display: "none !important",
      },
    },
    "& .MuiTabs-flexContainer": {
      padding: "5px",
      margin: "0px 24px",

      "& .MuiTab-root": {
        border: "1px solid #5C6878",
        fontWeight: "700",
        "&:focus": {
          backgroundColor: "#5c6878",
          color: "white",
          border: "none",
          fontWeight: "500",
        },
      },
    },
    "& .MuiCard-root": {
      boxShadow: "0px 0px 2px 0px rgba(0,0,0,1)",
      marginBottom: "0.5rem",
      "& .MuiCardContent-root": {
        "&:last-child": {
          paddingBottom: "15px",
        },
      },
      "& .MuiGrid-container": {
        "& .MuiGrid-item": {
          fontSize: "1.5rem",
          "&:last-of-type": {
            textAlign: "right",
          },
        },
      },
      "& .MuiButton-root": {
        // backgroundColor: 'burlywood',
        minWidth: "15vw",
        fontWeight: "bold",
      },
    },
  },
}));

const Others = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Navbar />
      <Grid container spacing={3} className="container"></Grid>
      <Typography
        align="center"
        variant="h3"
        style={{ color: "#fff" }}
        gutterBottom
      >
        مفردات القرآن
      </Typography>
      <Grid
        style={{ marginBottom: 10, borderRadius: 20 }}
        container
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={11} lg={11}>
          <div className={classes.root}>
            <AppBar position="static" color="initial" elevation={0}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                variant="fullWidth"
                indicatorColor="primary"
                initialSelectedIndex={2}
              >
                <Tab
                  style={{
                    borderWidth: 2,
                    borderColor: "#5c6878",
                    backgroundColor: value == 0 ? "#5C6878" : "transparent",
                    color: value == 0 ? "#fff" : "#000",
                  }}
                  label="Android Apps"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    borderWidth: 2,
                    borderColor: "#5c6878",
                    backgroundColor: value == 1 ? "#5C6878" : "transparent",
                    color: value == 1 ? "#fff" : "#000",
                  }}
                  label="Websites"
                  {...a11yProps(1)}
                />
                <Tab
                  style={{
                    borderWidth: 2,
                    borderColor: "#5c6878",
                    backgroundColor: value == 2 ? "#5C6878" : "transparent",
                    color: value == 2 ? "#fff" : "#000",
                  }}
                  label="Research Works"
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              {allLinksAndroid.map((item) => (
                <Card>
                  <CardContent>
                    <Grid container justify="space-evenly" alignItems="center">
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        {" "}
                        {item.nameEnglish}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        {item.name}
                      </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                      <Button href={item.link}>View</Button>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {webData.map((item) => (
                <Card>
                  <CardContent>
                    <Grid container justify="space-evenly" alignItems="center">
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        {" "}
                        {item.nameEnglish}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        {item.name}
                      </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                      <Button href={item.link}>View</Button>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {takhliqiData.map((item) => (
                <Card>
                  <CardContent>
                    <Grid container justify="space-evenly" alignItems="center">
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        {" "}
                        {item.nameEnglish}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        {item.name}
                      </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                      <Button href={item.link}>View</Button>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Others;
