import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Frame from "../../Assets/Images/Icon/Frame.svg";
import { questionsDataForIsma } from "../MashaqContent/AllWordsArray";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  TestData,
  getAllData,
  loginDisplayShow,
} from "../../Redux/action/action";
import { useParams } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Login from "../Authentication/Login/Login";
import Question from "./Question";
import { handleLogout, userIsSignIn } from "../../Assets/firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // marginBottom: "0.1rem",
    borderRadius: "20px",
    "& .MuiTab-root": {
      minWidth: "127px",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "& .MuiTab-root": {
      backgroundColor: "#F8DC0F",
      fontSize: "1.2rem",
      color: "#104A6C",
      padding: "12px 50px",
      marginBottom: "1rem",
      fontWeight: "700",
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiBox-root": {
        padding: "0px",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box px={3} my={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const QuestionCard = () => {
  const dispatch = useDispatch();
  // let { name, testname } = useParams();

  const classes = useStyles();
  const history = useHistory();
  const newAllTests = localStorage.getItem("All-Tests")
    ? JSON.parse(localStorage.getItem("All-Tests"))
    : [];
  const [value, setValue] = React.useState(0);
  const testDate = new Date().toDateString();
  const [arrayQuestions, setArrayQuestions] = React.useState([]);
  const [loginDisplay, setLoginDisplay] = useState(false);
  const [arraySolutions, setArraySolutions] = React.useState([]);
  const [arrayTests, setArrayTests] = React.useState([]);
  const [questionName, setQuestionName] = React.useState("");
  const [adhadh, setAdhadh] = React.useState("");
  const [jins, setJins] = React.useState("");
  const [segha, setSegha] = React.useState("");
  const [isSignIn, setIsSignIn] = useState(null);
  useEffect(()=>{
    userIsSignIn((user)=>{user ? setIsSignIn(user) : setIsSignIn(false) })
  },[])
  const handleChangeAdhadh = (event, item) => {
    setAdhadh(event.target.value);
    setQuestionName(item.text);
  };
  const handleChangeJins = (event) => {
    setJins(event.target.value);
  };
  const handleChangeSegha = (event) => {
    setSegha(event.target.value);
  };
  const handleChange = (event, newValue) => {
    if (isSignIn) {
      if (adhadh === "" || jins === "" || segha === "") {
        alert("please select all options");
      } else {
        if (newValue < 9) {
          arraySolutions.push({
            text: questionName,
            zameerWahidJama: adhadh,
            zameerJins: jins,
            name: segha,
          });
          setValue(newValue + 1);
          setQuestionName("");
          setAdhadh("");
          setJins("");
          setSegha("");
        } else {
          newAllTests.push({
            testName: "اسم امتحان",
            testDate: testDate,
            questionAsked: arrayQuestions,
            answerGiven: arraySolutions,
          });
          arraySolutions.push({
            text: questionName,
            zameerWahidJama: adhadh,
            zameerJins: jins,
            name: segha,
          });
          // localStorage.setItem("All-Tests", JSON.stringify(newAllTests));
          // history.push("/report");
          // const token = localStorage.getItem("token");
          dispatch(
            TestData(isSignIn, {
              testName: "اسم امتحان",
              testDate: testDate,
              questionAsked: arrayQuestions,
              answerGiven: arraySolutions,
            },(id)=>{
              history.push(`/report/${id}`);
            })
          );
        }
      }
    } else if(isSignIn === false) {
      const answare = window.confirm("please login first then continue... ");

      if (answare) {
        // dispatch(loginDisplayShow(true));
        history.push("/login");
      }
    }
  };

  useEffect(() => {
    setArrayQuestions(
      questionsDataForIsma
        .sort(() => Math.random() - Math.random())
        .slice(0, 10)
    );
    setValue(0);
  }, []);

  return (
    <>
      <div style={{ marginTop: 0 }} className="questionCardHeadings">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          className={classes.root}
        >
          <h3
            style={{
              color: "#104A6C",
              fontSize: "3rem",
            }}
          >
            {/* <img
              src={Frame}
              alt=""
              style={{ width: "50px", marginRight: "0.5rem" }}
            />
            التصریف
            <img
              src={Frame}
              alt=""
              style={{ width: "50px", marginLeft: "0.5rem" }}
            /> */}
          </h3>
          <TabPanel className="question-card-main" value={value} index={value}>
            {arrayQuestions.map((item, index) => {
              if (index === value) {
                return (
                  <div>
                    <>
                      <h4
                        style={{
                          color: "#F8DC0F",
                          // marginTop: 20,
                          borderRadius: 20,
                        }}
                      >
                        سوال نمبر {index + 1}:
                        <span style={{ color: "black" }}>{item.text}</span>
                      </h4>

                      <div className="quiz-div">
                        <FormControl component="fieldset" className="quiz">
                          <RadioGroup
                            aria-label="adhadh"
                            name="adhadh"
                            value={adhadh}
                            onChange={(e) => handleChangeAdhadh(e, item)}
                          >
                            <Grid className="question-grid" container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControlLabel
                                  value="واحد"
                                  control={<Radio color="default" />}
                                  label="واحد"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControlLabel
                                  value="تثنیہ"
                                  control={<Radio />}
                                  label="تثنیہ"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControlLabel
                                  value="جمع"
                                  control={<Radio />}
                                  label="جمع"
                                />
                              </Grid>
                            
                              
                            </Grid>
                            {/* <Grid container >
                          
                        </Grid> */}
                          </RadioGroup>
                        </FormControl>

                        <FormControl component="fieldset" className="quiz">
                          <RadioGroup
                            aria-label="jins"
                            name="jins"
                            value={jins}
                            onChange={handleChangeJins}
                          >
                            <Grid className="question-grid" container>
                               <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControlLabel
                                  value="مذکر"
                                  control={<Radio color="default" />}
                                  label="مذکر"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControlLabel
                                  value=" مؤنث"
                                  control={<Radio />}
                                  label="مؤنث"
                                />
                              </Grid>
                             
                            </Grid>
                            {/* <Grid container justify='center' alignItems='center'>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            {' '}
                            <FormControlLabel
                              value='مذکر/مؤنث'
                              control={<Radio />}
                              label='مذکر/مؤنث'
                            />
                          </Grid>
                        </Grid> */}
                          </RadioGroup>
                        </FormControl>

                        <FormControl component="fieldset" className="quiz">
                          <RadioGroup
                            aria-label="Segha"
                            name="segha"
                            value={segha}
                            onChange={handleChangeSegha}
                          >
                            <Grid className="question-grid" container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControlLabel
                                  value="فاعل"
                                  control={<Radio />}
                                  label="فاعل"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControlLabel
                                  value="مفعول"
                                  control={<Radio />}
                                  label="مفعول"
                                />
                              </Grid>
                           
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </>
                  </div>
                );
              }
            })}
          </TabPanel>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            visibleScrollbar={false}
          >
            {[...Array(10).keys()].map((countNumber) => (
              <Tab
                label="تصدیق کریں"
                style={
                  value === countNumber
                    ? { display: "block" }
                    : { display: "none" }
                }
                disabled={value === 10 ? true : false}
              />
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default QuestionCard;
